// images
import logo from "../../icons/dashboard-logo.png"
// imports
import "./Navbar.scss"
import { Avatar, Stack } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar = () => {

  const name = localStorage.getItem("gic_name");
  
  return (
    <div className='Navbar'
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" 
      px={{xs : 5 , sm : 20}} py={5}>

        <Link to="/" ><div className="logo"><img src={logo} alt="" /></div></Link>

        <Stack direction="row" spacing={{xs : 4 , sm : 8}} alignItems="center">

          <div className="profile-img">
            <Avatar 
              sx={{
                bgcolor : "secondary.main" , 
                width : "50px" , 
                height :"50px"
              }} 
            > {name[0]} </Avatar>
          </div>

          <Stack spacing={2}>
            <h5>{name}</h5>
            <h6>lawyer</h6>
          </Stack>
          
        </Stack>
        
      </Stack>
    </div>
  )
}

export default Navbar
