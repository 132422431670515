import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { createTheme , ThemeProvider } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

import Login from "./pages/Login/Login";
import AddClient from "./pages/AddClient/AddClient";
import AddLawyer from "./pages/AddLawyer/AddLawyer";
import AddAdmin from "./pages/AddAdmin/AddAdmin";
import Calculator from "./pages/Calculator/Calculator";
import ClientsList from "./pages/ClientsList/ClientsList";
import LawyersList from "./pages/LawyersList/LawyersList";
import AdminsList from "./pages/AdminsList/AdminsList";
import LawyerProfile from "./pages/LawyerProfile/LawyerProfile";
import AdminProfile from "./pages/AdminProfile/AdminProfile";
import Profile from "./pages/Profile/Profile";
import EProfile from "./pages/E_Profile/EProfile";
import Plans from "./pages/Plans/Plans";
import Plan from "./pages/Plan/Plan";
import AddPlan from "./pages/AddPlan/AddPlan";
import EditPlan from "./pages/EditPlan/EditPlan";
import EditLawyer from "./pages/EditLawyer/EditLawyer";
import EditAdmin from "./pages/EditAdmin/EditAdmin";
import EditClient from "./pages/EditClient/EditClient";
import CheckE from "./pages/CheckE/CheckE";
import CheckQuestions from "./pages/CheckQuestions/CheckQuestions";
import FilteredPlans from "./pages/FilteredPlans/FilteredPlans";
import { RequireAuth } from "react-auth-kit";
import Settings from "./pages/Settings/Settings";
import Questions from "./pages/Questions";
import Answers from "./pages/Answers";


// Theming
const theme = createTheme({
  palette : {
    primary : {
      main : "#124367"
    },
    secondary : {
      main : "#D20000"
    },
    text : {
      primary : "#070707",
    },
  },
  spacing : 2,
  typography : {

    "fontFamily": `'Poppins', sans-serif`,
    
    button : {
      textTransform : "capitalize",
    }
  }
})

AOS.init({
  duration: 2000,
});


function App() {
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/calculator" element={
              <RequireAuth loginPath="/">
                <Calculator />
              </RequireAuth>
            } />
            <Route path="/clients-list" element={
              <RequireAuth loginPath="/">
                <ClientsList />
              </RequireAuth>
            } />
            <Route path="/admins-list" element={
              <RequireAuth loginPath="/">
                <AdminsList />
              </RequireAuth>
            } />
            {<Route path="/lawyers-list" element={
              <RequireAuth loginPath="/">
                <LawyersList />
              </RequireAuth>
            } />}
            <Route path="/clients-list/add-client" element={
              <RequireAuth loginPath="/">
                <AddClient />
              </RequireAuth>
            } />
            {<Route path="/lawyers-list/add-lawyer" element={
              <RequireAuth loginPath="/">
                <AddLawyer />
              </RequireAuth>
            } />}
            {<Route path="/admins-list/add-admin" element={
              <RequireAuth loginPath="/">
                <AddAdmin />
              </RequireAuth>
            } />}
            <Route path="/clients-list/:id" element={
              <RequireAuth loginPath="/">
                <Profile />
              </RequireAuth>
            } />
            <Route path="/lawyers-list/:id" element={
              <RequireAuth loginPath="/">
                <LawyerProfile />
              </RequireAuth>
            } />
            <Route path="/admins-list/:id" element={
              <RequireAuth loginPath="/">
                <AdminProfile />
              </RequireAuth>
            } />
            <Route path="/employee-profile" element={
              <RequireAuth loginPath="/">
                <EProfile />
              </RequireAuth>
            } />
            <Route path="/plans" element={
              <RequireAuth loginPath="/">
                <Plans />
              </RequireAuth>
            } />
            <Route path="/plans/:id" element={
              <RequireAuth loginPath="/">
                <Plan />
              </RequireAuth>
            } />
            {<Route path="/add-plan" element={
              <RequireAuth loginPath="/">
                <AddPlan />
              </RequireAuth>
            } />}
            {<Route path="/edit-plan/:id" element={
              <RequireAuth loginPath="/">
                <EditPlan />
              </RequireAuth>
            } />}
            {<Route path="/lawyers-list/edit-lawyer/:id" element={
              <RequireAuth loginPath="/">
                <EditLawyer />
              </RequireAuth>
            } />}
            {<Route path="/admins-list/edit-admin/:id" element={
              <RequireAuth loginPath="/">
                <EditAdmin />
              </RequireAuth>
            } />}
            {<Route path="/clients-list/edit-client/:id" element={
              <RequireAuth loginPath="/">
                <EditClient />
              </RequireAuth>
            } />}
            <Route path="/check-eligibility" element={
              <RequireAuth loginPath="/">
                <CheckE />
              </RequireAuth>
            } />
            <Route path="/check-eligibility/:id" element={
              <RequireAuth loginPath="/">
                <CheckQuestions />
              </RequireAuth>
            } />
            <Route path="/check-eligibility/:id/filtered-plans" element={
              <RequireAuth loginPath="/">
                <FilteredPlans />
              </RequireAuth>
            } />
            <Route path="/settings" element={
              <RequireAuth loginPath="/">
                <Settings />
              </RequireAuth>
            } />
            <Route path="/settings/questions" element={
              <RequireAuth loginPath="/">
                <Questions />
              </RequireAuth>
            } />
            <Route path="/settings/questions/:id" element={
              <RequireAuth loginPath="/">
                <Answers />
              </RequireAuth>
            } />
            <Route path="*" element={<div>Page is not found</div>} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
