import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styling/style.scss";
import { AuthProvider } from 'react-auth-kit';
import store from './store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider 
        authType = {'cookie'}
        authName={'_auth'}
        cookieDomain={window.location.hostname}
        cookieSecure={false}>
            <Provider store={store} >
                <App />
            </Provider>
    </AuthProvider>
);
