import { Button, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import Bread from "../../components/Bread/Bread";
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Calculator.scss"
import { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Link } from "react-router-dom";
import PopUp from "../../components/PopUp/PopUp";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Calculator = () => {
  // Bread Links
  const links = [
      {
          name: "Dashboard",
          to: "/",
      },
      {
          name: "Calculator",
          to: "/calculator",
      },
  ];

  const [isMarried, setIsMarried] = useState(false)
  // english cumulative
  const [WorkOut, setWorkOut] = useState(0)
  const [WorkIn, setWorkIn] = useState(0)

  const [score, setScore] = useState(0)
  const [score2, setScore2] = useState(0)
  const [totalScore, setTotalScore] = useState(0)

  const [higher7, setHigher7] = useState(false)
  const [higher9, setHigher9] = useState(false)
  // FSW Score
  const [totalFswScore, setTotalFswScore] = useState(0)
  const [fswScore, setFswScore] = useState(0)
  const [fswScore2, setFswScore2] = useState(0)

  const [add10, setAdd10] = useState(0)
  const [add10_2, setAdd10_2] = useState(0)
  
  // End FSW Score

  // CEC Score

  const [workedAtLeast, setWorkedAtLeast] = useState(false)
  const [cec, setCec] = useState(false)

  useEffect(() => {

    if ( higher7 && WorkIn > 0 && workedAtLeast ) setCec(true)
    else setCec(false)

  } , [higher7 , WorkIn , workedAtLeast])
  
  // End CEC Score


  useEffect(() => {
    setTotalScore( score + score2 )
  } , [score , score2])

  useEffect(() => {

    let total = 0;

    add10 + add10_2 <= 10 ? total = add10 + add10_2 : total = 10
    
    setTotalFswScore( fswScore + fswScore2 + total )
  } , [fswScore , fswScore2 , add10 , add10_2])


  // Pop Ups

  const [active, setActive] = useState(false);

  const [activeCancel, setActiveCancel] = useState(false);

  const [activeClear, setActiveClear] = useState(false);
  
  
  const popRej = {
      icon: (
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="104"
              height="103"
              viewBox="0 0 104 103"
              fill="none"
          >
              <path
                  d="M98.9316 51.1699C98.9316 77.1273 77.889 98.1699 51.9316 98.1699C25.9743 98.1699 4.93164 77.1273 4.93164 51.1699C4.93164 25.2125 25.9743 4.16991 51.9316 4.16991C77.889 4.16991 98.9316 25.2125 98.9316 51.1699Z"
                  stroke="#D20000"
                  strokeWidth="6"
              />
              <path
                  d="M51.9317 99.3351C78.5326 99.3351 100.097 77.7709 100.097 51.17C100.097 24.5691 78.5326 3.00486 51.9317 3.00486C25.3309 3.00486 3.7666 24.5691 3.7666 51.17C3.7666 77.7709 25.3309 99.3351 51.9317 99.3351Z"
                  stroke="#D20000"
                  strokeWidth="6"
              />
              <path
                  d="M51.9317 54.7562L34.0007 72.6872C33.4884 73.1996 32.8907 73.4557 32.2076 73.4557C31.5245 73.4557 30.9268 73.1996 30.4145 72.6872C29.9022 72.1749 29.646 71.5772 29.646 70.8941C29.646 70.211 29.9022 69.6133 30.4145 69.101L48.3455 51.17L30.4145 33.239C29.9022 32.7267 29.646 32.129 29.646 31.4459C29.646 30.7628 29.9022 30.1651 30.4145 29.6528C30.9268 29.1405 31.5245 28.8843 32.2076 28.8843C32.8907 28.8843 33.4884 29.1405 34.0007 29.6528L51.9317 47.5838L69.8627 29.6528C70.375 29.1405 70.9727 28.8843 71.6558 28.8843C72.3389 28.8843 72.9366 29.1405 73.4489 29.6528C73.9613 30.1651 74.2174 30.7628 74.2174 31.4459C74.2174 32.129 73.9613 32.7267 73.4489 33.239L55.5179 51.17L73.4489 69.101C73.9613 69.6133 74.2174 70.211 74.2174 70.8941C74.2174 71.5772 73.9613 72.1749 73.4489 72.6872C72.9366 73.1996 72.3389 73.4557 71.6558 73.4557C70.9727 73.4557 70.375 73.1996 69.8627 72.6872L51.9317 54.7562Z"
                  fill="#D20000"
              />
          </svg>
      ),
      message: `application score is ${totalScore}`,
      message2: "Sorry, this application is rejected",
      button1: (
          <Link to="/">
              <Button
                  color="primary"
                  variant="outlined"
                  sx={{ py: 8, px: { xs: 40, sm: 100 }, borderRadius: "8px" }}
              >
                  Cancel
              </Button>
          </Link>
      ),
  };

  const popAcc = {
      icon: (
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="103"
              height="104"
              viewBox="0 0 103 104"
              fill="none"
          >
              <path
                  d="M98.2109 52C98.2109 77.9574 77.1683 99 51.2109 99C25.2536 99 4.21094 77.9574 4.21094 52C4.21094 26.0426 25.2536 5 51.2109 5C77.1683 5 98.2109 26.0426 98.2109 52Z"
                  stroke="#71D54F"
                  strokeWidth="6"
              />
              <path
                  d="M51.211 100.165C77.8119 100.165 99.3762 78.601 99.3762 52.0001C99.3762 25.3992 77.8119 3.83495 51.211 3.83495C24.6102 3.83495 3.0459 25.3992 3.0459 52.0001C3.0459 78.601 24.6102 100.165 51.211 100.165Z"
                  stroke="#71D54F"
                  strokeWidth="6"
              />
              <path
                  d="M26.2109 56.2857L39.7824 69.8571"
                  stroke="#71D54F"
                  strokeWidth="6"
                  strokeLinecap="round"
              />
              <path
                  d="M39.7822 69.8571L75.4965 34.1429"
                  stroke="#71D54F"
                  strokeWidth="6"
                  strokeLinecap="round"
              />
          </svg>
      ),
      message: `application score is ${totalScore}`,
      message2: "congrats, this application is qualified",
      button1: (
          <Link to="/clients-list/add-client">
              <Button
                  color="primary"
                  variant="contained"
                  sx={{
                      width: "250px",
                      py: 8,
                      px: { xs: 40, sm: 100 },
                      borderRadius: "8px",
                  }}
              >
                  register
              </Button>
          </Link>
      ),
  };

  const cancelConfirm = {
      icon: (
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="104"
              height="104"
              viewBox="0 0 104 104"
              fill="none"
          >
              <path
                  d="M99 51.9899C99 77.9473 77.9574 98.9899 52 98.9899C26.0426 98.9899 5 77.9473 5 51.9899C5 26.0325 26.0426 4.98987 52 4.98987C77.9574 4.98987 99 26.0325 99 51.9899Z"
                  stroke="#E7511D"
                  strokeWidth="6"
              />
              <path
                  d="M52.0001 100.155C78.601 100.155 100.165 78.5908 100.165 51.99C100.165 25.3891 78.601 3.82483 52.0001 3.82483C25.3992 3.82483 3.83496 25.3891 3.83496 51.99C3.83496 78.5908 25.3992 100.155 52.0001 100.155Z"
                  stroke="#E7511D"
                  strokeWidth="6"
              />
              <path
                  d="M52.0001 55.5761L34.069 73.5072C33.5567 74.0195 32.959 74.2756 32.2759 74.2756C31.5928 74.2756 30.9951 74.0195 30.4828 73.5072C29.9705 72.9948 29.7144 72.3971 29.7144 71.7141C29.7144 71.031 29.9705 70.4333 30.4828 69.921L48.4139 51.9899L30.4828 34.0589C29.9705 33.5466 29.7144 32.9489 29.7144 32.2658C29.7144 31.5827 29.9705 30.985 30.4828 30.4727C30.9951 29.9604 31.5928 29.7042 32.2759 29.7042C32.959 29.7042 33.5567 29.9604 34.069 30.4727L52.0001 48.4037L69.9311 30.4727C70.4434 29.9604 71.0411 29.7042 71.7242 29.7042C72.4073 29.7042 73.005 29.9604 73.5173 30.4727C74.0296 30.985 74.2858 31.5827 74.2858 32.2658C74.2858 32.9489 74.0296 33.5466 73.5173 34.0589L55.5863 51.9899L73.5173 69.921C74.0296 70.4333 74.2858 71.031 74.2858 71.7141C74.2858 72.3971 74.0296 72.9948 73.5173 73.5072C73.005 74.0195 72.4073 74.2756 71.7242 74.2756C71.0411 74.2756 70.4434 74.0195 69.9311 73.5072L52.0001 55.5761Z"
                  fill="#E7511D"
              />
          </svg>
      ),
      message: `are you sure you want to cancel ?`,
      button1: (
          <Link to="/">
              <Button
                  color="primary"
                  variant="outlined"
                  sx={{
                      width: "250px",
                      py: 8,
                      px: { xs: 20, sm: 40 },
                      borderRadius: "8px",
                  }}
              >
                  yes
              </Button>
          </Link>
      ),
      button2: (
          <Button
              color="secondary"
              onClick={() => setActiveCancel(false)}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              no
          </Button>
      ),
  };

  const clearConfirm = {
      icon: (
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="79"
              height="101"
              viewBox="0 0 79 101"
              fill="none"
          >
              <path
                  d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z"
                  fill="#E7511D"
              />
          </svg>
      ),
      message: `are you sure you want to clear all ?`,
      button1: (
          <Button
              color="primary"
              onClick={() => window.location.reload()}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              yes
          </Button>
      ),
      button2: (
          <Button
              color="secondary"
              onClick={() => setActiveClear(false)}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              no
          </Button>
      ),
  };
  
  
  return (
    <>
      <Navbar />
      <div style={{ display: "flex" }}>
          <Sidebar />
          <div className="Calculator main-section" data-aos="fade-down">
              <Bread links={links} />
              
              <Stack 
                className="sector 1" 
                bgcolor="white"
                p={8}
                pb={12}
                mb={12}
                borderRadius="8px"
              >
                <CalcGroupHead title="personal applicant" />
                <Group1 
                  isMarried={isMarried} 
                  setIsMarried={setIsMarried} 
                  setWorkOut={setWorkOut} 
                  WorkOut={WorkOut} 
                  WorkIn={WorkIn} 
                  setScore={setScore} 
                  higher7={higher7}
                  setHigher7={setHigher7} 
                  higher9={higher9}
                  setHigher9={setHigher9} 
                  setFswScore={setFswScore}
                  setAdd10={setAdd10} 
                />
              </Stack>

              <Stack 
                className="sector 1" 
                bgcolor="white"
                p={8}
                pb={12}
                mb={12}
                borderRadius="8px"
              >
                <CalcGroupHead title="Skilled Work Experience" />
                <Group2 
                  isMarried={isMarried} 
                  setIsMarried={setIsMarried} 
                  setWorkOut={setWorkOut} 
                  WorkOut={WorkOut} 
                  WorkIn={WorkIn} 
                  setWorkIn={setWorkIn} 
                  setScore={setScore2} 
                  higher7={higher7}
                  higher9={higher9}
                  setFswScore={setFswScore2} 
                  setAdd10={setAdd10_2}
                  setWorkedAtLeast={setWorkedAtLeast}
                  workedAtLeast={workedAtLeast}
                />
              </Stack>

              <CopyWriting />

              {/* pop ups */}
              {active && (
                  <PopUp
                      setActive={setActive}
                      pop={totalScore > 250 ? popAcc : popRej}
                  />
              )}

              {activeCancel && (
                  <PopUp
                      setActive={setActiveCancel}
                      pop={cancelConfirm}
                  />
              )}

              {activeClear && (
                  <PopUp setActive={setActiveClear} pop={clearConfirm} />
              )}

              <Submit
                setActive={setActive}
                setActiveCancel={setActiveCancel}
                setActiveClear={setActiveClear}
              />
          </div>
          <div className="cumulative">
            <div className="content">
              <h4>CRS Points</h4>
              <h5>{totalScore}</h5>
            </div>
            <div className="content">
              <h4>FSW Points</h4>
              <h5>{totalFswScore}
              {totalFswScore > 67 ? <CheckCircleIcon sx={{ color : "green" }} /> : <CancelIcon sx={{ color : "red" }} />  }  
              </h5>
            </div>
            <div className="content">
              <h4>CEC Points</h4>
              <h5>{cec ? <CheckCircleIcon sx={{ color : "green" }} /> : <CancelIcon sx={{ color : "red" }} />  } </h5>
            </div>
          </div>
      </div>
    </>
  )
}

const Group1 = ({isMarried , setIsMarried  , WorkOut , WorkIn , setScore , setHigher7 , setFswScore , setHigher9 , setAdd10 , higher7 , higher9}) => {

  // How Old Are You?
  const [Q1, setQ1] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  // Certification
  const [Q2, setQ2] = useState({
    married : 0,
    notMarried: 0,
  })
  // Certification const
  const [Q2_2, setQ2_2] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0

  })
  // English lang 
  const [Q3_1, setQ3_1] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q3_2, setQ3_2] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q3_3, setQ3_3] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q3_4, setQ3_4] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  // french lang
  const [Q4_1, setQ4_1] = useState({
    married : 0,
    notMarried: 0,

  })
  const [Q4_2, setQ4_2] = useState({
    married : 0,
    notMarried: 0,

  })
  const [Q4_3, setQ4_3] = useState({
    married : 0,
    notMarried: 0,

  })
  const [Q4_4, setQ4_4] = useState({
    married : 0,
    notMarried: 0,
  })
  // Q6 have you completed 
  const [Q6, setQ6] = useState({
    married : 0,
    notMarried: 0,
  })
  // Q7 have you obtained  ? 
  const [Q7, setQ7] = useState({
    married : 0,
    notMarried: 0,
  })
  // Were You physically
  const [Q8, setQ8] = useState({
    married : 0,
    notMarried: 0,
  })
  // were you enrolled
  const [Q9, setQ9] = useState({
    married : 0,
    notMarried: 0,
  })
  // wife education
  const [Q10, setQ10] = useState({
    married : 0,
    notMarried: 0,
  })
  // wife English
  const [Q11_1, setQ11_1] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q11_2, setQ11_2] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q11_3, setQ11_3] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q11_4, setQ11_4] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  // wife french
  const [Q13_1, setQ13_1] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q13_2, setQ13_2] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q13_3, setQ13_3] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  const [Q13_4, setQ13_4] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  // Spouse Work exp
  const [Q12, setQ12] = useState({
    married : 0,
    notMarried: 0,
  })
  // Spouse Studied 2 years in canada
  const [Q14, setQ14] = useState(false)
  
  const [Studied, setStudied] = useState(false)
  const [WereU, setWereU] = useState(false)

  const reset6789 = () => {
    setQ6({married : 0 , notMarried : 0})
    setQ7({married : 0 , notMarried : 0})
    setQ8({married : 0 , notMarried : 0})
    setQ9({married : 0 , notMarried : 0})
  }

  // French Cumulative
  const [FrenchCalc, setFrenchCalc] = useState(0)

  useEffect(()=>{
    const calc = Q4_1.notMarried + Q4_2.notMarried + Q4_3.notMarried + Q4_4.notMarried
    
    isMarried && calc > 22 ? setFrenchCalc(22) : setFrenchCalc(calc)
    
    console.log(FrenchCalc)
  } , [Q4_1, Q4_2, Q4_3, Q4_4 , isMarried , FrenchCalc])


  // English Cumulative
  const [EnglishCalc, setEnglishCalc] = useState(0)

  useEffect(()=>{
    const calcm = Q3_1.notMarried + Q3_2.notMarried + Q3_3.notMarried + Q3_4.notMarried
    const calcn = Q3_1.married + Q3_2.married + Q3_3.married + Q3_4.married
    
    isMarried ? setEnglishCalc(calcn) : setEnglishCalc(calcm)
    
    console.log(EnglishCalc)

  } , [Q3_1, Q3_2, Q3_3, Q3_4 , isMarried , EnglishCalc])

  // Work out & language
  useEffect(() => {

    const calcm = Q3_1.notMarried + Q3_2.notMarried + Q3_3.notMarried + Q3_4.notMarried
    const calcn = Q3_1.married + Q3_2.married + Q3_3.married + Q3_4.married


    if (
      (Q3_1.notMarried === 17 || Q3_1.notMarried === 23 || Q3_1.married === 16 || Q3_1.married === 22) &&
      (Q3_2.notMarried === 17 || Q3_2.notMarried === 23 || Q3_2.married === 16 || Q3_2.married === 22) &&
      (Q3_3.notMarried === 17 || Q3_3.notMarried === 23 || Q3_3.married === 16 || Q3_3.married === 22) &&
      (Q3_4.notMarried === 17 || Q3_4.notMarried === 23 || Q3_4.married === 16 || Q3_4.married === 22) 
    ) {

      if(WorkOut === 0) {
        isMarried ? setEnglishCalc(calcn) : setEnglishCalc(calcm)
      }
      if(WorkOut === 1 || WorkOut === 2) {
        isMarried ? setEnglishCalc(calcn + 13) : setEnglishCalc(calcm + 13)
      }
      if(WorkOut > 2) {
        isMarried ? setEnglishCalc(calcn + 25) : setEnglishCalc(calcm + 25)
      }

    }

    if (
      (Q3_1.notMarried >= 29 || Q3_1.married >= 29) &&
      (Q3_2.notMarried >= 29 || Q3_2.married >= 29) &&
      (Q3_3.notMarried >= 29 || Q3_3.married >= 29) &&
      (Q3_4.notMarried >= 29 || Q3_4.married >= 29) 
    ) {

      if(WorkOut === 0) {
        isMarried ? setEnglishCalc(calcn) : setEnglishCalc(calcm)
      }
      if(WorkOut === 1 || WorkOut === 2) {
        isMarried ? setEnglishCalc(calcn + 25) : setEnglishCalc(calcm + 25)
      }
      if(WorkOut > 2) {
        isMarried ? setEnglishCalc(calcn + 50) : setEnglishCalc(calcm + 50)
      }

    }
    
    
  } , [WorkOut , WorkIn , Q3_1, Q3_2, Q3_3, Q3_4 , isMarried , EnglishCalc])
  

  // education & language
  // useEffect(()=>{

    
  //   // (Q3_1.notMarried === 17 || Q3_1.notMarried === 23 || Q3_1.married === 16 || Q3_1.married === 22) &&
  //   // (Q3_2.notMarried === 17 || Q3_2.notMarried === 23 || Q3_2.married === 16 || Q3_2.married === 22) &&
  //   // (Q3_3.notMarried === 17 || Q3_3.notMarried === 23 || Q3_3.married === 16 || Q3_3.married === 22) &&
  //   // (Q3_4.notMarried === 17 || Q3_4.notMarried === 23 || Q3_4.married === 16 || Q3_4.married === 22) 
  

  //   // Level 7 , 8
  //   if (higher7 && !higher9) {

  //     if ( Q2_2.married === 0 || Q2_2.notMarried === 0 ){
  //       setQ2({married : 0 , notMarried : 0})
  //       setQ2_2({married : 0 , notMarried : 0 , fsw : Q2_2.fsw})
  //     }if ( Q2_2.married === 28 || Q2_2.notMarried === 30 ){
  //       setQ2({married : 28 , notMarried : 30})
  //       setQ2_2({married : 28 , notMarried : 30 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 84 || Q2_2.notMarried === 90 ){
  //       setQ2({married : 97 , notMarried : 103})
  //       setQ2_2({married : 97 , notMarried : 103 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 91 || Q2_2.notMarried === 98 ){
  //       setQ2({married : 104 , notMarried : 111})
  //       setQ2_2({married : 104 , notMarried : 111 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 112 || Q2_2.notMarried === 120 ){
  //       setQ2({married : 125 , notMarried : 133})
  //       setQ2_2({married : 125 , notMarried : 133 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 119 || Q2_2.notMarried === 128 ){
  //       setQ2({married : 144 , notMarried : 153})
  //       setQ2_2({married : 144 , notMarried : 153 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 126 || Q2_2.notMarried === 135 ){
  //       setQ2({married : 151 , notMarried : 160})
  //       setQ2_2({married : 151 , notMarried : 160 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 140 || Q2_2.notMarried === 150 ){
  //       setQ2({married : 165 , notMarried : 175})
  //       setQ2_2({married : 165 , notMarried : 175 , fsw : Q2_2.fsw})
  //     }

      
  //   } else if (higher9) {

  //     if ( Q2_2.married === 0 || Q2_2.notMarried === 0 ){
  //       setQ2({married : 0 , notMarried : 0})
  //       setQ2_2({married : 0 , notMarried : 0 , fsw : Q2_2.fsw})
  //     }if ( Q2_2.married === 28 || Q2_2.notMarried === 30 ){
  //       setQ2({married : 28 , notMarried : 30})
  //       setQ2_2({married : 28 , notMarried : 30 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 84 || Q2_2.notMarried === 90 ){
  //       setQ2({married : 109 , notMarried : 115})
  //       setQ2_2({married : 109 , notMarried : 115 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 91 || Q2_2.notMarried === 98 ){
  //       setQ2({married : 116 , notMarried : 123})
  //       setQ2_2({married : 116 , notMarried : 123 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 112 || Q2_2.notMarried === 120 ){
  //       setQ2({married : 137 , notMarried : 145})
  //       setQ2_2({married : 137 , notMarried : 145 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 119 || Q2_2.notMarried === 128 ){
  //       setQ2({married : 169 , notMarried : 178})
  //       setQ2_2({married : 169 , notMarried : 178 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 126 || Q2_2.notMarried === 135 ){
  //       setQ2({married : 176 , notMarried : 185})
  //       setQ2_2({married : 176 , notMarried : 185 , fsw : Q2_2.fsw})
  //     }
  //     if ( Q2_2.married === 140 || Q2_2.notMarried === 150 ){
  //       setQ2({married : 190 , notMarried : 200})
  //       setQ2_2({married : 190 , notMarried : 200 , fsw : Q2_2.fsw})
  //     }

  //   } else {
  //     setQ2({married : Q2_2.married , notMarried : Q2_2.notMarried})
  //     setQ2_2({married : Q2_2.married , notMarried : Q2_2.notMarried , fsw : Q2_2.fsw})
  //   }
  //   // > 9
    
    
  // } , [ Q2_2 , Q2 , isMarried , higher7 , higher9 ])

  // education & workin
  // useEffect(()=>{
  //   // console.log("Work In & education", WorkIn)
  //   // Level 7 , 8
  //   if (WorkIn === 1) {

  //     if ( Q2_2.married === 0 || Q2_2.notMarried === 0 ){
  //       setQ2({married : 0 , notMarried : 0})
  //     }if ( Q2_2.married === 28 || Q2_2.notMarried === 30 ){
  //       setQ2({married : 28 , notMarried : 30})
  //     }
  //     if ( Q2_2.married === 84 || Q2_2.notMarried === 90 ){
  //       setQ2({married : 97 , notMarried : 103})
  //     }
  //     if ( Q2_2.married === 91 || Q2_2.notMarried === 98 ){
  //       setQ2({married : 104 , notMarried : 111})
  //     }
  //     if ( Q2_2.married === 112 || Q2_2.notMarried === 120 ){
  //       setQ2({married : 125 , notMarried : 133})
  //     }
  //     if ( Q2_2.married === 119 || Q2_2.notMarried === 128 ){
  //       setQ2({married : 144 , notMarried : 153})
  //     }
  //     if ( Q2_2.married === 126 || Q2_2.notMarried === 135 ){
  //       setQ2({married : 151 , notMarried : 160})
  //     }
  //     if ( Q2_2.married === 140 || Q2_2.notMarried === 150 ){
  //       setQ2({married : 165 , notMarried : 175})
  //     }

      
  //   } else if (WorkIn > 1) {

  //     if ( Q2_2.married === 0 || Q2_2.notMarried === 0 ){
  //       setQ2({married : 0 , notMarried : 0})
  //     }if ( Q2_2.married === 28 || Q2_2.notMarried === 30 ){
  //       setQ2({married : 28 , notMarried : 30})
  //     }
  //     if ( Q2_2.married === 84 || Q2_2.notMarried === 90 ){
  //       setQ2({married : 109 , notMarried : 115})
  //     }
  //     if ( Q2_2.married === 91 || Q2_2.notMarried === 98 ){
  //       setQ2({married : 115 , notMarried : 123})
  //     }
  //     if ( Q2_2.married === 112 || Q2_2.notMarried === 120 ){
  //       setQ2({married : 137 , notMarried : 145})
  //     }
  //     if ( Q2_2.married === 119 || Q2_2.notMarried === 128 ){
  //       setQ2({married : 169 , notMarried : 178})
  //     }
  //     if ( Q2_2.married === 126 || Q2_2.notMarried === 135 ){
  //       setQ2({married : 176 , notMarried : 185})
  //     }
  //     if ( Q2_2.married === 140 || Q2_2.notMarried === 150 ){
  //       setQ2({married : 190 , notMarried : 200})
  //     }

  //   } else {
  //     setQ2({married : Q2_2.married , notMarried : Q2_2.notMarried})
  //   }
    
    
  // } , [WorkIn , Q2_2 , Q2 , isMarried])


  const [eduLvl, seteduLvl] = useState(0)

  useEffect(() => {

    if ( higher9 ) {

      console.log("You Are In Higher 9")

      if ( eduLvl > 1 && eduLvl < 5 ) {
        console.log("You Are In Edu 2-4")
        if (WorkIn === 0 ) setQ2({married : Q2_2.married + 25 , notMarried :Q2_2.notMarried + 25 , fsw : Q2_2.fsw })
        if (WorkIn === 1 ) setQ2({married : Q2_2.married + 38 , notMarried :Q2_2.notMarried + 38 , fsw : Q2_2.fsw })
        if (WorkIn > 1 ) setQ2({married : Q2_2.married + 50 , notMarried :Q2_2.notMarried + 50 , fsw : Q2_2.fsw })
      } 
      else if ( eduLvl >= 5 ) {
        console.log("You Are In Edu > 5")
        if (WorkIn === 0 ) setQ2({married : Q2_2.married + 50 , notMarried :Q2_2.notMarried + 50 , fsw : Q2_2.fsw })
        if (WorkIn === 1 ) setQ2({married : Q2_2.married + 50 , notMarried :Q2_2.notMarried + 50 , fsw : Q2_2.fsw })
        if (WorkIn > 1 ) setQ2({married : Q2_2.married + 50 , notMarried :Q2_2.notMarried + 50 , fsw : Q2_2.fsw })
      }
      else if ( eduLvl <= 1 ) {
        console.log("You Are In Edu <= 1")
        setQ2({married : Q2_2.married , notMarried : Q2_2.notMarried , fsw : Q2_2.fsw})
      }
    } 
    else {

      if ( higher7 ) {

        if ( eduLvl > 1 && eduLvl < 5 ) {
          console.log("You Are In Edu 2-4")
          if (WorkIn === 0 ) setQ2({married : Q2_2.married + 13 , notMarried :Q2_2.notMarried + 13 , fsw : Q2_2.fsw })
          if (WorkIn === 1 ) setQ2({married : Q2_2.married + 26 , notMarried :Q2_2.notMarried + 26 , fsw : Q2_2.fsw })
          if (WorkIn > 1 ) setQ2({married : Q2_2.married + 38 , notMarried :Q2_2.notMarried + 38 , fsw : Q2_2.fsw })
        } 
        else if ( eduLvl >= 5 ) {
          console.log("You Are In Edu > 5")
          if (WorkIn === 0 ) setQ2({married : Q2_2.married + 25 , notMarried :Q2_2.notMarried + 25 , fsw : Q2_2.fsw })
          if (WorkIn === 1 ) setQ2({married : Q2_2.married + 50 , notMarried :Q2_2.notMarried + 50 , fsw : Q2_2.fsw })
          if (WorkIn > 1 ) setQ2({married : Q2_2.married + 50 , notMarried :Q2_2.notMarried + 50 , fsw : Q2_2.fsw })
        }
        else if ( eduLvl <= 1 ) {
          console.log("You Are In Edu <= 1")
          setQ2({married : Q2_2.married , notMarried : Q2_2.notMarried , fsw : Q2_2.fsw})
        }
        
      }

      else {
        // No Higher 7 No Higher 9
        console.log("No Higher 7 No Higher 9")
        if ( eduLvl > 1 && eduLvl < 5 ) {
          console.log("You Are In Edu 2-4")
          if (WorkIn === 0 ) setQ2({married : Q2_2.married , notMarried : Q2_2.notMarried , fsw : Q2_2.fsw })
          if (WorkIn === 1 ) setQ2({married : Q2_2.married + 13 , notMarried :Q2_2.notMarried + 13 , fsw : Q2_2.fsw })
          if (WorkIn > 1 ) setQ2({married : Q2_2.married + 25 , notMarried :Q2_2.notMarried + 25 , fsw : Q2_2.fsw })
        } 
        else if ( eduLvl >= 5 ) {
          console.log("You Are In Edu > 5")
          if (WorkIn === 0 ) setQ2({married : Q2_2.married , notMarried : Q2_2.notMarried , fsw : Q2_2.fsw })
          if (WorkIn === 1 ) setQ2({married : Q2_2.married + 25 , notMarried :Q2_2.notMarried + 25 , fsw : Q2_2.fsw })
          if (WorkIn > 1 ) setQ2({married : Q2_2.married + 50 , notMarried :Q2_2.notMarried + 50 , fsw : Q2_2.fsw })
        }
        
      }

    }
    
  } , [ higher9 , higher7 , eduLvl , WorkIn ])
  
  
  
  
  
  
  
  
  
  // Spouse French
  const [FrechSpouse, setFrechSpouse] = useState(0)

  useEffect(() => {
      
    const calc = Q13_1.notMarried + Q13_2.notMarried + Q13_3.notMarried + Q13_4.notMarried
    const calc2 = Q11_1.notMarried + Q11_2.notMarried + Q11_3.notMarried + Q11_4.notMarried
    
    calc + calc2 <= 20 && setFrechSpouse(calc)
    
  } , [Q11_1, Q11_2, Q11_3, Q11_4 , Q13_1 , Q13_2 , Q13_3 , Q13_4])


  // Spouse English calc
  const [EnglishSpouse, setEnglishSpouse] = useState(0)

  useEffect(()=>{

    const calc = Q11_1.notMarried + Q11_2.notMarried + Q11_3.notMarried + Q11_4.notMarried
    const calc2 = Q13_1.notMarried + Q13_2.notMarried + Q13_3.notMarried + Q13_4.notMarried

    calc + calc2 <= 20 && setEnglishSpouse(calc)
    
  } , [Q11_1, Q11_2, Q11_3, Q11_4 , Q13_1 , Q13_2 , Q13_3 , Q13_4 , isMarried , EnglishSpouse])


  // Languages Conditions
  const [HL, setHL] = useState(0)
  const [HH, setHH] = useState(0)
  
  useEffect(() => {

    if (
      (Q4_1.notMarried >= 3) &&
      (Q4_2.notMarried >= 3) &&
      (Q4_3.notMarried >= 3) &&
      (Q4_4.notMarried >= 3) 
    ) {
      if (
        (Q3_1.notMarried <= 6) &&
        (Q3_2.notMarried <= 6) &&
        (Q3_3.notMarried <= 6) &&
        (Q3_4.notMarried <= 6)
      ) {
        setHL(25)
        setHH(0)
      }
      if(
        (Q3_1.notMarried > 6) &&
        (Q3_2.notMarried > 6) &&
        (Q3_3.notMarried > 6) &&
        (Q3_4.notMarried > 6)
      ) {
        setHH(50)
        setHL(0)
      }
    } else {
      setHL(0)
      setHH(0)
    }

    
    
  } , [Q3_1 , Q3_2 , Q3_3 , Q3_4 , Q4_1 , Q4_2 , Q4_3 , Q4_4])
  
  // Score
  useEffect(() => {

    isMarried ? 
    setScore(Q1.married + Q2.married + EnglishCalc + FrenchCalc + Q6.married + Q7.married + Q8.married + Q9.married + Q10.married + Q12.married + EnglishSpouse + FrechSpouse + HL + HH)
    :
    setScore(Q1.notMarried + Q2.notMarried + EnglishCalc + FrenchCalc + Q6.notMarried + Q7.notMarried + Q8.notMarried + Q9.notMarried + Q10.notMarried + Q12.notMarried + EnglishSpouse + FrechSpouse + HL + HH)
    
  } , [setScore ,Q1 , Q2 , EnglishCalc , FrenchCalc , Q6 , Q7 , Q8 , Q9 , Q10 , Q12 , EnglishSpouse , FrechSpouse , isMarried , HL , HH])
  

  // if higher than 7 
  useEffect(() => {
    if (
      (Q3_1.notMarried >= 17 || Q3_1.married >= 16) &&
      (Q3_2.notMarried >= 17 || Q3_2.married >= 16) &&
      (Q3_3.notMarried >= 17 || Q3_3.married >= 16) &&
      (Q3_4.notMarried >= 17 || Q3_4.married >= 16)
    ) {
      setHigher7(true)
    } else {
      setHigher7(false)
    }
  } , [Q3_1 , Q3_2 , Q3_3 , Q3_4 , setHigher7])
  // if higher than 9
  useEffect(() => {
    if (
      (Q3_1.notMarried >= 31 || Q3_1.married >= 29) &&
      (Q3_2.notMarried >= 31 || Q3_2.married >= 29) &&
      (Q3_3.notMarried >= 31 || Q3_3.married >= 29) &&
      (Q3_4.notMarried >= 31 || Q3_4.married >= 29)
    ) {
      setHigher9(true)
    } else {
      setHigher9(false)
    }
  } , [Q3_1 , Q3_2 , Q3_3 , Q3_4 , setHigher9])


  // FSW Score

  const [EF, setEF] = useState()
  
  useEffect(() => {

    let french;
    if (
      (Q4_1.notMarried >= 1) &&
      (Q4_2.notMarried >= 1) &&
      (Q4_3.notMarried >= 1) &&
      (Q4_4.notMarried >= 1)
    ) {
      french = 4;
    } else french = 0;

    
    let english = Q3_1.fsw + Q3_2.fsw + Q3_3.fsw + Q3_4.fsw

    if((french + english) < 28) {
      setEF(french + english)
    } else {
      setEF(28)
    } 
    
    setFswScore(Q1.fsw + Q2_2.fsw + EF )
  }, [Q1 , Q2_2 , Q3_1 , Q3_2 , Q3_3 , Q3_4 , Q4_1 , Q4_2 , Q4_3 , Q4_4 , setFswScore , EF])
  
  // Add 10

  useEffect(() => {
    // Wife Language
    let wl = 0;
    if ( 
      ( Q11_1.fsw === 5 && Q11_2.fsw === 5 && Q11_3.fsw === 5 && Q11_4.fsw === 5 ) ||
      (Q13_1.fsw === 5 && Q13_2.fsw === 5 && Q13_3.fsw === 5 && Q13_4.fsw === 5)
    ) {
      wl = 5;
    } else {
      wl = 0;
    }
    // Studied at least 2 years in canada
    let wl2 = 0;
    if ( Q6.married === 15 ) wl2 = 5; 
    else wl2 = 0;
    // Spouse Studied at least 2 years in canada
    let wl3 = 0;
    Q14 ? wl3 = 5 : wl3 = 0;
    // Spouse Work Exp
    let wl4 = 0;
    Q12.married  > 0 ? wl4 = 5 : wl4 = 0
    
    setAdd10(wl + wl2 + wl3 + wl4)
    console.log("samo 3liko")
  } , [Q11_1 , Q11_2 , Q11_3 , Q11_4 , Q13_1 , Q13_2 , Q13_3 , Q13_4 , setAdd10 , Q6 , Q14 , Q12])
  
  
  
  
  
  return (
    <Stack className="g1" spacing={12}>

      {/*Q1 - How old are you */}
      <Stack>
        
        <TextField 
          fullWidth
          select
          defaultValue=""
          label="how old are you ?"
        >

          <MenuItem value="17 years or less" onClick={() => {
            setQ1({married : 0 , notMarried : 0 , fsw : 0})
          }} >
            17 years or less
          </MenuItem>

          <MenuItem value="18 years" onClick={() => {
            setQ1({married : 90 , notMarried : 99 , fsw : 12 })
          }} >
            18 years
          </MenuItem>

          <MenuItem value="19 years" onClick={() => {
            setQ1({married : 95 , notMarried : 105 , fsw : 12 })
          }} >
            19 years
          </MenuItem>

          <MenuItem value="20 to 29 years" onClick={() => {
            setQ1({married : 100 , notMarried : 110 , fsw : 12 })
          }} >
            20 to 29 years
          </MenuItem>

          <MenuItem value="30 years" onClick={() => {
            setQ1({married : 95 , notMarried : 105 , fsw : 12 })
          }} >
            30 years
          </MenuItem>

          <MenuItem value="31 years" onClick={() => {
            setQ1({married : 90 , notMarried : 99 , fsw : 12 })
          }} >
            31 years
          </MenuItem>

          <MenuItem value="32 years" onClick={() => {
            setQ1({married : 85 , notMarried : 94 , fsw : 12 })
          }} >
            32 years
          </MenuItem>

          <MenuItem value="33 years" onClick={() => {
            setQ1({married : 80 , notMarried : 88 , fsw : 12 })
          }} >
            33 years
          </MenuItem>

          <MenuItem value="34 years" onClick={() => {
            setQ1({married : 75 , notMarried : 83 , fsw : 12 })
          }} >
            34 years
          </MenuItem>

          <MenuItem value="35 years" onClick={() => {
            setQ1({married : 70 , notMarried : 77 , fsw : 12 })
          }} >
            35 years
          </MenuItem>

          <MenuItem value="36 years" onClick={() => {
            setQ1({married : 65 , notMarried : 72 , fsw : 11})
          }} >
            36 years
          </MenuItem>

          <MenuItem value="37 years" onClick={() => {
            setQ1({married : 60 , notMarried : 66 , fsw : 10})
          }} >
            37 years
          </MenuItem>

          <MenuItem value="38 years" onClick={() => {
            setQ1({married : 55 , notMarried : 61 , fsw : 9})
          }} >
            38 years
          </MenuItem>

          <MenuItem value="39 years" onClick={() => {
            setQ1({married : 50 , notMarried : 55 , fsw : 8})
          }} >
            39 years
          </MenuItem>

          <MenuItem value="40 years" onClick={() => {
            setQ1({married : 45 , notMarried : 50 , fsw : 7})
          }} >
            40 years
          </MenuItem>

          <MenuItem value="41 years" onClick={() => {
            setQ1({married : 35 , notMarried : 39 , fsw : 6})
          }} >
            41 years
          </MenuItem>

          <MenuItem value="42 years" onClick={() => {
            setQ1({married : 25 , notMarried : 28 , fsw : 5})
          }} >
            42 years
          </MenuItem>

          <MenuItem value="43 years" onClick={() => {
            setQ1({married : 15 , notMarried : 17 , fsw : 4})
          }} >
            43 years
          </MenuItem>

          <MenuItem value="44 years" onClick={() => {
            setQ1({married : 5 , notMarried : 6 , fsw : 3})
          }} >
            44 years
          </MenuItem>

          <MenuItem value="45 years" onClick={() => {
            setQ1({married : 0 , notMarried : 0 , fsw : 2})
          }} >
            45 years
          </MenuItem>

          <MenuItem value="46 years" onClick={() => {
            setQ1({married : 0 , notMarried : 0 , fsw : 1})
          }} >
            45 years
          </MenuItem>

          <MenuItem value="47 years or more" onClick={() => {
            setQ1({married : 0 , notMarried : 0 , fsw : 0})
          }} >
            45 years
          </MenuItem>

        </TextField>
        
        
      </Stack>
      {/*Q2 What is your highest level of completed education? */}
      <Stack>
        
        <TextField 
          fullWidth
          select
          defaultValue=""
          label="What is your highest level of completed education?"
        >

          <MenuItem value="Less than high school (secondary school)" onClick={() => {
            setQ2({married : 0 , notMarried : 0})
            setQ2_2({married : 0 , notMarried : 0 , fsw : 0})
            seteduLvl(0)
          }} >
            Less than high school (secondary school)
          </MenuItem>

          <MenuItem value="High school diploma (secondary school graduation)" onClick={() => {
            setQ2({married : 28 , notMarried : 30})
            setQ2_2({married : 28 , notMarried : 30 , fsw : 5})
            seteduLvl(1)
          }} >
            High school diploma (secondary school graduation)
          </MenuItem>

          <MenuItem value="1-year diploma from college, university, technical or trade school, or other institute" onClick={() => {
            setQ2({married : 84 , notMarried : 90})
            setQ2_2({married : 84 , notMarried : 90 , fsw : 15})
            seteduLvl(2)
          }} >
            1-year diploma from college, university, technical or trade school, or other institute
          </MenuItem>

          <MenuItem value="2-year diploma from college, university, technical or trade school, or other institute" onClick={() => {
            setQ2({married : 91 , notMarried : 98})
            setQ2_2({married : 91 , notMarried : 98 , fsw : 19})
            seteduLvl(3)
          }} >
            2-year diploma from college, university, technical or trade school, or other institute
          </MenuItem>

          <MenuItem value="Bachelor's degree or 3-year diploma from college, university, technical or trade school, or other institute 	" onClick={() => {
            setQ2({married : 112 , notMarried : 120})
            setQ2_2({married : 112 , notMarried : 120 , fsw : 21})
            seteduLvl(4)
          }} >
            Bachelor's degree or 3-year diploma from college, university, technical or trade school, or other institute 
          </MenuItem>

          <MenuItem value="More than one degree, certificate or diploma (one must be for a 3-year or longer program)" onClick={() => {
            setQ2({married : 119 , notMarried : 128})
            setQ2_2({married : 119 , notMarried : 128 , fsw : 22})
            seteduLvl(5)
          }} >
            More than one degree, certificate or diploma (one must be for a 3-year or longer program)
          </MenuItem>

          <MenuItem value="Master's degree" onClick={() => {
            setQ2({married : 126 , notMarried : 135})
            setQ2_2({married : 126 , notMarried : 135 , fsw : 23})
            seteduLvl(6)
          }} >
            Master's degree
          </MenuItem>

          <MenuItem value="Doctoral level university degree (Ph.D.)" onClick={() => {
            setQ2({married : 140 , notMarried : 150})
            setQ2_2({married : 140 , notMarried : 150 , fsw : 25})
            seteduLvl(7)
          }} >
            Doctoral level university degree (Ph.D.)
          </MenuItem>


        </TextField>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q2.married : Q2.notMarried} </span>
          </div>
        </div> */}
        
      </Stack>
      {/* Q3 English proficincy */}
      <Stack>
        <Stack direction={"row"} className="radio">
          <div className="lang-head">English Proficiency</div>
        </Stack>
        <div className="lang">
          {/* Speaking */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="speaking"
            >

              <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                setQ3_1({married : 0 , notMarried : 0 , fsw : 0 })
              }} >
                {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                setQ3_1({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                setQ3_1({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                setQ3_1({married : 8 , notMarried : 9 , fsw : 0 })
              }} >
                {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
              </MenuItem>
              <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                setQ3_1({married : 16 , notMarried : 17 , fsw : 4 })
              }} >
                {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                setQ3_1({married : 22 , notMarried : 23 , fsw : 5 })
              }} >
                {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                setQ3_1({married : 29 , notMarried : 31 , fsw : 6 })
              }} >
                {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                setQ3_1({married : 32 , notMarried : 34 , fsw : 6 })
              }} >
                {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
              </MenuItem>


            </TextField>
            {/* <div className="score-container">
              <div className="score">
                Score : <span> {isMarried ? Q3_1.married : Q3_1.notMarried} </span>
              </div>
            </div> */}
          </div>
          {/* Listening */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="listening"
            >

              <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                setQ3_2({married : 0 , notMarried : 0 , fsw : 0 })
              }} >
                {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                setQ3_2({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                setQ3_2({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                setQ3_2({married : 8 , notMarried : 9 , fsw : 0 })
              }} >
                {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
              </MenuItem>
              <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                setQ3_2({married : 16 , notMarried : 17 , fsw : 4 })
              }} >
                {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                setQ3_2({married : 22 , notMarried : 23 , fsw : 5 })
              }} >
                {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                setQ3_2({married : 29 , notMarried : 31 , fsw : 6 })
              }} >
                {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                setQ3_2({married : 32 , notMarried : 34 , fsw : 6 })
              }} >
                {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
              </MenuItem>  


            </TextField>
            {/* <div className="score-container">
              <div className="score">
                Score : <span> {isMarried ? Q3_2.married : Q3_2.notMarried} </span>
              </div>
            </div> */}
          </div>
          {/* reading */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="reading"
            >

              <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                setQ3_3({married : 0 , notMarried : 0 , fsw : 0 })
              }} >
                {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                setQ3_3({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                setQ3_3({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                setQ3_3({married : 8 , notMarried : 9 , fsw : 0 })
              }} >
                {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
              </MenuItem>
              <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                setQ3_3({married : 16 , notMarried : 17 , fsw : 4 })
              }} >
                {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                setQ3_3({married : 22 , notMarried : 23 , fsw : 5 })
              }} >
                {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                setQ3_3({married : 29 , notMarried : 31 , fsw : 6 })
              }} >
                {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                setQ3_3({married : 32 , notMarried : 34 , fsw : 6 })
              }} >
                {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
              </MenuItem>


            </TextField>
            {/* <div className="score-container">
              <div className="score">
                Score : <span> {isMarried ? Q3_3.married : Q3_3.notMarried} </span>
              </div>
            </div> */}
          </div>
          {/* Writing */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="writing"
            >

              <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                setQ3_4({married : 0 , notMarried : 0 , fsw : 0 })
              }} >
                {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                setQ3_4({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                setQ3_4({married : 6 , notMarried : 6 , fsw : 0 })
              }} >
                {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                setQ3_4({married : 8 , notMarried : 9 , fsw : 0 })
              }} >
                {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
              </MenuItem>
              <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                setQ3_4({married : 16 , notMarried : 17 , fsw : 4 })
              }} >
                {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                setQ3_4({married : 22 , notMarried : 23 , fsw : 5 })
              }} >
                {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                setQ3_4({married : 29 , notMarried : 31 , fsw : 6 })
              }} >
                {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                setQ3_4({married : 32 , notMarried : 34 , fsw : 6 })
              }} >
                {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
              </MenuItem>


            </TextField>
            {/* <div className="score-container">
              <div className="score">
                Score : <span> {isMarried ? Q3_4.married : Q3_4.notMarried} </span>
              </div>
            </div> */}
          </div>
        </div>
      </Stack>
      {/* Q4 French proficincy */}
      <Stack>
        <Stack direction={"row"} className="radio">
          <div className="lang-head">French Proficiency</div>
        </Stack>
        <div className="lang">
          {/* Speaking */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="speaking"
            >

              <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                setQ4_1({married : 0 , notMarried : 0})
              }} >
                {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                setQ4_1({married : 0 , notMarried : 0})
              }} >
                Basic (CLB 4/ TEF 121-150 /TCF 342-374)
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                setQ4_1({married : 1 , notMarried : 1})
              }} >
                Limited(CLB 5 / TEF 151-180 /TCF 375-405)
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                setQ4_1({married : 1 , notMarried : 1})
              }} >
                Fair(CLB 6 / TEF 181-206 /TCF 406-452)
              </MenuItem>
              <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                setQ4_1({married : 3 , notMarried : 3})
              }} >
                Good (CLB 7 / TEF 207-232 /TCF 453-498)
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                setQ4_1({married : 3 , notMarried : 3})
              }} >
                Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                setQ4_1({married : 6 , notMarried : 6})
              }} >
                Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                setQ4_1({married : 6 , notMarried : 6 })
              }} >
                {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
              </MenuItem>

            </TextField>
          </div>
          {/* Listening */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="listening"
            >

              <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                setQ4_2({married : 0 , notMarried : 0})
              }} >
                {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                setQ4_2({married : 0 , notMarried : 0})
              }} >
                Basic (CLB 4/ TEF 121-150 /TCF 342-374)
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                setQ4_2({married : 1 , notMarried : 1})
              }} >
                Limited(CLB 5 / TEF 151-180 /TCF 375-405)
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                setQ4_2({married : 1 , notMarried : 1})
              }} >
                Fair(CLB 6 / TEF 181-206 /TCF 406-452)
              </MenuItem>
              <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                setQ4_2({married : 3 , notMarried : 3})
              }} >
                Good (CLB 7 / TEF 207-232 /TCF 453-498)
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                setQ4_2({married : 3 , notMarried : 3})
              }} >
                Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                setQ4_2({married : 6 , notMarried : 6})
              }} >
                Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                setQ4_2({married : 6 , notMarried : 6})
              }} >
                {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
              </MenuItem>


            </TextField>
          </div>
          {/* reading */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="reading"
            >

              <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                setQ4_3({married : 0 , notMarried : 0})
              }} >
                {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                setQ4_3({married : 0 , notMarried : 0})
              }} >
                Basic (CLB 4/ TEF 121-150 /TCF 342-374)
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                setQ4_3({married : 1 , notMarried : 1})
              }} >
                Limited(CLB 5 / TEF 151-180 /TCF 375-405)
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                setQ4_3({married : 1 , notMarried : 1})
              }} >
                Fair(CLB 6 / TEF 181-206 /TCF 406-452)
              </MenuItem>
              <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                setQ4_3({married : 3 , notMarried : 3})
              }} >
                Good (CLB 7 / TEF 207-232 /TCF 453-498)
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                setQ4_3({married : 3 , notMarried : 3})
              }} >
                Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                setQ4_3({married : 6 , notMarried : 6})
              }} >
                Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                setQ4_3({married : 6 , notMarried : 6})
              }} >
                {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
              </MenuItem>


            </TextField>
          </div>
          {/* Writing */}
          <div>
            <TextField 
              fullWidth
              select
              defaultValue=""
              label="writing"
            >

              <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                setQ4_4({married : 0 , notMarried : 0})
              }} >
                {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
              </MenuItem>
              <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                setQ4_4({married : 0 , notMarried : 0})
              }} >
                Basic (CLB 4/ TEF 121-150 /TCF 342-374)
              </MenuItem>
              <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                setQ4_4({married : 1 , notMarried : 1})
              }} >
                Limited(CLB 5 / TEF 151-180 /TCF 375-405)
              </MenuItem>
              <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                setQ4_4({married : 1 , notMarried : 1})
              }} >
                Fair(CLB 6 / TEF 181-206 /TCF 406-452)
              </MenuItem>
              <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                setQ4_4({married : 3 , notMarried : 3})
              }} >
                Good (CLB 7 / TEF 207-232 /TCF 453-498)
              </MenuItem>
              <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                setQ4_4({married : 3 , notMarried : 3})
              }} >
                Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
              </MenuItem>
              <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                setQ4_4({married : 6 , notMarried : 6})
              }} >
                Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
              </MenuItem>
              <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                setQ4_4({married : 6 , notMarried : 6})
              }} >
                {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
              </MenuItem>


            </TextField>
          </div>
        </div>
      </Stack>
      {/* have you studied in canada */}
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label">
            Have You Studied In Canada?
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setStudied(true)
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setStudied(false)
              reset6789()
              setWereU(false)
              setQ9({married : 0 , notMarried : 0})
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> 0 </span>
          </div>
        </div> */}

      </Stack>
      {/* have you studied children */}
      { Studied && 
      <>
        {/* Q6 Have You Completed */}
        <Stack direction={"row"} className="radio">
          <div>
            <FormLabel className="radio-label">
                Have you completed at least two academic years of full-time study, in a program at least two years long, at a secondary or post-secondary school IN CANADA?
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
                setQ6({married : 15 , notMarried : 15})
              }} />
              <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
                setQ6({married : 0 , notMarried : 0})
              }} />
            </RadioGroup>
          </div>

          {/* <div className="score-container">
            <div className="score">
              Score : <span> {isMarried ? Q6.married : Q6.notMarried} </span>
            </div>
          </div> */}

        </Stack>
        {/* Q7 Have You Obtained */}
        <Stack direction={"row"} className="radio">
          <div>
            <FormLabel className="radio-label">
              Have You Obtained A Degree, Diploma Or Certificate From A School, College Or University IN CANADA?
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
                setQ7({married : 15 , notMarried : 15})
              }} />
              <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
                setQ7({married : 0 , notMarried : 0})
              }} />
            </RadioGroup>
          </div>

          {/* <div className="score-container">
            <div className="score">
              Score : <span> {isMarried ? Q7.married : Q7.notMarried} </span>
            </div>
          </div> */}

        </Stack>
        {/* Q8 were you Physically */}
        <Stack direction={"row"} className="radio">
          <div>
            <FormLabel className="radio-label">
                Were You Physically Present IN CANADA For At Least 8 Months During Your Studies?
            </FormLabel>
            <RadioGroup row>
              <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
                setQ8({married : 15 , notMarried : 15})
                setWereU(true)
              }} />
              <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
                setQ8({married : 0 , notMarried : 0})
                setQ9({married : 0 , notMarried : 0})
                setWereU(false)
              }} />
            </RadioGroup>
          </div>

          {/* <div className="score-container">
            <div className="score">
              Score : <span> {isMarried ? Q8.married : Q8.notMarried} </span>
            </div>
          </div> */}

        </Stack>

        { WereU &&
          // Q9 Were You Enrolled 
          <Stack direction={"row"} className="radio">
            <div>
              <FormLabel className="radio-label">
                  Were You Enrolled In Full-Time Study Or Training IN CANADA For At Least 8 Months?
              </FormLabel>
              <RadioGroup row>
                <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
                  setQ9({married : 15 , notMarried : 15})
                }} />
                <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
                  setQ9({married : 0 , notMarried : 0})
                }} />
              </RadioGroup>
            </div>

            {/* <div className="score-container">
              <div className="score">
                Score : <span> {isMarried ? Q9.married : Q9.notMarried} </span>
              </div>
            </div> */}

          </Stack>
        }
        
      </>
      }
      {/* - are you married */}
      <Stack direction={"row"} className="radio">

        <div>
          <FormLabel className="radio-label">
              Are you traveling with a spouse ?
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setIsMarried(true)
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setIsMarried(false)
              setQ10({married : 0 , notMarried : 0})
              setQ11_1({married : 0 , notMarried : 0})
              setQ11_2({married : 0 , notMarried : 0})
              setQ11_3({married : 0 , notMarried : 0})
              setQ11_4({married : 0 , notMarried : 0})
              setQ12({married : 0 , notMarried : 0})
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> 0 </span>
          </div>
        </div> */}

      </Stack>
      { isMarried &&
          <>
            {/*Q2 What is your wife highest level of completed education? */}
            <Stack>
              
              <TextField 
                  fullWidth
                  select
                  defaultValue=""
                  label="What is your spouse highest level of completed education?"
                >

                  <MenuItem value="Less than secondary school (high school)" onClick={() => {
                    setQ10({married : 0 , notMarried : 0})
                  }} >
                    Less than secondary school (high school)
                  </MenuItem>

                  <MenuItem value="Secondary school (high school graduation)" onClick={() => {
                    setQ10({married : 2 , notMarried : 2})
                  }} >
                    Secondary school (high school graduation)
                  </MenuItem>

                  <MenuItem value="One-year program at a university, college, trade or technical school, or other institute" onClick={() => {
                    setQ10({married : 6 , notMarried : 6})
                  }} >
                    One-year program at a university, college, trade or technical school, or other institute
                  </MenuItem>

                  <MenuItem value="Two-year program at a university, college, trade or technical in school, or other institute" onClick={() => {
                    setQ10({married : 7 , notMarried : 7})
                  }} >
                    Two-year program at a university, college, trade or technical in school, or other institute
                  </MenuItem>

                  <MenuItem value="Bachelor's degree OR a three or more year program at a university, college, trade or technical school, or another institute" onClick={() => {
                    setQ10({married : 8 , notMarried : 8})
                  }} >
                    Bachelor's degree OR a three or more year program at a university, college, trade or technical school, or another institute
                  </MenuItem>

                  <MenuItem value="Two or more certificates, diplomas, or degrees. One must be for a program of three or more years" onClick={() => {
                    setQ10({married : 9 , notMarried : 9})
                  }} >
                    Two or more certificates, diplomas, or degrees. One must be for a program of three or more years
                  </MenuItem>

                  <MenuItem value="Master's degree" onClick={() => {
                    setQ10({married : 10 , notMarried : 10})
                  }} >
                    Master's degree
                  </MenuItem>

                  <MenuItem value="Doctoral level university degree (PhD) " onClick={() => {
                    setQ10({married : 10 , notMarried : 10})
                  }} >
                    Doctoral level university degree (PhD) 
                  </MenuItem>


              </TextField>

              {/* <div className="score-container">
                <div className="score">
                  Score : <span> {isMarried ? Q10.married : Q10.notMarried} </span>
                </div>
              </div> */}
              
            </Stack>
            {/* Studied at least 2 years in anada */}
            <Stack direction={"row"} className="radio">
              <div>
                <FormLabel className="radio-label">
                    Have your spouse completed at least two academic years of full-time study, in a program at least two years long, at a secondary or post-secondary school IN CANADA?
                </FormLabel>
                <RadioGroup row>
                  <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
                    setQ14(true)
                  }} />
                  <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
                    setQ14(false)
                  }} />
                </RadioGroup>
              </div>
            </Stack>
            {/* Q12 Wife Work EXP  */}
            <Stack>
              
              <TextField 
                fullWidth
                select
                defaultValue=""
                label="Spouse's Canadian work experience"
              >

                <MenuItem value="None or less than a year" onClick={() => {
                  setQ12({married : 0 , notMarried : 0})
                }} >
                  None or less than a year
                </MenuItem>

                <MenuItem value="1 year" onClick={() => {
                  setQ12({married : 5 , notMarried : 5})
                }} >
                  1 year
                </MenuItem>

                <MenuItem value="2 years" onClick={() => {
                  setQ12({married : 7 , notMarried : 7})
                }} >
                  2 years
                </MenuItem>

                <MenuItem value="3 years" onClick={() => {
                  setQ12({married : 8 , notMarried : 8})
                }} >
                  3 years
                </MenuItem>

                <MenuItem value="4 years" onClick={() => {
                  setQ12({married : 9 , notMarried : 9})
                }} >
                  4 years
                </MenuItem>

                <MenuItem value="5 years or more" onClick={() => {
                  setQ12({married : 10 , notMarried : 10})
                }} >
                  5 years or more
                </MenuItem>


              </TextField>

              {/* <div className="score-container">
                <div className="score">
                  Score : <span> {isMarried ? Q12.married : Q12.notMarried} </span>
                </div>
              </div> */}
              
            </Stack>
            {/* Q11 Wife English proficincy */}
            <Stack>
              <Stack direction={"row"} className="radio">
                <div className="lang-head">Spouse English Proficiency</div>
              </Stack>
              <div className="lang">
                {/* Speaking */}
                <div>
                  <TextField 
                    fullWidth
                    select
                    defaultValue=""
                    label="speaking"
                  >

                    <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                      setQ11_1({married : 0 , notMarried : 0 , fsw : 0})
                    }} >
                      {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
                    </MenuItem>
                    <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                      setQ11_1({married : 0 , notMarried : 0 , fsw : 5})
                    }} >
                      {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
                    </MenuItem>
                    <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                      setQ11_1({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
                    </MenuItem>
                    <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                      setQ11_1({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
                    </MenuItem>
                    <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                      setQ11_1({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
                    </MenuItem>
                    <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                      setQ11_1({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
                    </MenuItem>
                    <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                      setQ11_1({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
                    </MenuItem>
                    <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                      setQ11_1({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
                    </MenuItem>


                  </TextField>
                </div>
                {/* Listening */}
                <div>
                  <TextField 
                    fullWidth
                    select
                    defaultValue=""
                    label="listening"
                  >

                    <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                      setQ11_2({married : 0 , notMarried : 0 , fsw : 0})
                    }} >
                      {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
                    </MenuItem>
                    <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                      setQ11_2({married : 0 , notMarried : 0 , fsw : 5})
                    }} >
                      {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
                    </MenuItem>
                    <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                      setQ11_2({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
                    </MenuItem>
                    <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                      setQ11_2({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
                    </MenuItem>
                    <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                      setQ11_2({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
                    </MenuItem>
                    <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                      setQ11_2({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
                    </MenuItem>
                    <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                      setQ11_2({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
                    </MenuItem>
                    <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                      setQ11_2({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
                    </MenuItem> 


                  </TextField>
                </div>
                {/* reading */}
                <div>
                  <TextField 
                    fullWidth
                    select
                    defaultValue=""
                    label="reading"
                  >

                    <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                      setQ11_3({married : 0 , notMarried : 0 , fsw : 0})
                    }} >
                      {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
                    </MenuItem>
                    <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                      setQ11_3({married : 0 , notMarried : 0 , fsw : 5})
                    }} >
                      {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
                    </MenuItem>
                    <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                      setQ11_3({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
                    </MenuItem>
                    <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                      setQ11_3({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
                    </MenuItem>
                    <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                      setQ11_3({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
                    </MenuItem>
                    <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                      setQ11_3({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
                    </MenuItem>
                    <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                      setQ11_3({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
                    </MenuItem>
                    <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                      setQ11_3({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
                    </MenuItem> 


                  </TextField>
                </div>
                {/* Writing */}
                <div>
                  <TextField 
                    fullWidth
                    select
                    defaultValue=""
                    label="writing"
                  >

                    <MenuItem value="None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)" onClick={() => {
                      setQ11_4({married : 0 , notMarried : 0 , fsw : 0})
                    }} >
                      {"None(CLB 3 or lower/ ELITS < 4/CELPIP < 4)"}
                    </MenuItem>
                    <MenuItem value="Basic (CLB 4/ ELITS 4/CELPIP 4)" onClick={() => {
                      setQ11_4({married : 0 , notMarried : 0 , fsw : 5})
                    }} >
                      {"Basic (CLB 4/ ELITS 4/CELPIP 4)"}
                    </MenuItem>
                    <MenuItem value="Limited(CLB 5 / IELTS 5 / CELPIP 5)" onClick={() => {
                      setQ11_4({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Limited(CLB 5 / IELTS 5 / CELPIP 5)"}
                    </MenuItem>
                    <MenuItem value="Fair(CLB 6 / IELTS 5.5 / CELPIP 6)" onClick={() => {
                      setQ11_4({married : 1 , notMarried : 1 , fsw : 5})
                    }} >
                      {"Fair(CLB 6 / IELTS 5.5 / CELPIP 6)"}
                    </MenuItem>
                    <MenuItem value="Good (CLB 7 / IELTS 6 / CELPIP 7)" onClick={() => {
                      setQ11_4({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Good (CLB 7 / IELTS 6 / CELPIP 7)"}
                    </MenuItem>
                    <MenuItem value="Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)" onClick={() => {
                      setQ11_4({married : 3 , notMarried : 3 , fsw : 5})
                    }} >
                      {"Very Good (CLB 8 / IELTS 6.5 / CELPIP 8)"}
                    </MenuItem>
                    <MenuItem value="Advanced (CLB 9 / IELTS 7 / CELPIP 9)" onClick={() => {
                      setQ11_4({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Advanced (CLB 9 / IELTS 7 / CELPIP 9)"}
                    </MenuItem>
                    <MenuItem value="Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..." onClick={() => {
                      setQ11_4({married : 5 , notMarried : 5 , fsw : 5})
                    }} >
                      {"Fluent/Native (CLB 10 or higher / IELTS >7/CELPIP>..."}
                    </MenuItem> 


                  </TextField>
                </div>
              </div>
            </Stack>
            {/* Q13 French proficincy */}
            <Stack>
              <Stack direction={"row"} className="radio">
                <div className="lang-head">Spouse French Proficiency</div>
                {/* <div className="score-container">
                  <div className="score">
                    Score : <span> {FrechSpouse} </span>
                  </div>
                </div> */}
              </Stack>
                <div className="lang">
                  {/* Speaking */}
                  <div>
                    <TextField 
                      fullWidth
                      select
                      defaultValue=""
                      label="speaking"
                    >

                      <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                        setQ13_1({married : 0 , notMarried : 0 , fsw : 0})
                      }} >
                        {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
                      </MenuItem>
                      <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                        setQ13_1({married : 0 , notMarried : 0 , fsw : 5})
                      }} >
                        Basic (CLB 4/ TEF 121-150 /TCF 342-374)
                      </MenuItem>
                      <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                        setQ13_1({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Limited(CLB 5 / TEF 151-180 /TCF 375-405)
                      </MenuItem>
                      <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                        setQ13_1({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Fair(CLB 6 / TEF 181-206 /TCF 406-452)
                      </MenuItem>
                      <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                        setQ13_1({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Good (CLB 7 / TEF 207-232 /TCF 453-498)
                      </MenuItem>
                      <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                        setQ13_1({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
                      </MenuItem>
                      <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                        setQ13_1({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
                      </MenuItem>
                      <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                        setQ13_1({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
                      </MenuItem>

                    </TextField>
                    {/* <div className="score-container">
                      <div className="score">
                        Score : <span> {isMarried ? Q13_1.married : Q13_1.notMarried} </ , fsw : 5span>
                      </div>
                    </div> */}
                  </div>
                  {/* Listening */}
                  <div>
                    <TextField 
                      fullWidth
                      select
                      defaultValue=""
                      label="listening"
                    >

                      <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                        setQ13_2({married : 0 , notMarried : 0 , fsw : 0})
                      }} >
                        {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
                      </MenuItem>
                      <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                        setQ13_2({married : 0 , notMarried : 0 , fsw : 5})
                      }} >
                        Basic (CLB 4/ TEF 121-150 /TCF 342-374)
                      </MenuItem>
                      <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                        setQ13_2({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Limited(CLB 5 / TEF 151-180 /TCF 375-405)
                      </MenuItem>
                      <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                        setQ13_2({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Fair(CLB 6 / TEF 181-206 /TCF 406-452)
                      </MenuItem>
                      <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                        setQ13_2({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Good (CLB 7 / TEF 207-232 /TCF 453-498)
                      </MenuItem>
                      <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                        setQ13_2({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
                      </MenuItem>
                      <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                        setQ13_2({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
                      </MenuItem>
                      <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                        setQ13_2({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
                      </MenuItem>


                    </TextField>
                    {/* <div className="score-container">
                      <div className="score">
                        Score : <span> {isMarried ? Q13_2.married : Q13_2.notMarried} </ , fsw : 5span>
                      </div>
                    </div> */}
                  </div>
                  {/* reading */}
                  <div>
                    <TextField 
                      fullWidth
                      select
                      defaultValue=""
                      label="reading"
                    >

                      <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                        setQ13_3({married : 0 , notMarried : 0 , fsw : 0})
                      }} >
                        {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
                      </MenuItem>
                      <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                        setQ13_3({married : 0 , notMarried : 0 , fsw : 5})
                      }} >
                        Basic (CLB 4/ TEF 121-150 /TCF 342-374)
                      </MenuItem>
                      <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                        setQ13_3({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Limited(CLB 5 / TEF 151-180 /TCF 375-405)
                      </MenuItem>
                      <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                        setQ13_3({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Fair(CLB 6 / TEF 181-206 /TCF 406-452)
                      </MenuItem>
                      <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                        setQ13_3({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Good (CLB 7 / TEF 207-232 /TCF 453-498)
                      </MenuItem>
                      <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                        setQ13_3({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
                      </MenuItem>
                      <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                        setQ13_3({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
                      </MenuItem>
                      <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                        setQ13_3({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
                      </MenuItem>


                    </TextField>
                    {/* <div className="score-container">
                      <div className="score">
                        Score : <span> {isMarried ? Q13_3.married : Q13_3.notMarried} </ , fsw : 5span>
                      </div>
                    </div> */}
                  </div>
                  {/* Writing */}
                  <div>
                    <TextField 
                      fullWidth
                      select
                      defaultValue=""
                      label="writing"
                    >

                      <MenuItem value="None(CLB 3 or lower/ TEF < 121 /TCF <342)" onClick={() => {
                        setQ13_4({married : 0 , notMarried : 0 , fsw : 0})
                      }} >
                        {"None(CLB 3 or lower/ TEF < 121 /TCF <342)"}
                      </MenuItem>
                      <MenuItem value="Basic (CLB 4/ TEF 121-150 /TCF 342-374)" onClick={() => {
                        setQ13_4({married : 0 , notMarried : 0 , fsw : 5})
                      }} >
                        Basic (CLB 4/ TEF 121-150 /TCF 342-374)
                      </MenuItem>
                      <MenuItem value="Limited(CLB 5 / TEF 151-180 /TCF 375-405)" onClick={() => {
                        setQ13_4({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Limited(CLB 5 / TEF 151-180 /TCF 375-405)
                      </MenuItem>
                      <MenuItem value="Fair(CLB 6 / TEF 181-206 /TCF 406-452)" onClick={() => {
                        setQ13_4({married : 1 , notMarried : 1 , fsw : 5})
                      }} >
                        Fair(CLB 6 / TEF 181-206 /TCF 406-452)
                      </MenuItem>
                      <MenuItem value="Good (CLB 7 / TEF 207-232 /TCF 453-498)" onClick={() => {
                        setQ13_4({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Good (CLB 7 / TEF 207-232 /TCF 453-498)
                      </MenuItem>
                      <MenuItem value="Very Good (CLB 8 / TEF 233-247 /TCF 499-523)" onClick={() => {
                        setQ13_4({married : 3 , notMarried : 3 , fsw : 5})
                      }} >
                        Very Good (CLB 8 / TEF 233-247 /TCF 499-523)
                      </MenuItem>
                      <MenuItem value="Advanced (CLB 9 / TEF 248-262 /TCF 524-548)" onClick={() => {
                        setQ13_4({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        Advanced (CLB 9 / TEF 248-262 /TCF 524-548)
                      </MenuItem>
                      <MenuItem value="Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)" onClick={() => {
                        setQ13_4({married : 5 , notMarried : 5 , fsw : 5})
                      }} >
                        {"Fluent/Native (CLB 10 or higher /TEF >262 /TCF >548)"}
                      </MenuItem>


                    </TextField>
                    {/* <div className="score-container">
                      <div className="score">
                        Score : <span> {isMarried ? Q13_4.married : Q13_4.notMarried} </span>
                      </div>
                    </div> */}
                  </div>
                </div>
            </Stack>
          </>
      }
      
    </Stack>
  )
}

const Group2 = ({ isMarried , setWorkOut , WorkOut , WorkIn , setWorkIn , setScore , higher7 , setFswScore , higher9 , setAdd10 , workedAtLeast , setWorkedAtLeast }) => { 


  const [jobOffer, setJobOffer] = useState(false)
  
  
  // Canadian Work Exp
  const [Q1, setQ1] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  // changing
  const [Q1_1, setQ1_1] = useState({
    married : 0,
    notMarried: 0,
  })
  // at least two 
  const [Q2, setQ2] = useState({
    married : 0,
    notMarried: 0,
  })
  // job offer
  const [Q3, setQ3] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })
  // nomination
  const [Q4, setQ4] = useState({
    married : 0,
    notMarried: 0,
  })
  // Q5 bro or sis
  const [Q5, setQ5] = useState({
    married : 0,
    notMarried: 0,
  })
  // Q6 trade
  const [Q6, setQ6] = useState({
    married : 0,
    notMarried: 0,
  })
  // Q7 trade
  const [Q7, setQ7] = useState({
    married : 0,
    notMarried: 0,
  })
  // Q8 at least 1 year in skilled occupation
  // const [Q8, setQ8] = useState({
  //   married : 0,
  //   notMarried: 0,
  // })
  // Q9 Teers 2
  // const [Q9, setQ9] = useState({
  //   married : 0,
  //   notMarried: 0,
  // })
  // Outside canada work exp


  const [Q9, setQ9] = useState({
    married : 0,
    notMarried: 0,
    fsw : 0
  })

  useEffect(() => {
    console.log(higher9 , higher7)
    // Higher 9 All Cases Are Working Precisly
    if (higher9) {

      if (WorkIn === 0 || WorkOut === 0) {
        setQ9({married : 0 , notMarried : 0 , fsw : Q9.fsw})
      }
      if (WorkIn === 1) {
        if (WorkOut === 1 || WorkOut === 2) setQ9({married : 13 , notMarried : 13 , fsw : Q9.fsw});
        if (WorkOut >= 3) setQ9({married : 0 , notMarried : 0 , fsw : Q9.fsw});
      }
      if (WorkIn >= 2) {
        if (WorkOut === 1 || WorkOut === 2) setQ9({married : 25 , notMarried : 25 , fsw : Q9.fsw});
        if (WorkOut >= 3) setQ9({married : 0 , notMarried : 0 , fsw : Q9.fsw});
      }
      
    } else {
      if (higher7) {
        if (WorkIn > 0) {
          
          if (WorkIn === 0 || WorkOut === 0) {
            setQ9({married : 0 , notMarried : 0 , fsw : Q9.fsw})
          }
          if (WorkIn === 1) {
            if (WorkOut === 1 || WorkOut === 2) setQ9({married : 13 , notMarried : 13 , fsw : Q9.fsw});
            if (WorkOut >= 3) setQ9({married : 25 , notMarried : 25 , fsw : Q9.fsw});
          }
          if (WorkIn >= 2) {
            if (WorkOut === 1 || WorkOut === 2) setQ9({married : 25 , notMarried : 25 , fsw : Q9.fsw});
            if (WorkOut >= 3) setQ9({married : 25 , notMarried : 25 , fsw : Q9.fsw});
          }
          
        } else setQ9({married : 0 , notMarried : 0 , fsw : Q9.fsw})
      } else {
        if (WorkIn === 0 || WorkOut === 0) {
          setQ9({married : 0 , notMarried : 0 , fsw : Q9.fsw})
        }
        if (WorkIn === 1) {
          if (WorkOut === 1 || WorkOut === 2) setQ9({married : 13 , notMarried : 13 , fsw : Q9.fsw});
          if (WorkOut >= 3) setQ9({married : 25 , notMarried : 25 , fsw : Q9.fsw});
        }
        if (WorkIn >= 2) {
          if (WorkOut === 1 || WorkOut === 2) setQ9({married : 25 , notMarried : 25 , fsw : Q9.fsw});
          if (WorkOut >= 3) setQ9({married : 50 , notMarried : 50 , fsw : Q9.fsw});
        }
      }
    }
  } , [higher9 , WorkOut , higher7 , WorkIn , Q9.fsw])

  // Comulative
  useEffect(() => {

    isMarried ? 
    setScore( Q1.married + Q5.married + Q2.married + Q3.married + Q4.married + Q6.married + Q7.married + Q9.married ) 
    : 
    setScore( Q1.notMarried + Q5.notMarried + Q2.notMarried + Q3.notMarried + Q4.notMarried + Q6.notMarried + Q7.notMarried + Q9.notMarried ) 

  } , [isMarried , Q1 , Q2 , Q3 , Q4 , Q5 , Q6 , Q7 , Q9 , setScore])
  
  // FSW For In and Out
  const [workCum, setWorkCum] = useState(0)

  // FSW
  useEffect(() => {
    setFswScore( workCum + Q3.fsw + Q9.fsw)
  } , [workCum , Q3 , Q9 , setFswScore])

  // Add 10
  useEffect(() => {
    // Canadian work EXP
    let wl = 0;
    WorkIn > 0 ? wl = 10 : wl = 0;
    // Job offer
    let wl2 = 0;
    jobOffer ? wl2 = 5 : wl2 = 0;
    // Relatives
    let wl3 = 0;
    Q7.married === 15 ? wl3 = 5 : wl3 = 0;

    setAdd10(wl + wl2 + wl3)
  } , [WorkIn , setAdd10 , jobOffer , Q7])

  
  function handleWorks (inside , outside) {

    if (inside + outside === 0) setWorkCum(0); 
    else if (inside + outside === 1) setWorkCum(9); 
    else if (inside + outside === 2 || inside + outside === 3 ) setWorkCum(11); 
    else if (inside + outside === 4 || inside + outside === 5 ) setWorkCum(13); 
    else if (inside + outside >= 6 ) setWorkCum(15);
    else setWorkCum(0);
    console.log(inside , outside , "function")
  }

  useEffect(() => {
    handleWorks(WorkIn , WorkOut)
  } , [WorkIn , WorkOut])
  
  return (
    <Stack className="g1" spacing={12}>

      {/*Q1 - canadian work experience */}
      <Stack>
        
        <TextField 
          fullWidth
          select
          defaultValue=""
          label="In the last 10 years, how many years of skilled work experience in Canada do you have?"
        >

          <MenuItem value="None or less than a year" onClick={() => {
            setQ1({married : 0 , notMarried : 0 , fsw : 0})
            setQ1_1({married : 0 , notMarried : 0 , fsw : 0})
            setWorkIn(0)
          }} >
            None or less than a year
          </MenuItem>

          <MenuItem value="1 year" onClick={() => {
            setQ1({married : 35 , notMarried : 40 , fsw : 9})
            setQ1_1({married : 35 , notMarried : 40 , fsw : 9})
            setWorkIn(1)
          }} >
            1 year
          </MenuItem>

          <MenuItem value="2 years" onClick={() => {
            setQ1({married : 46 , notMarried : 53 , fsw : 11})
            setQ1_1({married : 46 , notMarried : 53 , fsw : 11})
            setWorkIn(2)
          }} >
            2 years
          </MenuItem>

          <MenuItem value="3 years" onClick={() => {
            setQ1({married : 56 , notMarried : 64 , fsw : 11})
            setQ1_1({married : 56 , notMarried : 64 , fsw : 11})
            setWorkIn(3)
          }} >
            3 years
          </MenuItem>

          <MenuItem value="4 years" onClick={() => {
            setQ1({married : 63 , notMarried : 72 , fsw : 13})
            setQ1_1({married : 63 , notMarried : 72 , fsw : 13})
            setWorkIn(4)
          }} >
            4 years
          </MenuItem>

          <MenuItem value="5 years" onClick={() => {
            setQ1({married : 70 , notMarried : 80 , fsw : 13})
            setQ1_1({married : 70 , notMarried : 80 , fsw : 13})
            setWorkIn(5)
          }} >
            5 years
          </MenuItem>

          <MenuItem value="6 years or more" onClick={() => {
            setQ1({married : 70 , notMarried : 80 , fsw : 15})
            setQ1_1({married : 70 , notMarried : 80 , fsw : 15})
            setWorkIn(6)
          }} >
            6 years or more
          </MenuItem>

        </TextField>
        
        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q1.married : Q1.notMarried} </span>
          </div>
        </div> */}
        
      </Stack>
      {/* Have you worked at least 1 year in Canada within the last 3 years in a skilled occupation? */}
      { WorkIn > 0 && 
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label">
            Have you worked at least 1 year in Canada within the last 3 years in a skilled occupation? 
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setWorkedAtLeast(true)
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setWorkedAtLeast(false)
            }} />
          </RadioGroup>
        </div>
      </Stack>}
      {/* Teers 1 */}
      {workedAtLeast && 
      <Stack>
        
        <TextField 
          fullWidth
          select
          defaultValue=""
          label="Which occupation did you gain this experience in? *"
        >

          <MenuItem value="TEER 0">
            TEER 0
          </MenuItem>

          <MenuItem value="TEAR 1, 2 or 3">
            TEAR 1, 2 or 3
          </MenuItem>

          <MenuItem value="TEAR 4 or 5">
            TEAR 4 or 5
          </MenuItem>


        </TextField>
        
      </Stack>}
      {/*Q2 Outside Canada work experiences */}
      <Stack>
        
        <TextField 
          fullWidth
          select
          defaultValue=""
          label="In the last 10 years, how many years of skilled work experience outside Canada do you have?"
        >

          <MenuItem value="None or less than a year" onClick={() => {
            // setQ9({ fsw : 0 , fsw2 : 0 })
            setWorkOut(0)
          }} >
            None or less than a year
          </MenuItem>

          <MenuItem value="1 year" onClick={() => {
            // setQ9({ fsw : 9 , fsw2 : 0 })
            setWorkOut(1)
          }} >
            1 year
          </MenuItem>

          <MenuItem value="2 years" onClick={() => {
            // setQ9({ fsw : 11 , fsw2 : 0 })
            setWorkOut(2)
          }} >
            2 years
          </MenuItem>

          <MenuItem value="3 years" onClick={() => {
            // setQ9({ fsw : 11 , fsw2 : 0 })
            setWorkOut(3)
          }} >
            3 years
          </MenuItem>

          <MenuItem value="4 years" onClick={() => {
            // setQ9({ fsw : 13 , fsw2 : 0 })
            setWorkOut(4)
          }} >
            4 years
          </MenuItem>

          <MenuItem value="5 years" onClick={() => {
            // setQ9({ fsw : 13 , fsw2 : 0 })
            setWorkOut(5)
          }} >
            5 years
          </MenuItem>

          <MenuItem value="6 years" onClick={() => {
            // setQ9({married : 0 , notMarried : 0 , fsw : 15})
            setWorkOut(6)
          }} >
            6 years or more
          </MenuItem>


        </TextField>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> 0 </span>
          </div>
        </div> */}
        
      </Stack>
      {/* Do you have a trade certificate of qualification from a Canadian province, territory, or federal body? */}
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label">
            Do you have a trade certificate of qualification from a Canadian province, territory, or federal body?
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              higher7 ? setQ6({married : 50 , notMarried : 50})  : setQ6({married : 0 , notMarried : 0})
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setQ6({married : 0 , notMarried : 0})
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> 0 </span>
          </div>
        </div> */}

      </Stack>
      {/* Q2 Do you have at least two years of full-time experience, or equivalent part-time experience, as a tradesperson within the last five years? */}
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label">
          Do you have at least two years of full-time experience, or equivalent part-time experience, as a tradesperson within the last five years?
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setQ2({married : 0 , notMarried : 0})
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setQ2({married : 0 , notMarried : 0})
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q5.married : Q5.notMarried} </span>
          </div>
        </div> */}

      </Stack>
      {/* Do you have a job offer in Canada in a skilled occupation? */}
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label">
          Do you have a job offer in Canada in a skilled occupation?
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setJobOffer(true)
              setQ3({
                married : 0,
                notMarried: 0,
                fsw : 10
              })
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setJobOffer(false)
              setQ5({
                married : 0,
                notMarried: 0,
              })
              setQ3({
                married : 0,
                notMarried: 0,
                fsw : 0
              })
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q5.married : Q5.notMarried} </span>
          </div>
        </div> */}

      </Stack>
      {/*Q5 -TEERS 2 */}
      {jobOffer && 
      <Stack>
        
        <TextField 
          fullWidth
          select
          defaultValue=""
          label="Which NOC TEER is the job offer"
        >

          <MenuItem value="TEER 0" onClick={() => {
            setQ5({married : 200 , notMarried : 200})
            console.log("samo 3likkkkkkooooooooooooo")
          }}>
            TEER 0
          </MenuItem>

          <MenuItem value="TEAR 1, 2 or 3" onClick={() => {
            setQ5({married : 50 , notMarried : 50})
            console.log("samo 3likkkkkkooooooooooooo")
          }}>
            TEAR 1, 2 or 3
          </MenuItem>

          <MenuItem value="TEAR 4 or 5" onClick={() => {
            setQ5({married : 0 , notMarried : 0})
            console.log("samo 3likkkkkkooooooooooooo")
          }}>
            TEAR 4 or 5
          </MenuItem>


        </TextField>
        
        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q1.married : Q1.notMarried} </span>
          </div>
        </div> */}
        
      </Stack>}
      {/* Q3 Do you have a brother or sister living in Canada as a Canadian citizen or permanent resident? */}
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label">
              Do you have a brother or sister living in Canada as a Canadian citizen or permanent resident?
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setQ3({married : 15 , notMarried : 15 , fsw : Q3.fsw})
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setQ3({married : 0 , notMarried : 0 , fsw : Q3.fsw})
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q5.married : Q5.notMarried} </span>
          </div>
        </div> */}

      </Stack>
      {/* Q3 Do you have any of the following other types of relatives living in Canada as Canadian citizens or permanent residents? */}
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label ul">
              <span>Do you have any of the following other types of relatives living in Canada as Canadian citizens or permanent residents?</span>
              <ul>
                <li>Parent</li>
                <li>Grandparent</li>
                <li>Child</li>
                <li>Grandchild</li>
                <li>Aunt or uncle (by blood or marriage)</li>
                <li>Niece or nephew</li>
              </ul>
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setQ7({married : 15 , notMarried : 15})
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setQ7({married : 0 , notMarried : 0})
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q5.married : Q5.notMarried} </span>
          </div>
        </div> */}

      </Stack>
      {/* Q4 Have you received a provincial nomination through an Express Entry-aligned Provincial Nominee Program (PNP) stream?  */}
      <Stack direction={"row"} className="radio">
        <div>
          <FormLabel className="radio-label">
            Have you received a provincial nomination through an Express Entry-aligned Provincial Nominee Program (PNP) stream?
          </FormLabel>
          <RadioGroup row>
            <FormControlLabel value="yes" control={<Radio />} label="yes" onClick={() => {
              setQ4({married : 600 , notMarried : 600})
            }} />
            <FormControlLabel value="no" control={<Radio />} label="no" onClick={() => {
              setQ4({married : 0 , notMarried : 0})
            }} />
          </RadioGroup>
        </div>

        {/* <div className="score-container">
          <div className="score">
            Score : <span> {isMarried ? Q5.married : Q5.notMarried} </span>
          </div>
        </div> */}

      </Stack>

    </Stack>
  )
}

const CalcGroupHead = ({title}) => {
  return (
    <Stack>

      <h2>{title}</h2>

      <hr />

    </Stack>
  )
}

const Submit = ({ setActive, setActiveCancel, setActiveClear }) => {
  return (
      <div>
          <Stack
              direction={{ sx: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              mb={35}
              spacing={{ xs: 12, sm: 0 }}
          >
              <Stack direction="row" alignItems="center" spacing={6}>
                  <DeleteOutlineIcon sx={{ fill: "#5F5F5F" }} />
                  <Button
                      onClick={() => setActiveClear(true)}
                      sx={{ p: 0, color: "#5F5F5F" }}
                  >
                      Clear all
                  </Button>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={12}>
                  <Button
                      onClick={() => setActiveCancel(true)}
                      variant="outlined"
                      sx={{
                          py: 8,
                          px: 20,
                          borderRadius: "8px",
                      }}
                  >
                      Cancel
                  </Button>

                  <Button
                      variant="contained"
                      color="primary"
                      sx={{
                          py: 8,
                          px: 20,
                          borderRadius: "8px",
                      }}
                      onClick={() => {
                          setActive(true);
                      }}
                  >
                      calculate score
                  </Button>
              </Stack>

              <Stack>

              </Stack>
          </Stack>
      </div>
  );
};

export default Calculator
