import { Alert, Snackbar } from '@mui/material';
import React from 'react'

const MSAlert = ({open , setOpen , message , type}) => {

  const handleClose = () => {
    setOpen(false);
  }
  
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} sx={{ zIndex : 10000000 }} >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default MSAlert
