import Bread from "../../components/Bread/Bread";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Button, MenuItem, Stack, TextField } from "@mui/material";
import "./CheckQuestions.scss"

const CheckQuestions = () => {

  const {id} = useParams()

  const links = [
    {
        name: "Dashboard",
        to: "/",
    },
    {
        name: "plans",
        to: "/plans",
    },
    {
        name: "Check Eligibility",
        to: "/check-eligibility",
    },
    {
      name: "Questions",
      to: "/check-eligibility/" + id,
    },
  ];

  // Get Case Questions
  const [questions, setQuestions] = useState(null)
  
  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/questioner";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => {
    axios.post(url , {case_id : id}).then((response) => {
      console.log(response.data.data.questioner)
      setQuestions(response.data.data.questioner)
    });
  } , [])

  // Handle Answer
  let ids = [];
  

  // navigae
  const navigate = useNavigate()
  
  return (
    <>
      <Navbar />
      <div style={{ display: "flex" }}>
          <Sidebar />
          <div className="CheckQ main-section" data-aos="fade-down">
              <Bread links={links} />

              <Stack
                  bgcolor="white"
                  p={8}
                  pb={12}
                  mb={12}
                  borderRadius="8px"
              >
                  <h2>Questions</h2>

                  <hr style={{marginBottom : "16px"}} />

                  <div className="box">
                    {questions?.map((question , i) => {
                      if((question.case_id === null || question.case_id === id) && question.answers.length > 0) {
                        return (
                          <SelectInput key={question.id} question={question} ids={ids} i={i}  />
                        )
                      }
                    })}
                  </div>

              </Stack>

              <Stack alignItems={"flex-end"} >
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    sx={{
                      p : "16px 40px"
                    }} 
                    onClick={() => {
                      localStorage.setItem("gic_ids" , JSON.stringify(ids))
                      navigate("filtered-plans")
                    }}
                  >
                    Find Plan
                  </Button>
              </Stack>

              <CopyWriting />
          </div>
      </div>
    </>
  )
}


const SelectInput = ({ question, ids , i }) => {


  
  return (
      <>

          {/* Level 1 */}
          <div className="input">
              <TextField
                  fullWidth
                  select
                  defaultValue={""}
                  label={question.title}
                  color="primary"
                  key={question.id}
                  onChange={(e) => {
                    ids[i] = e.target.value;
                    console.log(ids)
                  } }
              >
                  {question.answers.map((answer) => {
                      return (
                          <MenuItem key={answer.id} value={answer.id}>
                              {answer.text}
                          </MenuItem>
                      );
                  })}
              </TextField>
          </div>

      </>
  );
};

export default CheckQuestions

