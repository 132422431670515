  

function useIsAdmin() {
  if(localStorage.getItem("gic_role") === "sadmin") {
    return 0;
  } else if (localStorage.getItem("gic_role") === "admin") {
    return 1;
  } else {
    return 2;
  }
}

export default useIsAdmin
