import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Bread from "../../components/Bread/Bread";
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import SearchIcon from '@mui/icons-material/Search';
import "./Plans.scss"
import { Accordion, AccordionDetails, AccordionSummary, Button, MenuItem, Slider, Stack, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import FilterListIcon from '@mui/icons-material/FilterList';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from "axios";
import PopUp from "../../components/PopUp/PopUp";
import MSAlert from "../../components/MSAlert";
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { DEL_QUESTION, GET_QUESTIONS } from "../../store/questionSlice"


const Plans = () => {

  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()
  const plans = useSelector(state => state.questions.questions)

  const links = [
    {
      name : "Dashboard",
      to : "/"
    },
    {
      name : "plans",
      to : "#"
    }
  ]
  
  // API 
  const [caseID, setCaseID] = useState(null)
  const [pricing, setPricing] = useState(null)
  const [lang, setLang] = useState(null)
  const [jobOffer, setJobOffer] = useState(null)

  
  const token = localStorage.getItem("gic_token");
  const url = `https://bksys.getincanada.ca/api/admin/programs?${
    caseID ? `case_id=${caseID}` : ""
  }${
    pricing ? `&pricing=${pricing}` : ""
  }${
    lang ? `&lang_ability=${lang}` : ""
  }${
    jobOffer ? `&job_offer=${jobOffer}` : ""
  }`;

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => {
    axios.get(url).then((response) => {
      dispatch(GET_QUESTIONS(response.data.data.programs))
        console.log("done")
    });
  } , [url , dispatch])

  // Search Plan
  const [search, setSearch] = useState("")
  const [filteredPlans, setFilteredPlans] = useState("")

  useEffect(() => {

    if(search.length > 0 && search.charCodeAt(0) !== 32 ) {
      const newData = plans && 
      plans.filter( plan => plan.title.toLowerCase().includes(search.toLowerCase()))
      setFilteredPlans(newData)
    } else {
      setFilteredPlans(plans)
    }

  } , [search , plans])
  
  // cases
  const [cases, setCases] = useState([])
    const url2 = "https://bksys.getincanada.ca/api/admin/cases";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => {
    axios.get(url2).then((response) => {
      setCases(response.data.data)
    });
  } , [])


  // Delete plan
  const [delConfirm, setdelConfirm] = useState(false)
  const [delId, setdelId] = useState(null)



  const deletePlan = () => {

    const url2 = `https://bksys.getincanada.ca/api/admin/programs/${delId}`

      axios.delete(url2).then((response) => {

        dispatch(DEL_QUESTION(delId))
        setdelConfirm(false)
        setOpen(true)

      }).catch(err => console.log(err))


  }
  
  const deleteConfirm = {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="101" viewBox="0 0 79 101" fill="none">
        <path d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z" fill="#E7511D"/>
      </svg>
      ),
      message: `are you sure you want to delete this plan ?`,
      button1: (
            <Button
                color="primary"
                variant="outlined"
                sx={{
                    width: "250px",
                    py: 8,
                    px: { xs: 20, sm: 40 },
                    borderRadius: "8px",
                }}
                onClick={() => deletePlan()}
            >
                yes
            </Button>
      ),
      button2: (
          <Button
              color="secondary"
              onClick={() => setdelConfirm(false)}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              no
          </Button>
      ),
  };
  
  return (
    <>
      <Navbar />
      <div style={{display : "flex" , position : "relative"}}>
        <Sidebar />
        <div className='Plans main-section'>
          <Bread links={links}/>
          <Search search={search} setSearch={setSearch} />
          <Filter cases={cases} setCaseID={setCaseID} setPricing={setPricing} setLang={setLang} setJobOffer={setJobOffer} />
          <div className="plansContainer">
            {filteredPlans?.length > 0 && filteredPlans.map((plan , i) => {
              return <Plan plan={plan} cases={cases} key={i} setdelConfirm={setdelConfirm} setdelId={setdelId}  />
            })}
          </div>
          <MSAlert open={open} setOpen={setOpen} message={"deleted successfully"} type={"success"} />
        </div>
        <CopyWriting />
      </div>
      {delConfirm && <PopUp pop={deleteConfirm} setActive={setdelConfirm} />}
    </>
  )
}

const Search = ({ search , setSearch }) => {

  const navigate = useNavigate()
  
  return (
    <Stack mb={9} direction="row" alignItems="center" justifyContent="space-between" spacing={50}>
      <div className="input-icon search">
        <input type="search" placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} />
        <div className="icon"><SearchIcon /></div>
      </div>

      <Stack direction={"row"} spacing={8}>
        <Button onClick={() => navigate("/add-plan")} sx={{p : "12px 40px", fontSize : "16px"}} variant="outlined" color="secondary" >add new plan</Button>
        <Button onClick={() => navigate("/check-eligibility")} variant="contained" color="secondary" sx={{p : "12px 40px", fontSize : "16px"}}>Check Eligibility</Button>
      </Stack>

    </Stack>
  )
}

const Filter = ({setCaseID , setJobOffer , setPricing , setLang , cases}) => {

  const [selected, setSelected] = useState([])
  const [sliderValue, setSliderValue] = useState(null)

  return (
    <Accordion className="Filter" sx={{
      borderRadius : "8px",
      boxShadow : "none",
      mb : 8
    }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          borderBottom : "1px solid #CBCFD2",
          pl : 6
        }}
      >
        <Stack direction="row" spacing={4}>
          <FilterListIcon sx={{color: "#A6A6A6"}} />
          <Typography
          sx={{
            fontWeight : "500",
            color : "#A6A6A6"
          }}
          >
            Filter By
          </Typography>
          <RotateLeftIcon 
          onClick = {() => {
            window.location.reload();
          }}
          sx={{
            color: "#A6A6A6",
            cursor: "pointer",
            "&:hover" : {
              color : "primary.main"
            }
            }} />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack my={8} mx={6} direction={"row"} spacing={8} alignItems={"center"}>
          <TextField
            fullWidth
            select
            defaultValue={""}
            label="WANT TO"
            sx={{
              flex: 1 ,
              textTransform : "uppercase",
            }}
            onChange={(e) => {
              !selected.includes(e.target.value) && setSelected([ ...selected , e.target.value])
              setCaseID(e.target.value)
            }}
          >
            {cases.length > 0 && cases.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              )
            })}
          </TextField>
          <TextField
            fullWidth
            select
            defaultValue={""}
            label="JOB OFFER"
            sx={{
              flex: 1 ,
              textTransform : "uppercase",
            }}
            onChange={(e) => setJobOffer(e.target.value)}
          >
            <MenuItem value={"yes"}>
              yes
            </MenuItem>
            <MenuItem value={"no"}>
              no
            </MenuItem>
          </TextField>
          <TextField
            fullWidth
            select
            defaultValue={""}
            label="LANGUAGE ABILITY"
            sx={{
              flex: 1 ,
              textTransform : "uppercase",
            }}
            onChange={(e) => {
              setLang(e.target.value)
            }}
          >
            <MenuItem value="No English">
              No English
            </MenuItem>
            <MenuItem value="Elementary English">
              Elementary English
            </MenuItem>
            <MenuItem value="Limited Working English">
              Limited Working English
            </MenuItem>
            <MenuItem value="Professional Working English">
              Professional Working English
            </MenuItem>
            <MenuItem value="Full Professional English">
              Full Professional English
            </MenuItem>
            <MenuItem value="Native / Bilingual English">
              Native / Bilingual English
            </MenuItem>
          </TextField>
          <Stack>
            <label className="slider-label">NET WORTH IN CAD</label>
            <Slider onChange={(e) => setSliderValue(e.target.value) }  sx={{minWidth : "200px"}} defaultValue={50000} aria-label="Default" valueLabelDisplay="auto" min={1000} step={1000} max={100000} />
            { sliderValue && <Button onClick={() => setPricing(sliderValue)} variant="outlined">confirm</Button>}
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

const Plan = ({plan , cases , setdelConfirm , setdelId}) => {

  const navigate = useNavigate()
  
  return (
    <Stack className="Plan" border="1px solid rgba(203, 207, 210, 0.4)" borderRadius="8px" bgcolor="white" pb={20} position={"relative"}
    >
      <Stack bgcolor={"#d2000012"} direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={6} mb={4} >
        <h4>{plan?.title}</h4>
        <Stack direction={"row"} alignItems={"center"} spacing={8} >

          <CreateOutlinedIcon onClick={() => {
            navigate("/edit-plan/" + plan?.id)
            localStorage.setItem("GIC_plan_edit" , JSON.stringify(plan))
          }} color="primary" sx={{cursor : "pointer",}} fontSize="small" />

          <DeleteOutlineOutlinedIcon onClick={() => {
            setdelConfirm(true)
            setdelId(plan?.id)
          }} color="secondary" sx={{cursor : "pointer",}} fontSize="small"/>

        </Stack>
      </Stack>
      <Stack p={6} pb={8} pt={0}>
        <h3>case</h3>
        <div className="rects">
          
          { cases && cases.map((item , i) => {
            return (
              <div key={i} className={item?.id === plan?.case?.id ? "active rect" : "rect" }>{item?.name}</div>
            )
          }) }
          
        </div>
        <h3>Description</h3>
        <p>{plan?.pref}</p>
        <Link to={"/plans/" + plan?.id} style={{ position : "absolute" , bottom : "16px" , right : "12px"  }} ><Button variant="outlined" sx={{py : 5 , px : 8 , borderRadius : "8px" , fontWeight : "400" , fontSize : "14px" , lineHeight : "21px"}} endIcon={<ArrowForwardIcon />} >view more details</Button></Link>
      </Stack>
    </Stack>
  )
}

export default Plans
