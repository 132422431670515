import { createSlice } from "@reduxjs/toolkit";

const answerSlice = createSlice({
  name : "answers",
  initialState : { answers : [] },
  reducers : {
    GET_ANSWERS : (state , action) => {
      state.answers = action.payload
    },
    ADD_ANSWER : (state , action) => {
      state.answers =  [ action.payload , ...state.answers ]
    },
    EDIT_ANSWER : (state , action) => {
      const item = state.answers.filter(answer => answer.id === action.payload.id)
      const index = state.answers.indexOf(item[0])
      state.answers[index] = action.payload
      state.answers = [ ...state.answers ]
    },
    DEL_ANSWER : (state , action) => {
      state.answers = state.answers.filter(answer => answer.id !== action.payload )
    },
  }
})


export const { GET_ANSWERS , ADD_ANSWER , EDIT_ANSWER , DEL_ANSWER } = answerSlice.actions;
export default answerSlice.reducer;