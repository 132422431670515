import "./CopyWriting.scss"

const CopyWriting = () => {
  return (
    <div className="CopyWriting">
      <p>© 2023 <span>Get In Canada</span>. All Rights Reserved.</p>
    </div>
  )
}

export default CopyWriting
