import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import Bread from '../../components/Bread/Bread';
import CopyWriting from '../../components/CopyWriting/CopyWriting';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button, Stack, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Link } from 'react-router-dom';
import PopUp from '../../components/PopUp/PopUp';
import { useState } from 'react';

const AddLawyer = () => {

  const links = [
    {name :"Dashboard" , to : "/"} ,
    {name :"admins List" , to : "/admins-list"} , 
    {name :"Add New admin" , to : "#"}
  ]

  const cancelConfirm = {
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="104"
            viewBox="0 0 104 104"
            fill="none"
        >
            <path
                d="M99 51.9899C99 77.9473 77.9574 98.9899 52 98.9899C26.0426 98.9899 5 77.9473 5 51.9899C5 26.0325 26.0426 4.98987 52 4.98987C77.9574 4.98987 99 26.0325 99 51.9899Z"
                stroke="#E7511D"
                strokeWidth="6"
            />
            <path
                d="M52.0001 100.155C78.601 100.155 100.165 78.5908 100.165 51.99C100.165 25.3891 78.601 3.82483 52.0001 3.82483C25.3992 3.82483 3.83496 25.3891 3.83496 51.99C3.83496 78.5908 25.3992 100.155 52.0001 100.155Z"
                stroke="#E7511D"
                strokeWidth="6"
            />
            <path
                d="M52.0001 55.5761L34.069 73.5072C33.5567 74.0195 32.959 74.2756 32.2759 74.2756C31.5928 74.2756 30.9951 74.0195 30.4828 73.5072C29.9705 72.9948 29.7144 72.3971 29.7144 71.7141C29.7144 71.031 29.9705 70.4333 30.4828 69.921L48.4139 51.9899L30.4828 34.0589C29.9705 33.5466 29.7144 32.9489 29.7144 32.2658C29.7144 31.5827 29.9705 30.985 30.4828 30.4727C30.9951 29.9604 31.5928 29.7042 32.2759 29.7042C32.959 29.7042 33.5567 29.9604 34.069 30.4727L52.0001 48.4037L69.9311 30.4727C70.4434 29.9604 71.0411 29.7042 71.7242 29.7042C72.4073 29.7042 73.005 29.9604 73.5173 30.4727C74.0296 30.985 74.2858 31.5827 74.2858 32.2658C74.2858 32.9489 74.0296 33.5466 73.5173 34.0589L55.5863 51.9899L73.5173 69.921C74.0296 70.4333 74.2858 71.031 74.2858 71.7141C74.2858 72.3971 74.0296 72.9948 73.5173 73.5072C73.005 74.0195 72.4073 74.2756 71.7242 74.2756C71.0411 74.2756 70.4434 74.0195 69.9311 73.5072L52.0001 55.5761Z"
                fill="#E7511D"
            />
        </svg>
    ),
    message: `are you sure you want to cancel ?`,
    button1: (
        <Link to="/admins-list">
            <Button
                color="primary"
                variant="outlined"
                sx={{
                    width: "250px",
                    py: 8,
                    px: { xs: 20, sm: 40 },
                    borderRadius: "8px",
                }}
            >
                yes
            </Button>
        </Link>
    ),
    button2: (
        <Button
            color="secondary"
            onClick={() => setActiveCancel(false)}
            variant="outlined"
            sx={{
                width: "250px",
                py: 8,
                px: { xs: 20, sm: 40 },
                borderRadius: "8px",
            }}
        >
            no
        </Button>
    ),
  };

  const clearConfirm = {
      icon: (
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="79"
              height="101"
              viewBox="0 0 79 101"
              fill="none"
          >
              <path
                  d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z"
                  fill="#E7511D"
              />
          </svg>
      ),
      message: `are you sure you want to clear all ?`,
      button1: (
          <Button
              color="primary"
              onClick={() => window.location.reload()}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              yes
          </Button>
      ),
      button2: (
          <Button
              color="secondary"
              onClick={() => setActiveClear(false)}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              no
          </Button>
      ),
  };

  const [id, setId] = useState(null)
  
  const pop = {
    icon : <svg xmlns="http://www.w3.org/2000/svg" width="103" height="103" viewBox="0 0 103 103" fill="none">
    <path d="M98.3164 51.175C98.3164 77.1324 77.2738 98.175 51.3164 98.175C25.359 98.175 4.31641 77.1324 4.31641 51.175C4.31641 25.2177 25.359 4.17505 51.3164 4.17505C77.2738 4.17505 98.3164 25.2177 98.3164 51.175Z" stroke="#71D54F" strokeWidth="6"/>
    <path d="M51.3165 99.3403C77.9174 99.3403 99.4817 77.776 99.4817 51.1752C99.4817 24.5743 77.9174 3.01001 51.3165 3.01001C24.7156 3.01001 3.15137 24.5743 3.15137 51.1752C3.15137 77.776 24.7156 99.3403 51.3165 99.3403Z" stroke="#71D54F" strokeWidth="6"/>
    <path d="M26.3164 55.4608L39.8878 69.0322" stroke="#71D54F" strokeWidth="6" strokeLinecap="round"/>
    <path d="M39.8877 69.0322L75.602 33.3179" stroke="#71D54F" strokeWidth="6" strokeLinecap="round"/>
    </svg>,
    message : "Successfull registration ",
    message2 : "Your admin has been registred successfully!",
    button1 : <Link to="/admins-list" >
                <Button variant="contained" sx={{py : 8 , px : {xs : 40 , sm : 100}}}> go to admins list </Button>
              </Link>,
  }




  const [activeCancel, setActiveCancel] = useState(false)
  const [activeClear, setActiveClear] = useState(false)
  const [activeSubmit, setActiveSubmit] = useState(false)
  




  
  return (
    <>
      <Navbar />
      <div style={{display : "flex"}}>
          <Sidebar />

          <div className='AddClient main-section'>

            <Bread links={links} />

            <h2>add new addmin</h2>

            <Personal setActiveCancel={setActiveCancel} setActiveClear={setActiveClear} setActiveSubmit={setActiveSubmit} setId={setId} />

            <CopyWriting />
            
            {activeCancel && <PopUp pop={cancelConfirm} setActive={setActiveCancel} />}

            {activeClear && <PopUp pop={clearConfirm} setActive={setActiveClear} />}

            {activeSubmit && <PopUp pop={pop} setActive={setActiveSubmit} />}
            
          </div>
      </div>
    </>
  )
}

const Personal = ({setActiveCancel , setActiveClear , setActiveSubmit, setId}) => {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/admin/admins";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  
  const onSubmit = (data) => {
    axios.post(url , data).then((response) => {
      console.log(response)
      setId(response.data.data.admin.id)
      setActiveSubmit(true)
    });
  }
  
  
  return (
    <div data-aos="fade-up">
      <form onSubmit={handleSubmit(onSubmit)}>
      <Stack className="box" bgcolor="white" p={8} pb={12} mb={12} borderRadius="8px">

        <h2>Applicant Personal Information</h2>

        <hr />

        <div className="inputs">
          
          <div className="input">
            <TextField fullWidth color="primary" label="first name" variant="outlined" 
            { ...register("first_name" , { required : true }) }
            />
            {errors.first_name?.type === "required" && <p className="error">First Name is Requierd</p>}
          </div>
          <div className="input">
            <TextField fullWidth color="primary" label="last name" variant="outlined"
            { ...register("last_name" , { required : true }) }
            />
            {errors.last_name?.type === "required" && <p className="error">last Name is Requierd</p>}
          </div>
          <div className="input">
            <TextField fullWidth color="primary" type='number' label="phone" variant="outlined" 
            { ...register("phone" , { required : true }) }
            />
            {errors.phone?.type === "required" && <p className="error">phone is Requierd</p>}
          </div>
          <div className="input">
            <TextField fullWidth color="primary" type='email' label="email" variant="outlined" 
            {...register("email" , {required : true , pattern : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i } )}
            />
            {errors.email?.type === "required" && <p className="error">Email is Requierd</p>}
            {errors.email?.type === "pattern" && <p className="error">Please Enter a valid Email</p>}
          </div>
          <div className="input">
            <TextField fullWidth color="primary" type='password' label="password" variant="outlined" 
            {...register("password" , { required : true , minLength : 8 , maxLength : 16 })} 
            />
            {errors.password?.type === "required" && <p className="error">password is Requierd</p>}
            {errors.password?.type === "minLength" && <p className="error">password minimum characters is 8</p>}
            {errors.password?.type === "maxLength" && <p className="error">password maximum characters is 16</p>}
          </div>

        </div>

        </Stack>

        <Submit setActiveCancel={setActiveCancel} setActiveClear={setActiveClear}  />
      </form>
    </div>
  )
}

const Submit = ({setActiveCancel , setActiveClear}) => {


  
  return (
    <div>
      <Stack direction={{xs : "column" , sm : "row"}} alignItems="center" justifyContent="space-between" mb={35} spacing={{xs : 12 , sm : 0}}>
          <Button onClick={() => setActiveClear(true)} sx={{
              p : "1px 2px",
              color : "#5F5F5F"
            }} startIcon={<DeleteOutlineIcon/>} >Clear all</Button>
          <Stack direction="row" alignItems="center" spacing={12}>
            <Button 
              onClick={() => setActiveCancel(true)}
              variant="outlined" 
              sx={{
                py : 8 , px : 20,
                borderRadius : "8px",
              }} 
            >
              cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{
              py : 8 , px : 20,
              borderRadius : "8px",
            }}>
              submit
            </Button>
          </Stack>
        </Stack>

    </div>
  )
}

export default AddLawyer
