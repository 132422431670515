import { Breadcrumbs } from '@mui/material';
import "./Bread.scss"
import { Link } from 'react-router-dom';

const Bread = ({links}) => {
  return (
    <Breadcrumbs sx={{mb :8}} aria-label="breadcrumb" className="Bread">
      {links.map((link , i) => {
        return (
          <Link
            underline="hover"
            color="inherit"
            to={link.to}
            key={i}
          >
            {link.name}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default Bread
