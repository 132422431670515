import "./AddClient.scss"
import Bread from "../../components/Bread/Bread";
import { Button, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Stack, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useEffect, useState } from "react";
import PopUp from "../../components/PopUp/PopUp"
import { Link } from "react-router-dom";
import CopyWriting from "../../components/CopyWriting/CopyWriting"
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useForm } from "react-hook-form";
import axios from "axios";
import MSAlert from "../../components/MSAlert"


const AddClient = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  } , [])

  const links = [
    {name :"Dashboard" , to : "/"} ,
    {name :"Clients List" , to : "/clients-list"} , 
    {name :"Add New Client" , to : "#"}
  ]

  const [activeStep, setActiveStep] = useState(0)

  const [activeCancel, setActiveCancel] = useState(false)
  const [activeClear, setActiveClear] = useState(false)
  const [activeSubmit, setActiveSubmit] = useState(false)


  const cancelConfirm = {
    icon: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="104"
            height="104"
            viewBox="0 0 104 104"
            fill="none"
        >
            <path
                d="M99 51.9899C99 77.9473 77.9574 98.9899 52 98.9899C26.0426 98.9899 5 77.9473 5 51.9899C5 26.0325 26.0426 4.98987 52 4.98987C77.9574 4.98987 99 26.0325 99 51.9899Z"
                stroke="#E7511D"
                strokeWidth="6"
            />
            <path
                d="M52.0001 100.155C78.601 100.155 100.165 78.5908 100.165 51.99C100.165 25.3891 78.601 3.82483 52.0001 3.82483C25.3992 3.82483 3.83496 25.3891 3.83496 51.99C3.83496 78.5908 25.3992 100.155 52.0001 100.155Z"
                stroke="#E7511D"
                strokeWidth="6"
            />
            <path
                d="M52.0001 55.5761L34.069 73.5072C33.5567 74.0195 32.959 74.2756 32.2759 74.2756C31.5928 74.2756 30.9951 74.0195 30.4828 73.5072C29.9705 72.9948 29.7144 72.3971 29.7144 71.7141C29.7144 71.031 29.9705 70.4333 30.4828 69.921L48.4139 51.9899L30.4828 34.0589C29.9705 33.5466 29.7144 32.9489 29.7144 32.2658C29.7144 31.5827 29.9705 30.985 30.4828 30.4727C30.9951 29.9604 31.5928 29.7042 32.2759 29.7042C32.959 29.7042 33.5567 29.9604 34.069 30.4727L52.0001 48.4037L69.9311 30.4727C70.4434 29.9604 71.0411 29.7042 71.7242 29.7042C72.4073 29.7042 73.005 29.9604 73.5173 30.4727C74.0296 30.985 74.2858 31.5827 74.2858 32.2658C74.2858 32.9489 74.0296 33.5466 73.5173 34.0589L55.5863 51.9899L73.5173 69.921C74.0296 70.4333 74.2858 71.031 74.2858 71.7141C74.2858 72.3971 74.0296 72.9948 73.5173 73.5072C73.005 74.0195 72.4073 74.2756 71.7242 74.2756C71.0411 74.2756 70.4434 74.0195 69.9311 73.5072L52.0001 55.5761Z"
                fill="#E7511D"
            />
        </svg>
    ),
    message: `are you sure you want to cancel ?`,
    button1: (
        <Link to="/clients-list">
            <Button
                color="primary"
                variant="outlined"
                sx={{
                    width: "250px",
                    py: 8,
                    px: { xs: 20, sm: 40 },
                    borderRadius: "8px",
                }}
            >
                yes
            </Button>
        </Link>
    ),
    button2: (
        <Button
            color="secondary"
            onClick={() => setActiveCancel(false)}
            variant="outlined"
            sx={{
                width: "250px",
                py: 8,
                px: { xs: 20, sm: 40 },
                borderRadius: "8px",
            }}
        >
            no
        </Button>
    ),
  };

  const clearConfirm = {
      icon: (
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="79"
              height="101"
              viewBox="0 0 79 101"
              fill="none"
          >
              <path
                  d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z"
                  fill="#E7511D"
              />
          </svg>
      ),
      message: `are you sure you want to clear all ?`,
      button1: (
          <Button
              color="primary"
              onClick={() => window.location.reload()}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              yes
          </Button>
      ),
      button2: (
          <Button
              color="secondary"
              onClick={() => setActiveClear(false)}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              no
          </Button>
      ),
  };

  const [id, setId] = useState(null)
  
  const pop = {
    icon : <svg xmlns="http://www.w3.org/2000/svg" width="103" height="103" viewBox="0 0 103 103" fill="none">
    <path d="M98.3164 51.175C98.3164 77.1324 77.2738 98.175 51.3164 98.175C25.359 98.175 4.31641 77.1324 4.31641 51.175C4.31641 25.2177 25.359 4.17505 51.3164 4.17505C77.2738 4.17505 98.3164 25.2177 98.3164 51.175Z" stroke="#71D54F" strokeWidth="6"/>
    <path d="M51.3165 99.3403C77.9174 99.3403 99.4817 77.776 99.4817 51.1752C99.4817 24.5743 77.9174 3.01001 51.3165 3.01001C24.7156 3.01001 3.15137 24.5743 3.15137 51.1752C3.15137 77.776 24.7156 99.3403 51.3165 99.3403Z" stroke="#71D54F" strokeWidth="6"/>
    <path d="M26.3164 55.4608L39.8878 69.0322" stroke="#71D54F" strokeWidth="6" strokeLinecap="round"/>
    <path d="M39.8877 69.0322L75.602 33.3179" stroke="#71D54F" strokeWidth="6" strokeLinecap="round"/>
    </svg>,
    message : "Successfull registration ",
    message2 : "Your client has been registred successfully !",
    button1 : <Link to="/clients-list" >
                <Button variant="contained" sx={{py : 8 , px : {xs : 40 , sm : 100}}}> go to clients list </Button>
              </Link>,
    id : id,
    type : "client"       
  }
  
  return (
    
    <>
      <Navbar />
      <div style={{display : "flex"}}>
          <Sidebar />

          <div className='AddClient main-section'>

            <Bread links={links} />

            <h2>add new client</h2>

            <Steps activeStep={activeStep} />

            <Personal step = {activeStep} setActiveStep={setActiveStep} setActiveCancel={setActiveCancel} setActiveClear={setActiveClear} activeStep={activeStep} setActiveSubmit={setActiveSubmit} setId={setId} />

            <CopyWriting />
            
            {activeCancel && <PopUp pop={cancelConfirm} setActive={setActiveCancel} />}
            {activeClear && <PopUp pop={clearConfirm} setActive={setActiveClear} />}
            {activeSubmit && <PopUp pop={pop} setActive={setActiveSubmit} />}
            
          </div>
      </div>
    </>
  )
}

const Steps = ({activeStep}) => {
  return (
    <Stepper activeStep={activeStep} alternativeLabel sx={{maxWidth : "580px" , margin : "auto", mb : 20}}>
        <Step >
          <StepLabel>Personal Information</StepLabel>
        </Step>
        <Step>
          <StepLabel>education</StepLabel>
        </Step>
        <Step>
          <StepLabel>work</StepLabel>
        </Step>
        <Step>
          <StepLabel>Other Details</StepLabel>
        </Step>
    </Stepper>
  )
}

const Personal = ({step , setActiveStep , setActiveCancel , setActiveClear , activeStep , setActiveSubmit , setId}) => {

  // Form
  const { register, handleSubmit, formState: { errors } } = useForm();

  // Educations
  const [edu, setEdu] = useState([1])
  
  const [eduData, setEduData] = useState([{
    name_of_educational_institution : "",
    country : "",
    from : "",
    to : "",
    total_of_years : "",
    field_of_study : "",
    education_degree : "",
    id : 0
  }]) 

  // work 
  const [work, setWork] = useState([1])
  
  const [workData, setWorkData] = useState([{
    name_of_company : "",
    country : "",
    from : "",
    to : "",
    isPaid : 0,
    job_title : "",
    number_of_hourse_week : "",
    id : 0
  }]) 

  
  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/add-client";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  const onSubmit = (data) => {
    data.educations = eduData;
    data.works = workData;
    console.log(data)

    if (activeStep < 3) {
      setActiveStep(prev => prev + 1)
    } else {
      axios.post(url , data).then((response) => {
        console.log(response.data)
        setActiveSubmit(true)
      }).catch(err => {
        console.log(err.response.data)
        setMessage("Some Thing Went Wrong Please Make Sure You Have Filled All Fields Correctly")
        setOpen(true)
      });
    }
  }



  return (
    <>
      <div data-aos="fade-up">
        <form onSubmit={handleSubmit(onSubmit)} >

          <Inputs1 step={step} register={register} errors={errors}/>

          { edu.length > 0 && edu.map((ed , i) => {
            return (
              <Inputs2 eduData={eduData} setEduData={setEduData} i={i} key={i} step={step} edu={edu} setEdu={setEdu}/>
            )
          })}

          { work.length > 0 && work.map((ed , i) => {
            return (
              <Inputs3 workData={workData} setWorkData={setWorkData} i={i} key={i} step={step} work={work} setWork={setWork} register={register} errors={errors}/>
            )
          })}

          <Inputs4 step={step} register={register} errors={errors}/>  
          
          <Submit setActiveStep={setActiveStep} activeStep={step} errors={errors} setActiveCancel={setActiveCancel} setActiveClear={setActiveClear} />
        </form>
      </div>
      <MSAlert open={open} setOpen={setOpen} type={"error"} message={message} />
    </>
  )
}

const Inputs1 = ({step , register , errors}) => {
  return (

    <Stack className="box" bgcolor="white" p={8} pb={12} mb={12} borderRadius="8px" sx={{display : step === 0 ? "grid" : "none"}}>

      <h2>Principal Applicant Education ( start with higher level)</h2>

      <hr />

      <div className="inputs" style={{display : step === 0 ? "grid" : "none" , marginBottom : "24px"}}>
              
        <div className="input">
          <TextField fullWidth color="primary" label="full name" variant="outlined" 
          { ...register("full_name") }
          />
          {errors.full_name?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="country of birth" variant="outlined" 
          { ...register("country_of_birth" , { required : false }) }
          />
          {errors.country_of_birth?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" variant="outlined" type="date"
          { ...register("date_birth" , { 
            required : false ,
            onChange : (e) => {
              const date = new Date(e.target.value)
              console.log(date);
              console.log(e.target.value)
            }
          }) }
          
          />
          {errors.date_birth?.type === "required" && <p className="error">this field is required</p>}
        </div>
        
        <div className="input">
          <TextField fullWidth color="primary" label="citizenship" variant="outlined" 
          { ...register("citizenship" , { required : false }) }
          />
          {errors.citizenship?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="highest education diploma" variant="outlined" 
          { ...register("highest_education_diploma" , { required : false }) }
          />
          {errors.highest_education_diploma?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="country of residence" variant="outlined" 
          { ...register("country_of_residence" , { required : false }) }
          />
          {errors.country_of_residence?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="email" variant="outlined" 
          { ...register("email" , { required : false }) }
          />
          {errors.email?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="status country of residence" variant="outlined" 
          { ...register("status_country_of_residence" , { required : false }) }
          />
          {errors.status_country_of_residence?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="phone" variant="outlined" type="number"
          { ...register("phone" , { required : false }) }
          />
          {errors.phone?.type === "required" && <p className="error">this field is required</p>}
        </div>
        
        <div className="input">
          <TextField fullWidth color="primary" label="city" variant="outlined" 
          { ...register("city" , { required : false }) }
          />
          {errors.city?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="marital status" variant="outlined" 
          { ...register("marital_status" , { required : false }) }
          />
          {errors.marital_status?.type === "required" && <p className="error">this field is required</p>}
        </div>

        <div className="radio input">
          <FormLabel sx={{
            mb : 5,
            color : "#424242"
          }}>
            Gender *
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{gap : 50}}
          >
            <FormControlLabel { ...register("gender" , { required : false }) } value="1" control={<Radio />} label="male" />
            <FormControlLabel { ...register("gender" , { required : false }) } value="2" control={<Radio color="secondary"/>} label="female" />
          </RadioGroup>
          {errors.gender?.type === "required" && <p className="error">this field is required</p>}
        </div>


      </div>

      <div className="input">
          <TextField fullWidth color="primary" label="How did you hear about us?" variant="outlined" 
          { ...register("hear_about_us" , { required : false }) }
          />
          {errors.hear_about_us?.type === "required" && <p className="error">this field is required</p>}
      </div>

    </Stack>
  )
}

const Inputs2 = ({step , i , eduData, setEdu , setEduData , edu}) => {
  
  return (  
    
      <Stack className="box" bgcolor="white" p={8} pb={12} mb={12} borderRadius="8px" sx={{display : step === 1 ? "grid" : "none"}}>

        <h2> Education ( start with higher level)</h2>

        <hr />

        <div className="inputs double">
        
          <div className="input">
            <Stack spacing={2} >
              <Typography> from </Typography>
              <TextField fullWidth color="primary" variant="outlined" type="date"
                onChange={(e) => {
                  eduData[i].from = e.target.value;
                }}
              />
            </Stack>
          </div>

          <div className="input">
            <Stack spacing={2} >
              <Typography> to </Typography>
              <TextField fullWidth color="primary" variant="outlined" type="date"
                onChange={(e) => {
                  eduData[i].to = e.target.value;
                }}
              />
            </Stack>
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="Name of educational institution " variant="outlined" 
              onChange={(e) => {
                eduData[i].name_of_educational_institution = e.target.value;
              }}
            />
  
          </div>

          <div className="input">
            <TextField fullWidth color="primary" label="Country" variant="outlined" 
            onChange={(e) => {
              eduData[i].country = e.target.value;
            }}
            />
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="Total of Years " type="number" variant="outlined" 
            onChange={(e) => {
              eduData[i].total_of_years = e.target.value;
            }}
            />
          </div>

          <div className="input">
            <TextField fullWidth color="primary" label="Field of study " variant="outlined" 
            onChange={(e) => {
              eduData[i].field_of_study = e.target.value;
            }}
            />
          </div>

          <div className="input">
            <TextField fullWidth color="primary" label="Degree obtained" variant="outlined" 
            onChange={(e) => {
              eduData[i].education_degree = e.target.value;
            }}
            />
          </div>

          <Button variant="outlined"
          sx={{ py : 7 }}
          onClick={() => {
            setEdu(prev => [...prev , 1])
            setEduData(prev => [...prev , {
              name_of_educational_institution : "",
              country : "",
              from : "",
              to : "",
              total_of_years : "",
              field_of_study : "",
              education_degree : "",
              id : edu.length
            }])
            console.log(eduData)
          }} > Add More </Button>

        </div>

      </Stack>
  )

}

const Inputs3 = ({step , i , workData, setWork , setWorkData , work }) => {
  return (

    <Stack className="box" bgcolor="white" p={8} pb={12} mb={12} borderRadius="8px" sx={{display : step === 2 ? "grid" : "none"}}>

      <h2>other details</h2>

      <hr />

      <div className="inputs double">

        <div className="input">
          <Stack spacing={2} >
            <Typography> from </Typography>
            <TextField fullWidth color="primary" variant="outlined" type="date"
              onChange={(e) => {
                workData[i].from = e.target.value;
              }}
            />
          </Stack>
        </div>

        <div className="input">
          <Stack spacing={2} >
            <Typography> to </Typography>
            <TextField fullWidth color="primary" variant="outlined" type="date"
              onChange={(e) => {
                workData[i].to = e.target.value;
              }}
            />
          </Stack>
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="Name of company" variant="outlined" 
            onChange={(e) => {
              workData[i].name_of_company = e.target.value;
            }}
          />
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="Country" variant="outlined" 
            onChange={(e) => {
              workData[i].Country = e.target.value;
            }}
          />
        </div>

        <div className="input">
          <TextField
              select // tell TextField to render select
              label="paid"
              color="primary"
              defaultValue=""
              fullWidth
              onChange={(e) => {
                workData[i].isPaid = e.target.value === "yes" ? 1 : 0 ;
              }}
          >
            <MenuItem
                value="yes"
            >
                yes
            </MenuItem>
            <MenuItem
                value="no"
            >
                no
            </MenuItem>
          </TextField>
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="job title" variant="outlined" 
            onChange={(e) => {
              workData[i].job_title = e.target.value;
            }}
          />
        </div>

        <div className="input">
          <TextField fullWidth color="primary" label="No. of working hours/week" type="number" variant="outlined" 
            onChange={(e) => {
              workData[i].number_of_hourse_week = e.target.value;
            }}
          />
        </div>

        {i === work.length - 1 && <Button variant="outlined" 
          sx={{ py : 7 }}
          onClick={() => {
            setWork(prev => [...prev , 1])
            setWorkData(prev => [...prev , {
              name_of_company : "",
              country : "",
              from : "",
              to : "",
              isPaid : 0,
              job_title : "",
              number_of_hourse_week : "",
              id : work.length
            }])
          console.log(workData)
        }} > Add More </Button>}

      </div>

    </Stack>

  )
}

const Inputs4 = ({step , register , errors}) => {

  return (


    <>

      <Stack className="box" bgcolor="white" p={8} pb={12} mb={12} borderRadius="8px" sx={{display : step === 3 ? "grid" : "none"}}>

        <h2>Principal Applicant Language Skills</h2>

        <hr />
        
        <div className="lang-head">english</div>
        <div className="lang">
            <TextField
                select // tell TextField to render select
                label="speaking"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("en_speaking")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
            <TextField
                select // tell TextField to render select
                label="listening"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("en_listening")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
            <TextField
                select // tell TextField to render select
                label="reading"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("en_reading")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
            <TextField
                select // tell TextField to render select
                label="writing"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("en_writing")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
        </div>


        <div className="lang-head">french</div>
        <div className="lang">
            <TextField
                select // tell TextField to render select
                label="speaking"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("fr_speaking")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
            <TextField
                select // tell TextField to render select
                label="listening"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("fr_listening")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
            <TextField
                select // tell TextField to render select
                label="reading"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("fr_reading")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
            <TextField
                select // tell TextField to render select
                label="writing"
                color="primary"
                defaultValue=""
                fullWidth
                {...register("fr_writing")}
            >
              <MenuItem
                  value="good"
              >
                  good
              </MenuItem>
              <MenuItem
                  value="excellent"
              >
                  excellent
              </MenuItem>
            </TextField>
        </div>

      </Stack>
    
      <Stack className="box" bgcolor="white" p={8} pb={12} mb={12} borderRadius="8px" sx={{display : step === 3 ? "grid" : "none"}}>

        <h2>Principal Applicant Language Skills</h2>

        <hr />

        <div className="inputs quarters">

          <div className="input">
            <TextField fullWidth color="primary" label="GIC Account" variant="outlined" 
            { ...register("gic_account" , { required : false }) }
            />
            {/* {errors.gic_account?.type === "required" && <p className="error">this field is required</p>} */}
          </div>

          <div className="input">
            <TextField fullWidth color="primary" label="Case Type" variant="outlined" 
            { ...register("case_type" , { required : false }) }
            />
            {/* {errors.case_type?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="UCI NO." variant="outlined" 
            { ...register("uci_no" , { required : false }) }
            />
            {/* {errors.uci_no?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="Student Permit" variant="outlined" 
            { ...register("student_permit" , { required : false }) }
            />
            {/* {errors.student_permit?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="SP N." variant="outlined" 
            { ...register("sp_n" , { required : false }) }
            />
            {/* {errors.sp_n?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="SP Expiry Date(YYYY/MM/DD)" variant="outlined" 
            { ...register("sp_expiry_date" , { required : false }) }
            />
            {/* {errors.sp_expiry_date?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="Work Permit" variant="outlined" 
            { ...register("work_permit" , { required : false }) }
            />
            {/* {errors.work_permit?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="WP NO." variant="outlined" 
            { ...register("wp_no" , { required : false }) }
            />
            {/* {errors.wp_no?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="CAQ" variant="outlined" 
            { ...register("caq" , { required : false }) }
            />
            {/* {errors.caq?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="CAQ App. No." variant="outlined" 
            { ...register("caq_app_no" , { required : false }) }
            />
            {/* {errors.caq_app_no?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="CAQ NO." variant="outlined" 
            { ...register("caq_no" , { required : false }) }
            />
            {/* {errors.caq_no?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="CSQ" variant="outlined" 
            { ...register("csq" , { required : false }) }
            />
            {/* {errors.csq?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="CSQ App. No." variant="outlined" 
            { ...register("caq_app_no" , { required : false }) }
            />
            {/* {errors.caq_app_no?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="CSQ NO." variant="outlined" 
            { ...register("csq_no" , { required : false }) }
            />
            {/* {errors.csq_no?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="CRS" variant="outlined" 
            { ...register("crs" , { required : false }) }
            />
            {/* {errors.crs?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="NOC CODE" variant="outlined" 
            { ...register("noc_code" , { required : false }) }
            />
            {/* {errors.noc_code?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="EE SCORE" variant="outlined" 
            { ...register("ee_score" , { required : false }) }
            />
            {/* {errors.ee_score?.type === "required" && <p className="error">this field is required</p>} */}
          </div>
          
          <div className="input">
            <TextField fullWidth color="primary" label="FSW SCORE" variant="outlined" 
            { ...register("fsw_score" , { required : false }) }
            />
            {/* {errors.fsw_score?.type === "required" && <p className="error">this field is required</p>} */}
          </div>

        </div>
        
      </Stack>
  
      
    </>

  )
}

const Submit = ({setActiveStep , activeStep , setActiveCancel , setActiveClear}) => {
  
  return (
    <div>
      <Stack direction={{xs : "column" , sm : "row"}} alignItems="center" justifyContent="space-between" mb={35} spacing={{xs : 12 , sm : 0}}>
          <Stack direction="row" alignItems="center" spacing={6}>
            <Button startIcon={<DeleteOutlineIcon />} onClick={() => setActiveClear(true)} sx={{
                p : "2px 4px",
                color : "#5F5F5F"
              }} >Clear all</Button>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={12}>
            <Button 
              onClick={ () => activeStep === 0 ? setActiveCancel(true) : setActiveStep(prev => prev - 1) } 
              variant="outlined" 
              sx={{
                py : 8 , px : 20,
                borderRadius : "8px",
              }} 
            >
              {activeStep === 0 ? "Cancel" : "Back"}
            </Button>
            <Button type="submit" variant="contained" color="primary" sx={{
              py : 8 , px : 20,
              borderRadius : "8px",
            }}>
              {activeStep === 3 ? "Submit" : "next"}
            </Button>
          </Stack>
        </Stack>
    </div>
  )
}

export default AddClient
