import { createSlice } from "@reduxjs/toolkit";

const questionSlice = createSlice({
  name : "questions",
  initialState : { questions : [] },
  reducers : {
    GET_QUESTIONS : (state , action) => {
      state.questions = action.payload
    },
    ADD_QUESTION : (state , action) => {
      state.questions =  [ action.payload , ...state.questions ]
    },
    EDIT_QUESTION : (state , action) => {
      const item = state.questions.filter(question => question.id === action.payload.id)
      const index = state.questions.indexOf(item[0])
      state.questions[index] = action.payload
      state.questions = [ ...state.questions ]
    },
    DEL_QUESTION : (state , action) => {
      state.questions = state.questions.filter(question => question.id !== action.payload )
    },
  }
})


export const { GET_QUESTIONS , ADD_QUESTION , EDIT_QUESTION , DEL_QUESTION } = questionSlice.actions;
export default questionSlice.reducer;