import { Snackbar, Stack } from "@mui/material";
import "./PopUp.scss"
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useState } from "react";

const PopUp = ({setActive , pop , setPoints , type }) => {

  const [open, setOpen] = useState(false)
  
  return (
      <div className="PopUp">

        <Stack className="box-popup" p={20} pt={24} bgcolor="white" borderRadius="8px" alignItems="center" position="relative" boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        >
          
          <CloseIcon
            onClick = {() => {
              setActive(false)
              setPoints && setPoints(0)
            }}
            sx={{
            color : "#A6A6A6",
            position : "absolute",
            right : "24px",
            top : "24px",
            cursor : "pointer",
            "&:hover" : {
              color : "secondary.main"
            }
          }} />
          
          <div className="icon">
            {pop.icon}
          </div>

          <h1>{pop.message} {pop.score && pop.score}</h1>

          {pop.message2 && <h3>{pop.message2}</h3>}

          { pop.id && <div className="clientID" onClick={() => {
            navigator.clipboard.writeText(pop.id);
            setOpen(true)
          }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <ContentCopyIcon sx={{fontSize : "10px" , color : "#A6A6A6"}} />
              <h5>copy {pop.type === "lawyer" ? "lawyer" : "client"} ID</h5>
            </Stack>
            <h6>{pop.id}</h6>
          </div>}

          <Stack direction="row" spacing={8}>

            {pop.button1}

            {pop.button2 && pop.button2}

          </Stack>

        </Stack>

        <Snackbar 
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message={`${pop.id} Copied to clipboard`}
        />
        
      </div>
  )
}

export default PopUp
