import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Bread from "../../components/Bread/Bread";
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import "./Plan.scss"
import { Box, Button, Stack, Tab, Tabs } from "@mui/material";
// Step, StepLabel, Stepper,
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";



const Plan = () => {

    // API
    const { id } = useParams()

    const [plan, setPlan] = useState([])
    
    const token = localStorage.getItem("gic_token");
    const url = "https://bksys.getincanada.ca/api/programs/" + id;
  
    axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
  
        return config;
    });
    
    useEffect(() => {
      axios.get(url).then((response) => {
        console.log(response.data.data.programs)
        setPlan(response.data.data.programs)
      });
    } , [url])

  const links = [
    {
      name : "Dashboard",
      to : "/"
    },
    {
      name : "plans",
      to : "/plans"
    },
    {
      name : "plan details",
      to : "#"
    },
  ]
  
  return (
    <>
      <Navbar />
      <div style={{display : "flex" , position : "relative"}}>
        <Sidebar />
        <div className='Plan main-section'>
          <Bread links={links}/>
          <Stack bgcolor="white" p={8} pb={12} mb={24} borderRadius="8px">
            <PlanHeader plan={plan} />
            <PlanTabs plan={plan} />
          </Stack>
        </div>
        <CopyWriting />
      </div>
    </>
  )
}

const PlanHeader = ({plan}) => {
  return (
    <>
      <Stack mb={2} direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction={"row"} spacing={12} alignItems={"center"}>
          <h1>{plan?.title}</h1>
          <div className="rect">{plan.case?.name}</div>
        </Stack>
        <Button variant="contained" color="secondary" sx={{p : "12px 40px", fontSize : "16px"}}>Check Eligibility</Button>
      </Stack>
      { plan.pref && <p className="plan-header">
        {plan.pref}
      </p>}
    </>
  )
}

const PlanTabs = ({plan}) => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <div className="Tabs">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs textColor="secondary" value={value} onChange={handleChange}>
            <Tab label="General Information" {...a11yProps(0)} />
            <Tab label="Pricing" {...a11yProps(1)} />
            {/* <Tab label="how it works" {...a11yProps(2)} />
            <Tab label="success rate" {...a11yProps(3)} /> */}
            <Tab label="required documents" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <GeneralInfo plan={plan} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Pricing plan={plan} />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <HowItWorks />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SuccessRate />
        </TabPanel> */}
        <TabPanel value={value} index={2}>
          <RequiredDocs plan={plan} />
        </TabPanel>
      </Box>
    </div>
  )
}

const GeneralInfo = ({plan}) => {
  return (
    <div className="GeneralInfo" dangerouslySetInnerHTML={{ __html: plan.description }}>
      
    </div>
  )
}

const Pricing = ({plan}) => {
  return (
    <div className="Pricing">
      <div className="price"> <span>Pricing :</span> CA${plan.pricing} <span>+ Government Fees</span> </div>
      <ul>
        <li>Quick and easy online application</li>
        <li>Lower cost than traditional lawyers</li>
        <li>Peace of mind of expert review</li>
        <li>$1 million in application insurance</li>
        <li>Secure document storage</li>
      </ul>
    </div>
  )
}

// const HowItWorks = () => {
//   return (
//     <div className="HowItWorks">
//       <Box sx={{ width: '100%' }}>
//         <Stepper orientation="vertical">
//           <Step>
//             <StepLabel>
//               <Typography sx={{
//                 fontWeight: "500",
//                 color : "text.primary",
//                 fontSize : "16px",
//                 lineHeight : "24px",
//                 my : 4
//               }}>Alberta Express Entry Stream</Typography>
//               <Typography sx={{
//                 fontWeight: "400",
//                 color : "#424242",
//                 fontSize : "14px",
//                 lineHeight : "21px"
//               }} >
//                 Create an account and either check your eligibility, or pick an application to start. We start by asking questions specific to the visa to ensure your eligibility. This can be quick and simple, such as for visitor visas, or may require entering multiple questionnaires. If you have any questions, there are no fees to contact our immigration experts by email, WeChat, WhatsApp or by booking a call.
//               </Typography>
//             </StepLabel>
//           </Step>
//           <Step>
//             <StepLabel>
//               <Typography sx={{
//                 fontWeight: "500",
//                 color : "text.primary",
//                 fontSize : "16px",
//                 lineHeight : "24px",
//                 my : 4
//               }}>Alberta Express Entry Stream</Typography>
//               <Typography sx={{
//                 fontWeight: "400",
//                 color : "#424242",
//                 fontSize : "14px",
//                 lineHeight : "21px"
//               }} >
//                 Create an account and either check your eligibility, or pick an application to start. We start by asking questions specific to the visa to ensure your eligibility. This can be quick and simple, such as for visitor visas, or may require entering multiple questionnaires. If you have any questions, there are no fees to contact our immigration experts by email, WeChat, WhatsApp or by booking a call.
//               </Typography>
//             </StepLabel>
//           </Step>
//           <Step>
//             <StepLabel>
//               <Typography sx={{
//                 fontWeight: "500",
//                 color : "text.primary",
//                 fontSize : "16px",
//                 lineHeight : "24px",
//                 my : 4
//               }}>Alberta Express Entry Stream</Typography>
//               <Typography sx={{
//                 fontWeight: "400",
//                 color : "#424242",
//                 fontSize : "14px",
//                 lineHeight : "21px"
//               }} >
//                 Create an account and either check your eligibility, or pick an application to start. We start by asking questions specific to the visa to ensure your eligibility. This can be quick and simple, such as for visitor visas, or may require entering multiple questionnaires. If you have any questions, there are no fees to contact our immigration experts by email, WeChat, WhatsApp or by booking a call.
//               </Typography>
//             </StepLabel>
//           </Step>
//         </Stepper>
//       </Box>
//     </div>
//   )
// }

// const SuccessRate = () => {
//   return (
//     <div className="SuccessRate">
//       <h3>98%</h3>
//       <h4>Success Rate</h4>
//       <div className="boxes">
//         <div className="box">
//           <div className="icon">
//             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="33" viewBox="0 0 26 33" fill="none">
//             <path d="M17.9698 3.96899H9.65694C9.55319 3.96872 9.45023 3.98953 9.35473 4.03034H9.33179C9.29338 4.04768 9.25604 4.06795 9.22083 4.09142L9.18269 4.12209C9.15575 4.1421 9.13041 4.16371 9.10614 4.18718L0.996052 12.7336L0.957909 12.7832L0.91203 12.8406H0.911764C0.897894 12.863 0.88509 12.8859 0.873621 12.9094C0.873621 12.9286 0.850682 12.9515 0.842946 12.9705C0.833878 12.9969 0.826142 13.0238 0.820007 13.0507V13.1118V13.1121C0.815206 13.1617 0.815206 13.2116 0.820007 13.2612V28.4462C0.820007 29.4603 1.22304 30.4331 1.94 31.1501C2.65725 31.8673 3.62976 32.2701 4.64391 32.2701H21.0293C21.232 32.2701 21.4268 32.1895 21.57 32.046C21.7135 31.9028 21.7941 31.7081 21.7941 31.5054V7.79717C21.7951 6.78227 21.3926 5.80867 20.6754 5.0906C19.9581 4.3728 18.9851 3.96924 17.9701 3.96924L17.9698 3.96899ZM8.88807 6.64565V12.485H3.33201L8.88807 6.64565ZM20.2642 30.7362H4.60871C4.00029 30.7362 3.41668 30.4946 2.98642 30.0643C2.55616 29.6341 2.31452 29.0504 2.31452 28.442V14.0143H9.65641C9.85913 14.0143 10.0538 13.9338 10.1971 13.7903C10.3406 13.647 10.4211 13.4523 10.4211 13.2496V5.49869H17.9692C18.5779 5.49869 19.1613 5.74035 19.5918 6.17059C20.0221 6.60083 20.2637 7.18447 20.2637 7.79288L20.2642 30.7362Z" fill="currentColor"/>
//             <path d="M18.9329 0.0800171H11.2393C10.8168 0.0800171 10.4746 0.422236 10.4746 0.844729C10.4746 1.26697 10.8168 1.60944 11.2393 1.60944H18.9329C20.3527 1.60944 21.7144 2.17358 22.7184 3.17758C23.7224 4.18158 24.2863 5.54328 24.2863 6.96311V28.7935C24.2863 29.2157 24.6287 29.5582 25.0512 29.5582C25.4735 29.5582 25.816 29.2157 25.816 28.7935V6.96311C25.816 5.13761 25.0907 3.38674 23.8 2.09603C22.509 0.805056 20.7584 0.0800853 18.9329 0.0800853L18.9329 0.0800171Z" fill="currentColor"/>
//             </svg>
//           </div>
//           <h5>Required Documents</h5>   
//           <p>Our U.S. and Canadian immigration experts have on average 8+ years of experience in the industry. We are regulated by the CICC in Canada.</p>
//         </div>
//         <div className="box">
//           <div className="icon">
//             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="33" viewBox="0 0 26 33" fill="none">
//             <path d="M17.9698 3.96899H9.65694C9.55319 3.96872 9.45023 3.98953 9.35473 4.03034H9.33179C9.29338 4.04768 9.25604 4.06795 9.22083 4.09142L9.18269 4.12209C9.15575 4.1421 9.13041 4.16371 9.10614 4.18718L0.996052 12.7336L0.957909 12.7832L0.91203 12.8406H0.911764C0.897894 12.863 0.88509 12.8859 0.873621 12.9094C0.873621 12.9286 0.850682 12.9515 0.842946 12.9705C0.833878 12.9969 0.826142 13.0238 0.820007 13.0507V13.1118V13.1121C0.815206 13.1617 0.815206 13.2116 0.820007 13.2612V28.4462C0.820007 29.4603 1.22304 30.4331 1.94 31.1501C2.65725 31.8673 3.62976 32.2701 4.64391 32.2701H21.0293C21.232 32.2701 21.4268 32.1895 21.57 32.046C21.7135 31.9028 21.7941 31.7081 21.7941 31.5054V7.79717C21.7951 6.78227 21.3926 5.80867 20.6754 5.0906C19.9581 4.3728 18.9851 3.96924 17.9701 3.96924L17.9698 3.96899ZM8.88807 6.64565V12.485H3.33201L8.88807 6.64565ZM20.2642 30.7362H4.60871C4.00029 30.7362 3.41668 30.4946 2.98642 30.0643C2.55616 29.6341 2.31452 29.0504 2.31452 28.442V14.0143H9.65641C9.85913 14.0143 10.0538 13.9338 10.1971 13.7903C10.3406 13.647 10.4211 13.4523 10.4211 13.2496V5.49869H17.9692C18.5779 5.49869 19.1613 5.74035 19.5918 6.17059C20.0221 6.60083 20.2637 7.18447 20.2637 7.79288L20.2642 30.7362Z" fill="currentColor"/>
//             <path d="M18.9329 0.0800171H11.2393C10.8168 0.0800171 10.4746 0.422236 10.4746 0.844729C10.4746 1.26697 10.8168 1.60944 11.2393 1.60944H18.9329C20.3527 1.60944 21.7144 2.17358 22.7184 3.17758C23.7224 4.18158 24.2863 5.54328 24.2863 6.96311V28.7935C24.2863 29.2157 24.6287 29.5582 25.0512 29.5582C25.4735 29.5582 25.816 29.2157 25.816 28.7935V6.96311C25.816 5.13761 25.0907 3.38674 23.8 2.09603C22.509 0.805056 20.7584 0.0800853 18.9329 0.0800853L18.9329 0.0800171Z" fill="currentColor"/>
//             </svg>
//           </div>
//           <h5>Required Documents</h5>   
//           <p>Our U.S. and Canadian immigration experts have on average 8+ years of experience in the industry. We are regulated by the CICC in Canada.</p>
//         </div>
//         <div className="box">
//           <div className="icon">
//             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="33" viewBox="0 0 26 33" fill="none">
//             <path d="M17.9698 3.96899H9.65694C9.55319 3.96872 9.45023 3.98953 9.35473 4.03034H9.33179C9.29338 4.04768 9.25604 4.06795 9.22083 4.09142L9.18269 4.12209C9.15575 4.1421 9.13041 4.16371 9.10614 4.18718L0.996052 12.7336L0.957909 12.7832L0.91203 12.8406H0.911764C0.897894 12.863 0.88509 12.8859 0.873621 12.9094C0.873621 12.9286 0.850682 12.9515 0.842946 12.9705C0.833878 12.9969 0.826142 13.0238 0.820007 13.0507V13.1118V13.1121C0.815206 13.1617 0.815206 13.2116 0.820007 13.2612V28.4462C0.820007 29.4603 1.22304 30.4331 1.94 31.1501C2.65725 31.8673 3.62976 32.2701 4.64391 32.2701H21.0293C21.232 32.2701 21.4268 32.1895 21.57 32.046C21.7135 31.9028 21.7941 31.7081 21.7941 31.5054V7.79717C21.7951 6.78227 21.3926 5.80867 20.6754 5.0906C19.9581 4.3728 18.9851 3.96924 17.9701 3.96924L17.9698 3.96899ZM8.88807 6.64565V12.485H3.33201L8.88807 6.64565ZM20.2642 30.7362H4.60871C4.00029 30.7362 3.41668 30.4946 2.98642 30.0643C2.55616 29.6341 2.31452 29.0504 2.31452 28.442V14.0143H9.65641C9.85913 14.0143 10.0538 13.9338 10.1971 13.7903C10.3406 13.647 10.4211 13.4523 10.4211 13.2496V5.49869H17.9692C18.5779 5.49869 19.1613 5.74035 19.5918 6.17059C20.0221 6.60083 20.2637 7.18447 20.2637 7.79288L20.2642 30.7362Z" fill="currentColor"/>
//             <path d="M18.9329 0.0800171H11.2393C10.8168 0.0800171 10.4746 0.422236 10.4746 0.844729C10.4746 1.26697 10.8168 1.60944 11.2393 1.60944H18.9329C20.3527 1.60944 21.7144 2.17358 22.7184 3.17758C23.7224 4.18158 24.2863 5.54328 24.2863 6.96311V28.7935C24.2863 29.2157 24.6287 29.5582 25.0512 29.5582C25.4735 29.5582 25.816 29.2157 25.816 28.7935V6.96311C25.816 5.13761 25.0907 3.38674 23.8 2.09603C22.509 0.805056 20.7584 0.0800853 18.9329 0.0800853L18.9329 0.0800171Z" fill="currentColor"/>
//             </svg>
//           </div>
//           <h5>Required Documents</h5>   
//           <p>Our U.S. and Canadian immigration experts have on average 8+ years of experience in the industry. We are regulated by the CICC in Canada.</p>
//         </div>
//       </div>
//     </div>
//   )
// }

const RequiredDocs = ({plan}) => {
  return (
    <div className="RequiredDocs">
      <h3>Required Documents</h3>
      <h4>You’ll need the following documents if you decide to apply to this visa.</h4>
      <ul>
        {plan.documents.length > 0 ? plan.documents.map(doc => {
          return <li key={doc.id}>{doc.name}</li>
        }) : <li>No documents required</li> }
      </ul>
    </div>
  )
}

export default Plan
