import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import Bread from "../../components/Bread/Bread";
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import "./Plans.scss"
import {  Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from "axios";



const Plans = () => {

  const {id} = useParams()

  const links = [
    {
        name: "Dashboard",
        to: "/",
    },
    {
        name: "plans",
        to: "/plans",
    },
    {
        name: "Check Eligibility",
        to: "/check-eligibility",
    },
    {
      name: "Questions",
      to: "/check-eligibility/" + id,
    },
    {
      name: "Filtered Plans",
      to: "#",
    },
  ];
  
  console.log()
  
  // API 
  const [plans, setPlans] = useState([])

  
  const token = localStorage.getItem("gic_token");
  const url = `https://bksys.getincanada.ca/api/valid-programs`;

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => {
    axios.post(url , {Ids : JSON.parse(localStorage.getItem("gic_ids")) , case_id : id})
    .then((response) => {
      console.log(response.data)
      setPlans(response.data.data.Programs)
    }).catch((error) => console.log(error));
  } , [url , id])
  
  // cases
  const [cases, setCases] = useState([])
  const url2 = "https://bksys.getincanada.ca/api/admin/cases";

  
  useEffect(() => {
    axios.get(url2).then((response) => {
      setCases(response.data.data)
    });
  } , [])

  
  return (
    <>
      <Navbar />
      <div style={{display : "flex" , position : "relative"}}>
        <Sidebar />
        <div className='Plans main-section'>
          <Bread links={links}/>
          <EligibleVisa plansNum={plans?.length} />
          <div className="plansContainer">
            {plans?.length > 0 && plans.map((plan , i) => {
              return <Plan plan={plan} cases={cases} key={i} />
            })}
          </div>
          <Redo />
          <Visas />
        </div>
        <CopyWriting />
      </div>
    </>
  )
}


const EligibleVisa = ({plansNum}) => {

  
  return (
    <Stack bgcolor={"white"} borderRadius={"8px"} p={8} pb={12} mb={8} >
      <Typography
      sx={{
        fontSize : "18px",
        fontWeight : "500",
        pb : 6,
        mb : 6,
        borderBottom : "1px solid rgba(214, 214, 214, 0.50)"
      }}
      >
      Eligible visa
      </Typography>
      <Typography
      sx={{
        color : "#5F5F5F"
      }}
      >
      based on clients answer, we found <span style={{
        color : "#124367",
        fontWeight : "600",
        fontSize : "18px"
      }} > {plansNum ? plansNum : 0} study</span> visas you may be eligible for
      </Typography>
    </Stack>
  )
}

const Plan = ({plan , cases}) => {

  
  return (
    <Stack className="Plan" border="1px solid rgba(203, 207, 210, 0.4)" borderRadius="8px" bgcolor="white"  
    >
      <Stack bgcolor={"#d2000012"} direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={6} mb={4}>
        <h4>{plan.title}</h4>
      </Stack>
      <Stack p={6} pb={8} pt={0}>
        <h3>case</h3>
        <div className="rects">
          
          { cases && cases.map((item , i) => {
            return (
              <div key={i} className={item.id === plan.case.id ? "active rect" : "rect" }>{item.name}</div>
            )
          }) }
          
        </div>
        <h3>Description</h3>
        <p>{plan.pref}</p>
        <Link to={"/plans/" + plan.id} ><Button variant="outlined" sx={{py : 5 , px : 8 , borderRadius : "8px" , fontWeight : "400" , fontSize : "14px" , lineHeight : "21px"}} endIcon={<ArrowForwardIcon />} >view more details</Button></Link>
      </Stack>
    </Stack>
  )
}

const Redo = () => {
  return (
    <Stack bgcolor={"white"} borderRadius={"8px"} py={16} textAlign={"center"} alignItems={"center"} mb={12} >
      <Typography
      sx={{
        fontSize : "20px",
        fontWeight : 500,
        mb : 20
      }}
      >
        not finding what you’re looking for
      </Typography>
      <Link to={"/check-eligibility"} >
        <Button variant="outlined" color="primary"
          sx={{
            fontSize : "18px",
            fontWeight : "500",
            p : "15px 80px",
            borderRadius : "8px",
          }}
        >
          redo eligibility check
        </Button>
      </Link>
    </Stack>
  )
}

const Visas = () => {
  return (
    <Stack bgcolor={"white"} borderRadius={"8px"} py={16} textAlign={"center"} alignItems={"center"} mb={12} >
      <Typography
      sx={{
        fontSize : "20px",
        fontWeight : 500,
        mb : 4
      }}
      >
        see our visas
      </Typography>
      <Typography
      sx={{
        color : "#909090",
        mb : 20
      }}
      >
        we have more than 150 visas
      </Typography>
      <Link to={"/plans"} >
        <Button variant="contained" color="secondary"
          sx={{
            fontSize : "18px",
            fontWeight : "500",
            p : "15px 80px",
            borderRadius : "8px",
          }}
        >
          view
        </Button>
      </Link>
    </Stack>
  )
}

export default Plans