import { Button, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import PopUp from "../components/PopUp/PopUp";
import axios from "axios";
import { useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import Bread from "../components/Bread/Bread";
import CopyWriting from "../components/CopyWriting/CopyWriting";
// Redux 
import { GET_ANSWERS , ADD_ANSWER , EDIT_ANSWER , DEL_ANSWER } from '../store/answerSlice';
import { useDispatch , useSelector } from 'react-redux';
import MSAlert from "../components/MSAlert";


const Container = () => {

  const links = [
    {
      name : "Dashboard",
      to : "/ "
    },
    {
      name : "Settings",
      to : "/settings"
    },
    {
      name : "Questions",
      to : "/settings/questions"
    },
    {
      name : "Answers",
      to : "#"
    },
  ]

  const [addAns, setAddAns] = useState(false)

  const [open, setOpen] = useState(false)


  
  return (
    <>
      <Navbar />
      <div style={{display : "flex"}}>
        <Sidebar />
        <div className='Settings main-section' data-aos="fade-down">

          <Bread links={links} />
          
          <Answers  setOpen={setOpen} />
          {/* Add Questions */}
          <Stack alignItems={"flex-end"} mb={15} >
            <Button variant='contained' sx={{p : "15px 40px"}}
            onClick={() => setAddAns(true)}
            >
              Add Answer
            </Button>
          </Stack>
          {addAns && <AddAnswer setAddAns={setAddAns} setOpen={setOpen} />}
          <CopyWriting />
        </div>
        <MSAlert setOpen={setOpen} open={open} type={"success"} message={"Done"} />
      </div>
    </>
  )
}

const Answers = ({setOpen}) => {


  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/admin/proAnswers";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });

  const answers = useSelector(state => state.answers.answers)
  const dispatch = useDispatch()
  
  
  // Get Answers
  const { id } = useParams()
  
  useEffect(() => {
    axios.get(url , {
      headers : {
        Authorization : "Bearer" + localStorage.getItem("gic_token")
      }
    }).then((response) => {

      dispatch(GET_ANSWERS(response.data.data.answers.filter(answer => +answer.question_id === +id).reverse()))

    }).catch((error) => console.log(error));
  } , [url , id])

  // Delete answer
  
  const [delConfirm, setdelConfirm] = useState(false)
  const [delId, setdelId] = useState(null)


  const deleteConfirm = {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="101" viewBox="0 0 79 101" fill="none">
        <path d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z" fill="#E7511D"/>
      </svg>
      ),
      message: `are you sure you want to delete this answer ?`,
      button1: (
            <Button
                color="primary"
                variant="outlined"
                sx={{
                    width: "250px",
                    py: 8,
                    px: { xs: 20, sm: 40 },
                    borderRadius: "8px",
                }}
                onClick={() => deleteQuestion()}
            >
                yes
            </Button>
      ),
      button2: (
          <Button
              color="secondary"
              onClick={() => setdelConfirm(false)}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              no
          </Button>
      ),
  };
  
  const deleteQuestion = () => {

    
    axios.delete(url + "/" + delId).then((response) => {
      if(response.data.code === 200) {

        dispatch(DEL_ANSWER(delId))
        setOpen(true)
        setdelConfirm(false)

      }
    });
  }
  

  // Edit Answer 
  const [edit, setEdit] = useState(false)
  const [ansId, setAnsId] = useState(null)



return (
  <>
    <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} mb={12}  >
      <h2 className="main-title">Questions</h2>
      <hr />
      <Stack maxHeight={"370px"} overflow={"hidden"} sx={{overflowY : "scroll"}} spacing={12} >
        {answers.length > 0 ? answers?.map(answer => {
          return (
            <Stack key={answer.id} width={"90%"} direction={"row"} justifyContent={"space-between"} p={"20px 10px"} borderRadius={"8px"} alignItems={"center"} bgcolor={"#EEE"}
              sx={{
                transition : ".2s",
                "&:hover" : {
                  borderLeft : "3px solid red",
                }
              }}>
                  <Typography sx={{fontWeight : "500" , fontSize : "18px" , color : "#5F5F5F"}} >{answer.text}</Typography>
                  
                  <Stack direction={"row"} spacing={4} >
                    <DeleteIcon fontSize='small' 
                      onClick={() => {
                        setdelId(answer.id)
                        setdelConfirm(true)
                      }}
                      sx={{
                        color : "primary.main",
                        cursor : "pointer",
                        "&:hover" : {
                          color : "secondary.main"
                        }
                        }} 
                      />
                    <EditIcon fontSize='small' 
                      onClick = {() => {
                        setAnsId(answer.id)
                        setEdit(true)
                      }}
                      sx={{
                        color : "primary.main",
                        cursor : "pointer",
                        "&:hover" : {
                          color : "secondary.main"
                        }
                        }} 
                      />
                  </Stack>

            </Stack>
          )
        }) : <Typography> No Answers </Typography> }

      </Stack>
    </Stack>
    {delConfirm && <PopUp pop={deleteConfirm} setActive={setdelConfirm} />}
    {edit && <Answer quesId={ansId} questions={answers} setEdit={setEdit} setOpen={setOpen} />}
  </> 
)
}

const Answer = ({quesId , questions , setEdit , setOpen}) => {
    
  const dispatch = useDispatch()

  // Get Questions
  const [question, setQuestion] = useState([])

  const [index, setIndex] = useState(null)

  useEffect(() => {
    setIndex(questions.findIndex((q) => q.id === quesId ))
    setQuestion(questions[index])
  } , [questions , quesId , index] )

  // New Question Data
  const [newQues, setNewQues] = useState("")

  useEffect(() => {
    setNewQues(question?.text)
  } , [question])

  const url = "https://bksys.getincanada.ca/api/admin/proAnswers"
  // Edit Question
  const EditQues = () => {
    axios.put(url + "/" + quesId , { text : newQues }).then((response) => {
      if(response.status === 201){
        dispatch(EDIT_ANSWER(response.data.data.answer))
        setEdit(false)
        setOpen(true)
      }
    });
  }

  return (
    <>
      <Stack position={"fixed"} left={"50%"} top={"50%"} bgcolor={"white"} padding={"30px"} borderRadius={"12px"}
      boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"} spacing={8} maxHeight={"400px"} borderLeft={"4px solid #D20000"}
      zIndex={1000}
      sx={{
        translate : "-50% -50%",
      }}>

      <CloseIcon fontSize='small' 
        onClick={() => setEdit(false)}
        sx={{
          position : "absolute",
          right : "10px",
          top : "10px",
          "&:hover" : {
            color : "secondary.main",
            cursor : "pointer"
          }
      }} />
        
        <TextField id="outlined-basic" label={"New Answer"} variant="outlined" sx={{width : "600px"}} value={newQues} onChange={(e) => setNewQues(e.target.value)} />

        <Stack alignItems={"flex-end"} direction={"row"} spacing={8}>
          <Button variant='contained' sx={{p : "15px 40px"}}
          disabled={question?.text === newQues ? true : false}
          onClick={() => EditQues()}
          >
            Save 
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

const AddAnswer = ({setAddAns , setOpen}) => {

  const dispatch = useDispatch()

  const { id } = useParams()
  
  // Add Question
  const [question, setQuestion] = useState("")

  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/admin/proAnswers";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  const handleAdd = () => {
      axios.post(url , {text : question , question_id : id }).then((response) => {
        dispatch(ADD_ANSWER(response.data.data.answer))
        setAddAns(false)
        setOpen(true)
      });
  }

  
  return (
    <Stack position={"fixed"} left={"50%"} top={"50%"} bgcolor={"white"} padding={"30px"} borderRadius={"12px"}
    boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"} spacing={8} maxHeight={"400px"} borderLeft={"4px solid #D20000"}
    sx={{
      translate : "-50% -50%",
    }}>

      <CloseIcon fontSize='small' 
      onClick={() => setAddAns(false)}
      sx={{
        position : "absolute",
        right : "10px",
        top : "10px",
        "&:hover" : {
          color : "secondary.main",
          cursor : "pointer"
        }
      }} />

      <TextField id="outlined-basic" label="Answer title" variant="outlined" sx={{width : "600px"}} value={question} 
      onChange={(e) => setQuestion(e.target.value) } />

      <Stack alignItems={"flex-end"} direction={"row"} spacing={8} >

        <Button variant='contained' sx={{p : "15px 40px"}}
        disabled={question === "" ? true : false}
        onClick={() => handleAdd()}
        >
          Add 
        </Button>

      </Stack>
    </Stack>
  )
}

export default Container