import "./Settings.scss"

import CopyWriting from "../../components/CopyWriting/CopyWriting";

import Bread from "../../components/Bread/Bread";

import Navbar from '../../components/Navbar/Navbar';

import Sidebar from '../../components/Sidebar/Sidebar';
import { Button, Stack, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import PopUp from "../../components/PopUp/PopUp";
import axios from "axios";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Link } from "react-router-dom";


const Settings = () => {

  const links = [
    {
      name : "Dashboard",
      to : "/ "
    },
    {
      name : "Settings",
      to : "/settings"
    },
  ]
  
  return (
    <>
      <Navbar />
      <div style={{display : "flex"}}>
        <Sidebar />
        <div className='Settings main-section' data-aos="fade-down">

          <Bread links={links} />

          <Docs />
          <Cases />
          <Stack alignItems={"center"} p={12} >
            <Link to={"questions"}>
              <Button 
                variant="contained"
                sx={{
                  p : "10px 40px"
                }}
                endIcon={ <DoubleArrowIcon fontSize="small" /> }
              >
                Questions
              </Button>
            </Link>
          </Stack>

          <CopyWriting />
        </div>
      </div>
    </>
  )
}


const Docs = () => {

  // Get Documents
  const [docs, setDocs] = useState([])
    
  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/admin/documents";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => {
    axios.get(url).then((response) => {
      setDocs(response.data.data)
    });
  } , [url])

  // Delete Document
  const [delConfirm, setdelConfirm] = useState(false)
  const [delId, setdelId] = useState(null)
  
  const deleteConfirm = {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="79" height="101" viewBox="0 0 79 101" fill="none">
      <path d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z" fill="#E7511D"/>
    </svg>
    ),
    message: `are you sure you want to delete this doc ?`,
    button1: (
          <Button
              color="primary"
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
              onClick={() => deleteDoc()}
          >
              yes
          </Button>
    ),
    button2: (
        <Button
            color="secondary"
            onClick={() => setdelConfirm(false)}
            variant="outlined"
            sx={{
                width: "250px",
                py: 8,
                px: { xs: 20, sm: 40 },
                borderRadius: "8px",
            }}
        >
            no
        </Button>
    ),
  };

  const deleteDoc = () => {

    let url2 = "https://bksys.getincanada.ca/api/admin/documents/" + delId

    axios.delete(url2).then((response) => {
      response.status === 200 && window.location.reload()
    });
  }

  // Add Document
  const [doc, setDoc] = useState("")
  
  const addDoc = () => {
    axios.post(url , {name : doc}).then((response) => {
      response.status === 201 &&
      setDocs(prev => [...prev , response.data.data.document])
      setDoc("")
    });
  }
  
  
  return (
    <>
    <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} mb={12} >

      <h2 className="main-title">required documents</h2>
      <hr />

      <Stack direction={"row"} spacing={8} alignItems={"center"} justifyContent={"space-between"} mb={16} >
        <TextField
          label="document name"
          fullWidth
          value={doc}
          onChange={(e) => setDoc(e.target.value)}
        />
        <Button variant="contained"
        disabled={doc === "" && true}
        onClick={() => {
          doc !== "" && addDoc()
        }}
        sx={{
          p : "16px 50px"
        }} >Add</Button>
      </Stack>

      { docs && 
      <div className="items">

        {docs.map(doc => {
          return (
            <Stack className="item" key={doc.id} direction={"row"} p={"12px 8px"} justifyContent={"space-between"} alignItems={"center"} border={"1px solid #CBCFD2"} borderRadius={"8px"} spacing={24} >
              <Typography sx={{fontWeight : "500"}}>{doc.name}</Typography>
              <CloseIcon fontSize="small" 
              onClick={() => {
                setdelConfirm(true)
                setdelId(doc.id)
              }}
              sx={{
                cursor : "pointer",
                "&:hover" : {
                  color : "secondary.main"
                }
              }} />
            </Stack>
          )
        })}

      </div>
      }

    </Stack>
    {delConfirm && <PopUp pop={deleteConfirm} setActive={setdelConfirm} />}
    </>
  )
}


const Cases = () => {

  // Get Documents
  const [docs, setDocs] = useState([])
    
  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/admin/cases";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => {
    axios.get(url).then((response) => {
      setDocs(response.data.data)
    });
  } , [url])

  // Delete Document
  const [delConfirm, setdelConfirm] = useState(false)
  const [delId, setdelId] = useState(null)
  
  const deleteConfirm = {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="79" height="101" viewBox="0 0 79 101" fill="none">
      <path d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z" fill="#E7511D"/>
    </svg>
    ),
    message: `are you sure you want to delete this case ?`,
    button1: (
          <Button
              color="primary"
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
              onClick={() => deleteDoc()}
          >
              yes
          </Button>
    ),
    button2: (
        <Button
            color="secondary"
            onClick={() => setdelConfirm(false)}
            variant="outlined"
            sx={{
                width: "250px",
                py: 8,
                px: { xs: 20, sm: 40 },
                borderRadius: "8px",
            }}
        >
            no
        </Button>
    ),
  };

  const deleteDoc = () => {

    let url2 = "https://bksys.getincanada.ca/api/admin/cases/" + delId

    axios.delete(url2).then((response) => {
      response.status === 200 && window.location.reload()
    });
  }

  // Add Document
  const [doc, setDoc] = useState("")
  
  const addDoc = () => {
    axios.post(url , {name : doc}).then((response) => {
      response.status === 201 &&
      setDocs(prev => [...prev , response.data.data.case])
      setDoc("")
    });
  }
  
  
  return (
    <>
    <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} >

      <h2 className="main-title">Cases</h2>
      <hr />

      <Stack direction={"row"} spacing={8} alignItems={"center"} justifyContent={"space-between"} mb={16} >
        <TextField
          label="Case Name"
          fullWidth
          value={doc}
          onChange={(e) => setDoc(e.target.value)}
        />
        <Button variant="contained"
        disabled={doc === "" && true}
        onClick={() => {
          doc !== "" && addDoc()
        }}
        sx={{
          p : "16px 50px"
        }} >Add</Button>
      </Stack>

      { docs && 
      <div className="items">

        {docs.map(doc => {
          return (
            <Stack className="item" key={doc.id} direction={"row"} p={"12px 8px"} justifyContent={"space-between"} alignItems={"center"} border={"1px solid #CBCFD2"} borderRadius={"8px"} spacing={24} >
              <Typography sx={{fontWeight : "500"}}>{doc.name}</Typography>
              <CloseIcon fontSize="small" 
              onClick={() => {
                setdelConfirm(true)
                setdelId(doc.id)
              }}
              sx={{
                cursor : "pointer",
                "&:hover" : {
                  color : "secondary.main"
                }
              }} />
            </Stack>
          )
        })}

      </div>
      }

    </Stack>
    {delConfirm && <PopUp pop={deleteConfirm} setActive={setdelConfirm} />}
    </>
  )
}


export default Settings
