import { Button, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Bread from "../../components/Bread/Bread";
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./CheckE.scss";
import { useEffect, useState } from "react";
import EastIcon from '@mui/icons-material/East';
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CheckE = () => {

    const links = [
        {
            name: "Dashboard",
            to: "/",
        },
        {
            name: "plans",
            to: "/plans",
        },
        {
            name: "Check Eligibility",
            to: "/check-eligibility",
        },
    ];

    return (
      <>
        <Navbar />
        <div style={{display : "flex"}}>
            <Sidebar />
            <div className='CheckE main-section' data-aos="fade-down" >

              <Bread links={links} />

              <Stack p={"32px 80px 20px"} bgcolor={"white"} borderRadius={"8px"} alignItems={"center"} >
                <h3>Check Eligibility</h3>
                <Question />
              </Stack>


              <CopyWriting />
              
            </div>
        </div>
    </>
    );
};


const Question = () => {


    const navigate = useNavigate()
  
    
    const [alignment, setAlignment] = useState(null);

    const [cases, setCases] = useState(null)
  
    const token = localStorage.getItem("gic_token");
    const url = "https://bksys.getincanada.ca/api/admin/cases";
  
    axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;
  
        return config;
    });
    
    useEffect(() => {
      axios.get(url).then((response) => {
        console.log(response.data.data)
        setCases(response.data.data)
      });
    } , [])

    const [selectedCase, setSelectedCase] = useState(null)

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
        console.log(event.target.value)
        setSelectedCase(event.target.value)
    };

    return (
        <div className="Question">

            <p>What Case Do You Want To Choose ?</p>

            <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                sx={{
                  display : "flex",
                  justifyContent : "center",
                  mb : 16
                }}
            >
              {
                cases?.map( item => {
                  return (
                    <ToggleButton
                    key={item.id}
                    variant="outlined"
                    value={item.id}
                    sx={{
                        border: "1px solid #CBCFD2",
                        color: "#5F5F5F",
                        py: 8,
                        px: 50,
                    }}
                  >
                      {item.name}
                  </ToggleButton>
                  )
                })
              }
            </ToggleButtonGroup>

            <Stack alignItems={"center"}>
              <Button 
                color="secondary" 
                variant="contained" 
                endIcon={<EastIcon fontSize="small" />} 
                sx={{px : 60 , py :8}}
                disabled={ selectedCase === null ? true : false }
                onClick={() => selectedCase !== null && navigate(selectedCase)}

              >
                  Next
              </Button> 
            </Stack>

        </div>
    );
};

export default CheckE;
