import '../ClientsList/ClientsList.scss'
import Bread from "../../components/Bread/Bread"
import { Box, Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CopyWriting from "../../components/CopyWriting/CopyWriting"
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import PersonIcon from '@mui/icons-material/Person';
import PopUp from "../../components/PopUp/PopUp";
import MSAlert from "../../components/MSAlert";
// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { DEL_QUESTION, GET_QUESTIONS } from "../../store/questionSlice"






const ClientsList = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  } , [])

  const navigate = useNavigate()

  const links = [
    {
      name : "Dashboard",
      to : "/"
    },
    {
      name : "lawyers list",
      to : "#"
    }
  ]
  
  // Search
  const [search, setSearch] = useState("")
  
  return (
    
    <>
      <Navbar />
      <div style={{display : "flex"}}>
          <Sidebar />
          <div className='ClientsList main-section' data-aos="fade-down" >

            <Bread links={links} />

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={8}>
              <h2>lawyers list</h2>
              <Button variant='contained' color='secondary' 
              onClick={() => navigate("add-lawyer")}
              startIcon = {<AddIcon sx={{fontSize : "25px"}} />} 
              sx={{
                p : {xs : "5px 20px" , sm : "11px 40px"},
                fontSize : {xs : "14px" , sm : "18px"},
                fontWeight : "500"
              }} >add new lawyer</Button>
            </Stack>

            <ActionsBar search={search}  setSearch={setSearch} />

            <ClientsTable search={search} />

            <CopyWriting />
            
          </div>
      </div>
    </>
  )
}


const ActionsBar = ({setSearch , search}) => {
  return (
    <Stack spacing={8} direction={{xs : "column" , sm : "row"}} mb={8} className='ActionsBar'>

      <div className="input-icon search">
        <input type="search" placeholder='Search' value={search} onChange={(e) => setSearch(e.target.value)} />
        <div className="icon"><SearchIcon /></div>
      </div>

    </Stack>
  )
}


const ClientsTable = ({search}) => {

  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()
  const lawyers = useSelector(state => state.questions.questions)

  // API
  const [filteredLawyers, setFilteredLawyers] = useState([])

  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/admin/lawyers";

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => {
    axios.get(url).then((response) => {
      if(response.data.code === 200) {
        dispatch(GET_QUESTIONS(response.data.data.lawyers))
        console.log(response.data.data.lawyers)
        console.log("done")
      }
    });
  } , [dispatch])
  
  // Filter lawyers

  useEffect(() => {
  
    if(search.length > 0 && search.charCodeAt(0) !== 32 ) {
      const newData = lawyers && 
      lawyers.filter( lawyer => {
        const name = lawyer.first_name.toLowerCase() + " " + lawyer.last_name.toLowerCase()

        if(name.includes(search.toLowerCase())){
          return lawyer
        }
      })
      setFilteredLawyers(newData)
    } else {
      setFilteredLawyers(lawyers)
    }

  } , [search , lawyers])
  

  // Delete Client
  const [delConfirm, setdelConfirm] = useState(false)
  const [delId, setdelId] = useState(null)

  const deleteClient = () => {

    const url2 = `https://bksys.getincanada.ca/api/admin/lawyers/${delId}`

      axios.delete(url2).then((response) => {

        dispatch(DEL_QUESTION(delId))
        setdelConfirm(false)
        setOpen(true)

      }).catch(err => console.log(err))


  }
  
  const deleteConfirm = {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="101" viewBox="0 0 79 101" fill="none">
        <path d="M61.7615 33.8883V89.4439H17.3171V33.8883H61.7615ZM53.4282 0.554993H25.6504L20.0948 6.11055H0.650391V17.2217H78.4282V6.11055H58.9837L53.4282 0.554993ZM72.8726 22.7772H6.20595V89.4439C6.20595 95.555 11.2059 100.555 17.3171 100.555H61.7615C67.8726 100.555 72.8726 95.555 72.8726 89.4439V22.7772Z" fill="#E7511D"/>
      </svg>
      ),
      message: `are you sure you want to delete this lawyer ?`,
      button1: (
            <Button
                color="primary"
                variant="outlined"
                sx={{
                    width: "250px",
                    py: 8,
                    px: { xs: 20, sm: 40 },
                    borderRadius: "8px",
                }}
                onClick={() => deleteClient()}
            >
                yes
            </Button>
      ),
      button2: (
          <Button
              color="secondary"
              onClick={() => setdelConfirm(false)}
              variant="outlined"
              sx={{
                  width: "250px",
                  py: 8,
                  px: { xs: 20, sm: 40 },
                  borderRadius: "8px",
              }}
          >
              no
          </Button>
      ),
  };

  function createData(NO , name, phoneNumber , emailAddress, Action) {
    return { NO , name, phoneNumber , emailAddress, Action};
  }
  
  const rows = [];

  filteredLawyers && filteredLawyers.forEach((lawyer , i) => {
    rows.push(
      createData(
        i + 1, 
        `${lawyer.first_name} ${lawyer.last_name}` , 
        lawyer.phone,
        lawyer.email,
        <Stack spacing={4} direction={"row"} >
          <DeleteOutlineIcon onClick={() => {
            setdelConfirm(true)
            setdelId(lawyer.id)
          }}
          sx={{cursor : "pointer" , "&:hover" : {color :"secondary.main"} , color : "#BFBFBF"}} />
          <Link to={"/lawyers-list/" + lawyer.id} >
            <PersonIcon sx={{cursor : "pointer" , "&:hover" : {color :"primary.main"} , color : "#BFBFBF"}} />
          </Link>
          <Link to={"edit-lawyer/" + lawyer.id} >
            <EditIcon sx={{cursor : "pointer" , "&:hover" : {color :"primary.main"} , color : "#BFBFBF"}} />
          </Link>
        </Stack>
      ),
    )
  })
  
  return (
    <div>
      <TableContainer component={Paper} className='table'>
        <Box sx={{ overflow: "auto" }}>
          <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align='left'>NO.</TableCell>
                  <TableCell align='left'>Client name</TableCell>
                  <TableCell align='left'>phone number</TableCell>
                  <TableCell align='left'>email address</TableCell>
                  <TableCell align='left'>action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.NO}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" align='left'>
                      {row.NO}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.phoneNumber}</TableCell>
                    <TableCell align="left">{row.emailAddress}</TableCell>
                    <TableCell align="left">{row.Action}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </TableContainer>
      {delConfirm && <PopUp pop={deleteConfirm} setActive={setdelConfirm} />}
      <MSAlert open={open} setOpen={setOpen} message={"deleted successfully"} type={"success"} />
    </div>
  )
}



export default ClientsList
