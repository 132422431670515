import { Stack } from '@mui/material';
import './E_Profile.scss'
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import Bread from "../../components/Bread/Bread";
import { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import profile from "../../images/profile.png"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const E_Profile = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  } , [])

  const links = [
    {
      name : "Dashboard",
      to : "/"
    },
    {
      name : "clients list",
      to : "/clients-list"
    },
    {
      name : "employee profile",
      to : "#"
    },
  ]
  
  return (
    <>
      <Navbar />
      <div style={{display : "flex"}}>
        <Sidebar />
        <div className='Profile main-section' data-aos="fade-down">
          <Bread links={links} />
          <ProfileData />
          <ProfilePI />
          {/* <ProfileED />
          <ProfileOD /> */}
          <CopyWriting />
        </div>
      </div>
    </>
  )
}

const ProfileData = () => {
  
  return (
    <Stack p={8} bgcolor="white" borderRadius="8px" direction="row" justifyContent="space-between" mb={8} alignItems={{xs : "flex-end" , sm : "flex-start"}} className='ProfileData' position={"relative"} >
      <EditOutlinedIcon sx={{
          position :"absolute",
          top: "16px",
          right: "16px",
          color : "#424242",
          cursor : "pointer",
          "&:hover" : {
            color : "secondary.main"
          }
        }} />
      <Stack direction="row" spacing={{xs : 4 , sm : 8}} alignItems="center">

        <div className="profile-img"><img src={profile} alt="" /></div>

        <Stack spacing={2}>
          <h5>Sendora Ali</h5>
          <h6>lawyer</h6>
        </Stack>

      </Stack>
    </Stack>
  )
}

const ProfilePI = () => {

  return (
    <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
      <h2>employee information</h2>
      <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>
        <Stack direction="row" flex={1} spacing={32}>
          <Stack className='keys' spacing={8}>
            <li>Name</li>
            <li>sex</li>
            <li>Date of Birth</li>
            <li>phone</li>
          </Stack>
          <Stack className='values' spacing={8}>
            <li>Mohamed ali Mohamed ali</li>
            <li>Male</li>
            <li>20-12-2022</li>
            <li>0123456789</li>
          </Stack>
        </Stack>
        <Stack direction="row" flex={1} spacing={32} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
          <Stack className='keys' spacing={8}>
            <li>email address</li>
            <li>Marital Status</li>
            <li>country</li>
            <li>city</li>
          </Stack>
          <Stack className='values' spacing={8}>
            <li>Mohamed@Gmail.com</li>
            <li>Active</li>
            <li>egypt</li>
            <li>Cairo</li>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

// const ProfileED = () => {

//   return (
//     <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
//       <h2>Education</h2>
//       <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>
//         <Stack direction="row" flex={1} spacing={32}>
//           <Stack className='keys' spacing={8}>
//             <li>Name of education</li>
//             <li>country</li>
//             <li>from (date)</li>
//           </Stack>
//           <Stack className='values' spacing={8}>
//             <li>education name</li>
//             <li>country name</li>
//             <li>20-12-2022</li>
//           </Stack>
//         </Stack>
//         <Stack direction="row" flex={1} spacing={32} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
//           <Stack className='keys' spacing={8}>
//             <li>Name of education</li>
//             <li>country</li>
//             <li>from (date)</li>
//           </Stack>
//           <Stack className='values' spacing={8}>
//             <li>education name</li>
//             <li>country name</li>
//             <li>20-12-2022</li>
//           </Stack>
//         </Stack>
//       </Stack>
//     </Stack>
//   )
// }

// const ProfileOD = () => {

//   return (
//     <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
//       <h2>other details</h2>
//       <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>
//         <Stack direction="row" flex={1} spacing={32}>
//           <Stack className='keys' spacing={8}>
//             <li>Name of education</li>
//             <li>country</li>
//             <li>from (date)</li>
//           </Stack>
//           <Stack className='values' spacing={8}>
//             <li>education name</li>
//             <li>country name</li>
//             <li>20-12-2022</li>
//           </Stack>
//         </Stack>
//         <Stack direction="row" flex={1} spacing={32} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
//           <Stack className='keys' spacing={8}>
//             <li>Name of education</li>
//             <li>country</li>
//             <li>from (date)</li>
//           </Stack>
//           <Stack className='values' spacing={8}>
//             <li>education name</li>
//             <li>country name</li>
//             <li>20-12-2022</li>
//           </Stack>
//         </Stack>
//       </Stack>
//     </Stack>
//   )
// }

export default E_Profile
