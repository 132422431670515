// Images
import lab from "../../images/login-labtop.png"
import logo from "../../icons/login-logo.png"


// imports
import "./Login.scss";
import { Button, CircularProgress, TextField } from "@mui/material";
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { useEffect, useState } from "react";


const Login = () => {

  // is Logged in ?
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  useEffect(() => {
    isAuthenticated() && navigate("/clients-list")
  } , [isAuthenticated , navigate])

  const { register, handleSubmit, formState: { errors } } = useForm();

  // Signing in

  const [invalid, setInvalid] = useState("")
  const [loading , setLoading] = useState(false)

  const signIn = useSignIn()

  const onSubmit = (data) => {
    setLoading(true)
    axios.post("https://bksys.getincanada.ca/api/login" , data)
    .then(res => {
      console.log(res.data)
      if(res.data.code === 200) {
        signIn({
          token : res.data.data.token,
          expiresIn: 3600,
          tokenType : "Bearer",
          authState : {email : res.data.data.lawyer.email}
        })  
        setInvalid("")
        localStorage.setItem("gic_role" , res.data.data.lawyer.role)
        localStorage.setItem("gic_token" , res.data.data.token)
        localStorage.setItem("gic_id" , res.data.data.lawyer.id)
        localStorage.setItem("gic_name" , res.data.data.lawyer.first_name + " " + res.data.data.lawyer.last_name )
        navigate("/calculator")
      }
    })
    .catch(err => {
      setInvalid(err.response.data.message)
      setLoading(false)
    })
  }


  
  
  return (
    <div className='Login'>

      <div className="image">
        <img src={lab} alt="" data-aos="zoom-in" />
      </div>

      <div className="content">
        
        <img src={logo} alt="" data-aos="zoom-in" />

        <h5 data-aos="fade-up" >hi, welcome to get in canada !</h5>

        <h6 data-aos="fade-up" data-aos-delay="500">sign in..</h6>

        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="input">
            <TextField fullWidth type="email" label="Email" variant="outlined" data-aos="fade-up" data-aos-delay="1000" sx={{
              mb : 12
            }} {...register("email" , {required : true , pattern : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i } )}
            onChange={() => setInvalid("")}
            />

            {errors.email?.type === "required" && <p className="error">Email is Requierd</p>}
            {errors.email?.type === "pattern" && <p className="error">Please Enter a valid Email</p>}
          </div>

          <div className="input">
            <TextField fullWidth type="password" label="Password" variant="outlined" data-aos="fade-up" data-aos-delay="1200" sx={{
              mb : 20
            }} {...register("password" , { required : true , minLength : 8 , maxLength : 16 })} 
            onChange={() => setInvalid("")}
            />

            {errors.password?.type === "required" && <p className="error">password is Requierd</p>}
            {errors.password?.type === "minLength" && <p className="error">password minimum characters is 8</p>}
            {errors.password?.type === "maxLength" && <p className="error">password maximum characters is 16</p>}
          </div>

          <Button color="secondary" type="submit" variant="contained" data-aos="fade-up" data-aos-delay="1400" sx={{
            py : "15px",
            minHeight : "54.5px",
          }}
          > 

            {loading ? <CircularProgress size={20} /> : "sign in" }
            
          </Button>

          {invalid !== "" && <p style={{color : "red" , marginTop : "24px" , fontSize : "16px" , fontWeight : "500"}} >{invalid}</p>}

        </form>
        
        <CopyWriting />
        
      </div>

    </div>
  )
}

export default Login
