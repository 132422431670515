import { Stack, Tooltip } from '@mui/material';
import './Profile.scss'
import CopyWriting from "../../components/CopyWriting/CopyWriting";
import Bread from "../../components/Bread/Bread";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Profile = () => {

  useEffect(()=>{
    window.scrollTo(0, 0);
  } , [])

  const links = [
    {
      name : "Dashboard",
      to : "/"
    },
    {
      name : "clients list",
      to : "/clients-list"
    },
    {
      name : "client profile",
      to : "/profile"
    },
  ]

  const { id } = useParams()

  const [client, setClient] = useState({})
  
  const token = localStorage.getItem("gic_token");
  const url = "https://bksys.getincanada.ca/api/clients/" + id;

  axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
  });
  
  useEffect(() => { 

    axios.get(url)
    .then((response) => {
      console.log(response.data.data.client)
      setClient(response.data.data.client)
    }).catch((error) => console.log(error) )
    
  } , [url])
  
  return (
    <>
      <Navbar />
      <div style={{display : "flex"}}>
        <Sidebar />
        <div className='Profile main-section' data-aos="fade-down">
          <Bread links={links} />
          <ProfileData client={client} />
          <ProfilePI client={client} />
          
          {client.educations && client.educations.length > 0 && client.educations.map((ed , i) => {
            return (
              <ProfileED ed={ed} key={i} i = {i} />
            )
          })}

          {client.works && client.works.length > 0 && client.works.map((ed , i) => {
            return (
              <ProfileWO ed={ed} key={i} i = {i} />
            )
          })}
          
          <ProfileOD client={client} />
          <ProfileGIC client={client} />
          <CopyWriting />
        </div>
      </div>
    </>
  )
}

const ProfileData = ({client}) => {

  const icons = [
    {
      icon : <CreateOutlinedIcon sx={{fontSize : "18px" , color : "#424242" , "&:hover" : {
      color : "secondary.main",
      cursor : "pointer"
      } }} />,
      name : "Create"
    },
    {
      icon : <LocalPrintshopOutlinedIcon sx={{fontSize : "18px" , color : "#424242" , "&:hover" : {
      color : "secondary.main",
      cursor : "pointer"
      } }} />,
      name : "Print"
    },
  ]
  
  return (
    client && 
    
    <Stack p={8} bgcolor="white" borderRadius="8px" direction="row" justifyContent="space-between" mb={8} alignItems={{xs : "flex-end" , sm : "flex-start"}} className='ProfileData' >
      <Stack spacing={4}>
        <h3>{client.full_name}</h3>
        <h4><span>ID : </span>{client.id}</h4>
      </Stack>
      {/* Icons */}
      {/* <Stack direction="row" spacing={4} >
        {icons.map((icon , i) => {
          return (
            <Stack key={i} width="25px" height="25px" alignItems="center" justifyContent="center" overflow="hidden" bgcolor="rgba(203, 207, 210, 0.3);" borderRadius="4px" >
              <Tooltip title={icon.name} sx={{transition : "1s"}}>
                {icon.icon}
              </Tooltip>
            </Stack>
          )
        })}
      </Stack> */}
    </Stack>

  )
}

const ProfilePI = ({client}) => {

  return (
    client && 
    <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
      <h2>personal information</h2>
      <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>
        <Stack direction="row" flex={1} spacing={32}>
          <Stack className='keys' spacing={8}>
            <li>Name</li>
            <li>gender</li>
            <li>ID</li>
            <li>register Date</li>
            <li>Date of Birth</li>
            <li>Citizenship</li>
          </Stack>
          <Stack className='values' spacing={8}>
            <li>{client.full_name}</li>
            <li>{client.gender ? client.gender : "unknown"}</li>
            <li>{client.id ? client.id : "unknown"}</li>
            <li>{client.created_at ? client.created_at.slice(0 , 10) : "unknown"}</li>
            <li>{client.date_birth ? client.date_birth : "unknown"}</li>
            <li>{client.citizenship ? client.citizenship : "unknown"}</li>
          </Stack>
        </Stack>
        <Stack direction="row" flex={1} spacing={32} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
          <Stack className='keys' spacing={8}>
            <li>email address</li>
            <li>phone</li>
            <li>Marital Status</li>
            <li>country</li>
            <li>city</li>
          </Stack>
          <Stack className='values' spacing={8}>
            <li>{client.email ? client.email : "unknown"}</li>
            <li>{client.number ? client.number : "unknown"}</li>
            <li>{client.marital_status ? client.marital_status : "unknown"}</li>
            <li>{client.country_of_residence ? client.country_of_residence : "unknown"}</li>
            <li>{client.city ? client.city : "unknown"}</li>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

const ProfileED = ({ed , i}) => {

    return (
      <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
        <h2>Education {i > 0 && i + 1} </h2>
        <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>
          <Stack direction="row" flex={1} spacing={32}>
            <Stack className='keys' spacing={8}>
              <li>Name of education</li>
              <li>country</li>
              <li>from</li>
            </Stack>
            <Stack className='values' spacing={8}>
              <li>{ed.name_of_educational_institution ? ed.name_of_educational_institution : "unknown"}</li>
              <li>{ed.country ? ed.country : "unknown"}</li>
              <li>{ed.from ? ed.from.slice(0 , 10) : "unknown"}</li>
            </Stack>
          </Stack>
          <Stack direction="row" flex={1} spacing={32} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
            <Stack className='keys' spacing={8}>
              <li>field of study</li>
              <li>education degree</li>
              <li>to (date)</li>
            </Stack>
            <Stack className='values' spacing={8}>
              <li>{ed.field_of_study ? ed.field_of_study : "unknown"}</li>
              <li>{ed.education_degree ? ed.education_degree : "unknown"}</li>
              <li>{ed.to ? ed.to.slice(0 , 10) : "unknown"}</li>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    )  
  
}

const ProfileWO = ({ed , i}) => {

  return (
    <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
      <h2>work {i > 0 && i + 1} </h2>
      <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>
        <Stack direction="row" flex={1} spacing={32}>
          <Stack className='keys' spacing={8}>
            <li>Name of company</li>
            <li>country</li>
            <li>from</li>
          </Stack>
          <Stack className='values' spacing={8}>
            <li>{ed.name_of_company ? ed.name_of_company : "unknown"}</li>
            <li>{ed.country ? ed.country : "unknown"}</li>
            <li>{ed.from ? ed.from.slice(0 , 10) : "unknown"}</li>
          </Stack>
        </Stack>
        <Stack direction="row" flex={1} spacing={32} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
          <Stack className='keys' spacing={8}>
            <li>is paid</li>
            <li>number of hourse / week</li>
            <li>to (date)</li>
          </Stack>
          <Stack className='values' spacing={8}>
            <li>{ed.isPaid === 0 ? "no" : "yes" }</li>
            <li>{ed.number_of_hourse_week ? ed.number_of_hourse_week : "unknown"}</li>
            <li>{ed.to ? ed.to.slice(0 , 10) : "unknown"}</li>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )  

}

const ProfileOD = ({client}) => {

  return (
    <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
      <h2>Language details</h2>
      <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>

        <Stack flex={1}>
          <h4 className='lang'>English</h4>
          <Stack direction="row" spacing={32}>
            <Stack className='keys' spacing={8}>
              <li>speaking</li>
              <li>listening</li>
              <li>reading</li>
              <li>writing</li>
            </Stack>
            <Stack className='values' spacing={8}>
              <li>{client.en_speaking ? client.en_speaking : "unknown"}</li>
              <li>{client.en_listening ? client.en_listening : "unknown"}</li>
              <li>{client.en_reading ? client.en_reading : "unknown"}</li>
              <li>{client.en_writing ? client.en_writing : "unknown"}</li>
            </Stack>
          </Stack>
        </Stack>

        <Stack flex={1} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
          <h4 className='lang fr'>French</h4>
          <Stack direction="row" spacing={32}>
            <Stack className='keys' spacing={8}>
              <li>speaking</li>
              <li>listening</li>
              <li>reading</li>
              <li>writing</li>
            </Stack>
            <Stack className='values' spacing={8}>
              <li>{client.fr_speaking ? client.fr_speaking : "unknown"}</li>
              <li>{client.fr_listening ? client.fr_listening : "unknown"}</li>
              <li>{client.fr_reading ? client.fr_reading : "unknown"}</li>
              <li>{client.fr_writing ? client.fr_writing : "unknown"}</li>
            </Stack>
          </Stack>
        </Stack>

      </Stack>
    </Stack>
  )
}

const ProfileGIC = ({client}) => {

  return (
    client && 
    <Stack p={8} pb={12} mb={8} bgcolor="white" borderRadius="8px" className='ProfilePI'>
      <h2>GIC Details</h2>
      <Stack direction={{xs : "column" , sm : "row"}} spacing={{xs : 16 , sm : 0}}>

        <Stack flex={1}>
          <Stack direction="row" spacing={32}>
            <Stack className='keys' spacing={8}>
              <li>GIC Account</li>
              <li>Case Type</li>
              <li>Application Status</li>
              <li>UCI NO.</li>
              <li>Student Permit</li>
              <li>SP N.</li>
              <li>SP Expiry Date</li>
              <li>Work Permit</li>
              <li>WP NO.</li>
              <li>CAQ</li>
            </Stack>
            <Stack className='values' spacing={8}>
              <li>{client.gic_account ? client.gic_account : "unknown"}</li>
              <li>{client.case_type ? client.case_type : "unknown"}</li>
              <li>{client.app_statues ? client.app_statues : "unknown"}</li>
              <li>{client.uci_no ? client.uci_no : "unknown"}</li>
              <li>{client.student_permit ? client.student_permit : "unknown"}</li>
              <li>{client.sp_n ? client.sp_n : "unknown"}</li>
              <li>{client.sp_expiry_date ? client.sp_expiry_date : "unknown"}</li>
              <li>{client.work_permit ? client.work_permit : "unknown"}</li>
              <li>{client.wp_no ? client.wp_no : "unknown"}</li>
              <li>{client.caq ? client.caq : "unknown"}</li>
            </Stack>
          </Stack>
        </Stack>

        <Stack flex={1} paddingLeft={{xs : 0 , sm : 12}} borderLeft={{xs : "0" , sm : "1px solid #CBCFD2"}}>
          <Stack direction="row" spacing={32}>
            <Stack className='keys' spacing={8}>
              <li>CAQ App. No.</li>
              <li>CAQ NO.</li>
              <li>CSQ</li>
              <li>CSQ App. No.</li>
              <li>CSQ NO.</li>
              <li>CRS</li>
              <li>NOC CODE</li>
              <li>EE SCORE</li>
              <li>FSW SCORE</li>
            </Stack>
            <Stack className='values' spacing={8}>
              <li>{client.caq_app_no ? client.caq_app_no : "unknown"}</li>
              <li>{client.caq_no ? client.caq_no : "unknown"}</li>
              <li>{client.csq ? client.csq : "unknown"}</li>
              <li>{client.csq_app_no ? client.csq_app_no : "unknown"}</li>
              <li>{client.csq_no ? client.csq_no : "unknown"}</li>
              <li>{client.crs ? client.crs : "unknown"}</li>
              <li>{client.noc_code ? client.noc_code : "unknown"}</li>
              <li>{client.ee_score ? client.ee_score : "unknown"}</li>
              <li>{client.fsw_score ? client.fsw_score : "unknown"}</li>
            </Stack>
          </Stack>
        </Stack>

      </Stack>
    </Stack>
  )
}


export default Profile
