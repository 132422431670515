import "../AddPlan/AddPlan.scss"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import CopyWriting from "../../components/CopyWriting/CopyWriting"
import Bread from "../../components/Bread/Bread"
import { Button, MenuItem, Stack, TextField } from '@mui/material';
import React, { useEffect, useState, } from 'react';
import JoditEditor from 'jodit-react';
import { useForm } from "react-hook-form";
import axios from "axios";
import PopUp from "../../components/PopUp/PopUp";
import { Link, useParams } from "react-router-dom";


const AddPlan = () => {

    const links = [
        {
            name: "Dashboard",
            to: "/",
        },
        {
            name: "plans",
            to: "/plans",
        },
        {
            name: "Add Plan",
            to: "/add-plan",
        },
    ];

    return (
        <>
            <Navbar />
            <div style={{ display: "flex" }}>
                <Sidebar />
                <div className="AddPlan main-section" data-aos="fade-down">
                    <Bread links={links} />

                    <AddPlanForm />

                    <CopyWriting />
                </div>
            </div>
        </>
    );
}

const AddPlanForm = () => {


    // API 
    const {id} = useParams()

    // Get Data From Local Storage
    const data = JSON.parse(localStorage.getItem("GIC_plan_edit"))

    const { register , handleSubmit } = useForm()

    const [editor, setEditor] = useState(data.description)
    const [caseId, setCaseId] = useState()

    const [pointsCounter, setPointsCounter] = useState([]);
    const [docsId, setDocsId] = useState([]);

    const [active, setActive] = useState(false)
    const [activeSubmit, setActiveSubmit] = useState(false)

    const [error, setError] = useState(false)

    const cancelConfirm = {
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="104"
                height="104"
                viewBox="0 0 104 104"
                fill="none"
            >
                <path
                    d="M99 51.9899C99 77.9473 77.9574 98.9899 52 98.9899C26.0426 98.9899 5 77.9473 5 51.9899C5 26.0325 26.0426 4.98987 52 4.98987C77.9574 4.98987 99 26.0325 99 51.9899Z"
                    stroke="#E7511D"
                    strokeWidth="6"
                />
                <path
                    d="M52.0001 100.155C78.601 100.155 100.165 78.5908 100.165 51.99C100.165 25.3891 78.601 3.82483 52.0001 3.82483C25.3992 3.82483 3.83496 25.3891 3.83496 51.99C3.83496 78.5908 25.3992 100.155 52.0001 100.155Z"
                    stroke="#E7511D"
                    strokeWidth="6"
                />
                <path
                    d="M52.0001 55.5761L34.069 73.5072C33.5567 74.0195 32.959 74.2756 32.2759 74.2756C31.5928 74.2756 30.9951 74.0195 30.4828 73.5072C29.9705 72.9948 29.7144 72.3971 29.7144 71.7141C29.7144 71.031 29.9705 70.4333 30.4828 69.921L48.4139 51.9899L30.4828 34.0589C29.9705 33.5466 29.7144 32.9489 29.7144 32.2658C29.7144 31.5827 29.9705 30.985 30.4828 30.4727C30.9951 29.9604 31.5928 29.7042 32.2759 29.7042C32.959 29.7042 33.5567 29.9604 34.069 30.4727L52.0001 48.4037L69.9311 30.4727C70.4434 29.9604 71.0411 29.7042 71.7242 29.7042C72.4073 29.7042 73.005 29.9604 73.5173 30.4727C74.0296 30.985 74.2858 31.5827 74.2858 32.2658C74.2858 32.9489 74.0296 33.5466 73.5173 34.0589L55.5863 51.9899L73.5173 69.921C74.0296 70.4333 74.2858 71.031 74.2858 71.7141C74.2858 72.3971 74.0296 72.9948 73.5173 73.5072C73.005 74.0195 72.4073 74.2756 71.7242 74.2756C71.0411 74.2756 70.4434 74.0195 69.9311 73.5072L52.0001 55.5761Z"
                    fill="#E7511D"
                />
            </svg>
        ),
        message: `are you sure you want to cancel ?`,
        button1: (
            <Link to="/plans">
                <Button
                    color="primary"
                    variant="outlined"
                    sx={{
                        width: "250px",
                        py: 8,
                        px: { xs: 20, sm: 40 },
                        borderRadius: "8px",
                    }}
                >
                    yes
                </Button>
            </Link>
        ),
        button2: (
            <Button
                color="secondary"
                onClick={() => setActive(false)}
                variant="outlined"
                sx={{
                    width: "250px",
                    py: 8,
                    px: { xs: 20, sm: 40 },
                    borderRadius: "8px",
                }}
            >
                no
            </Button>
        ),
    };

    const pop = {
        icon : <svg xmlns="http://www.w3.org/2000/svg" width="103" height="103" viewBox="0 0 103 103" fill="none">
        <path d="M98.3164 51.175C98.3164 77.1324 77.2738 98.175 51.3164 98.175C25.359 98.175 4.31641 77.1324 4.31641 51.175C4.31641 25.2177 25.359 4.17505 51.3164 4.17505C77.2738 4.17505 98.3164 25.2177 98.3164 51.175Z" stroke="#71D54F" strokeWidth="6"/>
        <path d="M51.3165 99.3403C77.9174 99.3403 99.4817 77.776 99.4817 51.1752C99.4817 24.5743 77.9174 3.01001 51.3165 3.01001C24.7156 3.01001 3.15137 24.5743 3.15137 51.1752C3.15137 77.776 24.7156 99.3403 51.3165 99.3403Z" stroke="#71D54F" strokeWidth="6"/>
        <path d="M26.3164 55.4608L39.8878 69.0322" stroke="#71D54F" strokeWidth="6" strokeLinecap="round"/>
        <path d="M39.8877 69.0322L75.602 33.3179" stroke="#71D54F" strokeWidth="6" strokeLinecap="round"/>
        </svg>,
        message : "Successfully Edited ",
        message2 : "plan has been Edited successfully!",
        button1 : <Link to={"/plans/" + id} >
                    <Button variant="contained" sx={{py : 8 , px : {xs : 40 , sm : 100}}}> go to plan </Button>
                </Link>,
        type : "plan"       
    }

    const err = {
        icon :<svg
        xmlns="http://www.w3.org/2000/svg"
        width="104"
        height="104"
        viewBox="0 0 104 104"
        fill="none"
    >
        <path
            d="M99 51.9899C99 77.9473 77.9574 98.9899 52 98.9899C26.0426 98.9899 5 77.9473 5 51.9899C5 26.0325 26.0426 4.98987 52 4.98987C77.9574 4.98987 99 26.0325 99 51.9899Z"
            stroke="#E7511D"
            strokeWidth="6"
        />
        <path
            d="M52.0001 100.155C78.601 100.155 100.165 78.5908 100.165 51.99C100.165 25.3891 78.601 3.82483 52.0001 3.82483C25.3992 3.82483 3.83496 25.3891 3.83496 51.99C3.83496 78.5908 25.3992 100.155 52.0001 100.155Z"
            stroke="#E7511D"
            strokeWidth="6"
        />
        <path
            d="M52.0001 55.5761L34.069 73.5072C33.5567 74.0195 32.959 74.2756 32.2759 74.2756C31.5928 74.2756 30.9951 74.0195 30.4828 73.5072C29.9705 72.9948 29.7144 72.3971 29.7144 71.7141C29.7144 71.031 29.9705 70.4333 30.4828 69.921L48.4139 51.9899L30.4828 34.0589C29.9705 33.5466 29.7144 32.9489 29.7144 32.2658C29.7144 31.5827 29.9705 30.985 30.4828 30.4727C30.9951 29.9604 31.5928 29.7042 32.2759 29.7042C32.959 29.7042 33.5567 29.9604 34.069 30.4727L52.0001 48.4037L69.9311 30.4727C70.4434 29.9604 71.0411 29.7042 71.7242 29.7042C72.4073 29.7042 73.005 29.9604 73.5173 30.4727C74.0296 30.985 74.2858 31.5827 74.2858 32.2658C74.2858 32.9489 74.0296 33.5466 73.5173 34.0589L55.5863 51.9899L73.5173 69.921C74.0296 70.4333 74.2858 71.031 74.2858 71.7141C74.2858 72.3971 74.0296 72.9948 73.5173 73.5072C73.005 74.0195 72.4073 74.2756 71.7242 74.2756C71.0411 74.2756 70.4434 74.0195 69.9311 73.5072L52.0001 55.5761Z"
            fill="#E7511D"
        />
        </svg>,
        message : "Somthing went wrong",
        message2 : "Make sure to fill all fields",
        button1 : <Button variant="contained" sx={{py : 8 , px : {xs : 40 , sm : 100}}} onClick={() => {
            setError(false)
        }}> close </Button>,
        type : "plan"       
    }

    const token = localStorage.getItem("gic_token");
    const url = "https://bksys.getincanada.ca/api/admin/programs/" + id;


    // Fill points Counter
    useEffect(() => {
        data.answers?.map(answer => setPointsCounter(prev => [...prev , { points : answer.id , id : answer.question_id }]))
    } , [])
    
    
    axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;

        return config;
    });

    const onSubmit = (data) => {
        data.description = editor
        data.documentIds = docsId
        const ids = pointsCounter.map(id => id.points)
        if (ids) {
            data.answerIds = ids
        }

        console.log(data)

        axios.put(url , data)
        .then((response) => {
            console.log(response.data)
            setError(false)
            response.data.status ? setActiveSubmit(true) : setError(true)
        })
        .catch(err => {
            console.log(err)
            setError(true)
        });
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Stack p={8} pb={12} bgcolor={"white"} spacing={12} borderRadius={"8px"} mb={12}>

                <TextField label="plan name" variant="outlined" {...register("title")} defaultValue={data.title} />

                <TextField
                    fullWidth
                    select
                    defaultValue={data.job_offer}
                    label="JOB OFFER"
                    sx={{
                    flex: 1 ,
                    textTransform : "uppercase",
                    }}
                    {...register("job_offer")}
                >
                    <MenuItem value={"yes"}>
                    yes
                    </MenuItem>
                    <MenuItem value={"no"}>
                    no
                    </MenuItem>
                </TextField>

                <TextField
                fullWidth
                select
                defaultValue={data.lang_ability}
                label="LANGUAGE ABILITY"
                sx={{
                    flex: 1 ,
                    textTransform : "uppercase",
                }}
                {...register("lang_ability")}
                >
                <MenuItem value="No English">
                    No English
                </MenuItem>
                <MenuItem value="Elementary English">
                    Elementary English
                </MenuItem>
                <MenuItem value="Limited Working English">
                    Limited Working English
                </MenuItem>
                <MenuItem value="Professional Working English">
                    Professional Working English
                </MenuItem>
                <MenuItem value="Full Professional English">
                    Full Professional English
                </MenuItem>
                <MenuItem value="Native / Bilingual English">
                    Native / Bilingual English
                </MenuItem>
                </TextField>

                <TextField label="pref description" variant="outlined" {...register("pref")} defaultValue={data.pref} />

            </Stack>

            <GeneralInfo editor={editor} setEditor={setEditor} />
            
            <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} mb={12} spacing={12}>
                <h3>pricing</h3>
                <TextField type="number" label="add price" variant="outlined" {...register("pricing")} defaultValue={data.pricing} />
            </Stack>

            <RequiredDos docsId={docsId} setDocsId={setDocsId} data={data} />

            <Cases register={register} setCaseId={setCaseId} data={data} />

            <Questions caseId={caseId} pointsCounter={pointsCounter} setPointsCounter={setPointsCounter} data={data} />

            <Submit setActive={setActive} setActiveSubmit={setActiveSubmit} />

            { active && <PopUp pop={cancelConfirm} setActive={setActive} /> }
            { activeSubmit && <PopUp pop={pop} setActive={setActiveSubmit} /> }
            { error && <PopUp pop={err} setActive={setError} /> }
        
        </form>
    )

}

const GeneralInfo = ({editor , setEditor }) => {



    return (
        <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} mb={12} spacing={12}>

        <h3>General Information</h3>

        <JoditEditor 
            value={editor}
            onBlur={(e) => {
            setEditor(e)
            console.log(editor)
            }}
        />
        
        </Stack>
    )
}

const RequiredDos = ({docsId , setDocsId , data}) => {  

    // API 
    const [documents, setDocuments] = useState(data.documents)
    const [names, setNames] = useState(data.documents.map((doc) => doc.name))

    const token = localStorage.getItem("gic_token");
    const url = "https://bksys.getincanada.ca/api/admin/documents";

    axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;

        return config;  
    });

    useEffect(() => {
        axios.get(url).then((response) => {
            setDocuments(response.data.data)
        });
    } , [])

    useEffect(() => {
        setDocsId(data.documents.map((doc) => doc.id))
    } , [])


    return (
    <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} mb={12} spacing={12}>

        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <h3>required documents</h3>
            { docsId.length > 0 && <Button variant="contained" onClick={() => {
                setDocsId([])
                setNames([])
            }} >Reset</Button>}
        </Stack>

        <TextField
            fullWidth
            select
            defaultValue={""}
            label="documents"
            color="primary"
            onChange={(e) => {
                console.log(e.target)
                !docsId.includes(e.target.value) &&
                setDocsId(prev => [...prev , e.target.value])
            }}
        >

        {documents && documents.map( doc => {
            return (
            <MenuItem key={doc.id} value={doc.id} onClick={() => {
                !names.includes(doc.name) &&
                setNames(prev => [...prev , doc.name])
            } }>
                {doc.name}
            </MenuItem>
            )
        } )}
        </TextField>

        <div className="items">
            {names && names.map( (name , i) => {
                return (
                    <div className="item" key={i} >{name}</div>
                )
            } ) }
        </div>

    </Stack>
    )
}

const Cases = ({register , setCaseId , data}) => {

    const [cases, setCases] = useState(null)

    const token = localStorage.getItem("gic_token");
    const url = "https://bksys.getincanada.ca/api/admin/cases";

    axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;

        return config;
    });

    useEffect(() => {
        axios.get(url)
        .then((response) => {
            setCases(response.data.data)
        }).catch(err => console.log(err))
        ;
    } , [])


    return (
        <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} mb={12} spacing={12}>

        <h3>Case</h3>

        <TextField
            fullWidth
            select
            defaultValue={data.case.id || ""}
            label="case"
            color="primary"
            {...register("case_id")}
        >
            {cases && cases.map( item => {
                return (
                    <MenuItem key={item.id} value={item.id} onClick={() => setCaseId(item.id)}>
                        {item.name}
                    </MenuItem>
                )
            } )}
        </TextField>

        </Stack>
    )
}

const Questions = ({caseId , data , pointsCounter , setPointsCounter}) => {


    const [Ques, setQues] = useState([])

    const token = localStorage.getItem("gic_token");
    const url = `https://bksys.getincanada.ca/api/questioner`;

    axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`;

        return config;
    });

    useEffect(() => {
        axios.post(url , {case_id : data.case.id}).then((response) => {
        setQues(response.data.data.questioner)
        });
    } , [caseId , url])


    return (
        <Stack p={8} pb={12} bgcolor={"white"} borderRadius={"8px"} spacing={12}>

        <h3>questions</h3>


        {Ques && Ques.map((question) => {
            return (
                <SelectInput
                    key={question.id}
                    question={question}
                    pointsCounter={pointsCounter}
                    setPointsCounter={setPointsCounter}
                    defaultValue={data?.answers?.filter(answer => answer.question_id == question.id)}
                />
            );
        })}


        </Stack>
    )
}

const SelectInput = ({ question , defaultValue , pointsCounter , setPointsCounter}) => {

    const addPoints = (points, id) => {
        
        let index = pointsCounter.findIndex((i) => +i.id === +id);

        if (index > -1) {
            pointsCounter.splice(index, 1);
            setPointsCounter([...pointsCounter, { points, id }]);
        } else {
            setPointsCounter([...pointsCounter, { points, id }]);
        }

        console.log(pointsCounter)
    };
    
    return (
        <>
            {/* Level 1 */}
            <div className="input">
                <TextField
                    fullWidth
                    select
                    defaultValue={defaultValue[0]?.id || ""}
                    label={question.title}
                    color="primary"
                    key={question.id}
                    onChange={e => addPoints(e.target.value , question.id)}
                >
                    {question.answers.map((answer) => {
                        return (
                            <MenuItem key={answer.id} value={answer.id}>
                                {answer.text}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </div>

        </>
    );
};

const Submit = ({setActive}) => {
    return (
        <Stack justifyContent={"end"} direction={"row"} spacing={12} mb={50} mt={20} >
        <Button sx={{py : 6 , px : 25}} variant="outlined" onClick={() => setActive(true)} >cancel</Button>
        <Button type="submit" sx={{py : 6 , px : 25}} variant="contained">edit plan</Button>
        </Stack>
    )
}

export default AddPlan
